import * as S from './styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RoleData } from '@monorepo/interfaces'
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

const SectionRolesList: React.FC<RoleData> = ({ roles }) => {
  const { t } = useTranslation('admin')
  const history = useHistory()

  const handleEditRole = (index: number) => {
    const id = roles[index]._id
    history.push(`/roles/edit/${id}`)
  }

  return (
    <S.Container>
      <S.InputBase
        placeholder={t('section_roles_list.input_placeholder')}
        inputProps={{ 'aria-label': 'search' }}
        endAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <S.TableCellTitle>
                {t('section_roles_list.table_title_1')}
              </S.TableCellTitle>
              <S.TableCellTitle>
                {t('section_roles_list.table_title_2')}
              </S.TableCellTitle>
              <S.TableCellTitle align="right">
                {t('section_roles_list.table_title_3')}
              </S.TableCellTitle>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {roles?.map((roles, index) => (
                <TableRow key={index}>
                  <S.TableCell component="th" scope="row">
                    {roles.roleName}
                  </S.TableCell>
                  <S.TableCell component="th" scope="row">
                    {roles.role}
                  </S.TableCell>
                  <S.TableCell align="right">
                    <S.Button>
                      {t('section_roles_list.impersonate_button')}
                    </S.Button>
                    <IconButton
                      onClick={() => handleEditRole(index)}
                      disableRipple={true}
                      id={index + ''}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton disableRipple={true}>
                      <DeleteIcon />
                    </IconButton>
                  </S.TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        </Table>
      </TableContainer>
    </S.Container>
  )
}

export default SectionRolesList
