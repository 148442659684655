import React from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from '@monorepo/components'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const SectionRoles: React.FC = () => {
  const { t } = useTranslation('admin')
  const history = useHistory()

  const hadleChangePage = (url: string) => {
    history.push(url)
  }

  return (
    <S.Container>
      <Text size="30px" family="ATTAleckSansBold">
        {t('section_roles.title')}
      </Text>
      <S.Button onClick={() => hadleChangePage('roles/create')}>
        {t('section_roles.button_text')}
      </S.Button>
    </S.Container>
  )
}

export default SectionRoles
