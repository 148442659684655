import * as S from './styles'
import { Icon } from '@monorepo/components'

interface ExportXlsLinkProps {
  onClick: () => void
}

const ExportXlsLink: React.FC<ExportXlsLinkProps> = ({ onClick, ...props }) => (
  <S.ExportXlsLink {...props} onClick={onClick}>
    <span>Export</span>
    <Icon icon="ExportIcon" size={17} />
  </S.ExportXlsLink>
)

export default ExportXlsLink
