import * as S from './styles'

interface SeparatorProps {
  color?: string
}

const Separator: React.FC<SeparatorProps> = ({ ...props }) => (
  <S.Separator {...props} />
)

export default Separator
