import { IconsType } from '@monorepo/components'

interface Card {
  title: string
  link: string
  iconName: keyof typeof IconsType
  external?: boolean
}

interface CardGroup {
  subtitle: string
  cards: Card[]
}

export const menuCards: CardGroup[] = [
  {
    subtitle: 'People',
    cards: [
      {
        title: 'Directory',
        iconName: 'PersonCropRectangleStack',
        link: '/admin/directory',
      },
      {
        title: 'Export Dealer File',
        iconName: 'ExportIcon',
        link: `https://themarketingzoneblob.blob.core.windows.net/dealerfile/DealerFile.csv?v=${Date.now()}`,
        external: true,
      },
    ],
  },
  {
    subtitle: 'Storefront Controls',
    cards: [
      {
        title: 'Resources',
        iconName: 'BooksVertical',
        link: '/admin/resources',
      },
      {
        title: 'New User Pop-Up',
        iconName: 'Megaphone',
        link: '/admin/pop-ups',
      },
      {
        title: 'Message Blasts',
        iconName: 'Envelope',
        link: '/admin/message-blasts',
      },
      {
        title: 'Homepage Image Update',
        iconName: 'HomepageImage',
        link: '/admin/homepage-image',
      },
    ],
  },
]
