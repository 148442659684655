import * as S from './styles'

import {
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogLabel,
} from '@reach/alert-dialog'
import {
  Pagination,
  SectionUsers,
  SectionUsersList,
  TabList,
} from '../../../components'
import { Pagination as PaginationType, UserProfile } from '@monorepo/interfaces'
import {
  DealerType,
  http,
  httpUserInfo,
  usePaginationSearchParams,
  useQueryParams,
} from '@monorepo/infra'
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'

import { Icon } from '@monorepo/components'
import {
  DashboardBackButton,
  PaginationDetails,
  ExportXlsLink,
} from '../../atoms'
import { SearchTerm } from '../../molecules'
import { UserForm } from './forms/userForm'
import { colors } from '@monorepo/theme'
import useTextTranslation from '@monorepo/infra/build/locales/i18next/useLocation'
import { IUserForm } from './forms/userForm'
import axios from 'axios'
import * as TreeHelper from '../../helpers/user'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakHelper } from '@monorepo/infra'

type UsersFetch = PaginationType & {
  data: UserProfile[]
}

const Users = (): JSX.Element => {
  const directoryBaseUrl = '/user/directory'
  const pageURLKey = 'p'
  const searchURLKey = 's'

  const queryParams = useQueryParams()

  const [isManageDialogOpened, setIsManageDialogOpened] = useState(false)
  const manageCancelRef = useRef(null)

  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(searchURLKey) ?? ''
  )
  const { t } = useTextTranslation('admin')

  const { apiSearchParams, handlePageChange, handlePrevPage, handleNextPage } =
    usePaginationSearchParams({
      pageAPIKey: 'pageNum',
      pageURLKey,
      searchAPIKey: 'search',
      searchURLKey,
      searchTerm,
    })

  const [users, setUser] = useState<UsersFetch>()
  const [userToEdit, setUserToEdit] = useState<IUserForm>()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  // const [currentPage, setCurrentPage] = useState(1)
  const previousApiUrl = useRef<string | undefined>(undefined)

  const getUsers = async () => {
    const activeTab = queryParams.get('activeTab')
    const apiURL = `${directoryBaseUrl}?${apiSearchParams}&page=${page}&pageSize=20&groups=${activeTab}&withDealers=true`
    // const url =
    //   activeTab !== 'all' && activeTab !== null
    //     ? `/user/directory?page=${currentPage}&pageSize=10&groups=${activeTab}`
    //     : `/user/directory?page=${currentPage}&pageSize=10`

    if (previousApiUrl.current === apiURL) {
      return
    }

    previousApiUrl.current = apiURL
    setLoading(true)
    const usersList = await httpUserInfo.get<UsersFetch>({
      url: apiURL,
    })
    setUser(usersList.data)
    setLoading(false)
  }

  const handlePageChangeLocal = (page: number) => {
    handlePageChange(page)
    const newPage = page + 1
    setPage(newPage)
  }

  useEffect(() => {
    getUsers()
  }, [page, queryParams, apiSearchParams])

  const toggleModal = useCallback(() => {
    setIsManageDialogOpened((isOpened) => !isOpened)
  }, [])

  const { keycloak } = useKeycloak()

  const handleOpenModalEditUser = (index: number) => {
    const data = users?.data[index] as UserProfile
    const userForm: IUserForm = {
      userId: data?._id as string,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      dealerTypes: data?.dealerTypes || [],
      enabled: data?.enabled || false,
    }
    setUserToEdit(userForm)
    setIsManageDialogOpened(true)
  }

  // const { data, ...pagination } = users
  // pagination.page = Number(1)
  const handleClearSearchTerm = () => setSearchTerm('')

  const handleDeleteUser = async (email: string, enabled: boolean) => {
    try {
      await httpUserInfo.delete({ url: '/user/' + email + '/' + enabled })
      document.location.reload()
    } catch (err) {
      console.error(err)
    }
  }

  const handleUserActivity = async (data: IUserForm) => {
    const userChanges = []
    const previusData = users?.data.find(u => u._id === data.userId)
    if (previusData?.dealerTypes !== data.dealerTypes) {
      Object.values(DealerType).filter(d=> {
        if(!previusData?.dealerTypes?.includes(d) && data?.dealerTypes?.includes(d)){
        userChanges.push({ key: d, previusValue: false, currentValue: true })
      }
        if(previusData?.dealerTypes?.includes(d) && !data?.dealerTypes?.includes(d)){
        userChanges.push({ key: d, previusValue: true, currentValue: false })
      }
      })
    }
    if (previusData?.enabled !== data.enabled) {
      userChanges.push({ key: 'enable', previusValue: Boolean(previusData?.enabled), currentValue: Boolean(data.enabled) })
    }
    if (userChanges.length > 0) {

      return await http.post({
        url: '/log',
        data: {
          userActivity: {
            changes: userChanges,
            userId: data.userId
          },
          type: 3,
        },
        headers: {
          Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
          'content-type': 'application/json',
        }
      })
    }
  }

  const handleEditUser = async (data: IUserForm) => {
    try {
      const userData = {
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dealerTypes: data.dealerTypes,
        enabled: data.enabled,
      }
      handleUserActivity(data)

      await httpUserInfo.patch({
        url: `/user/${data.userId}`,
        data: userData,
      })

      setIsManageDialogOpened(false)
      document.location.reload()
    } catch (err) {
      console.error(err)
    }
  }

  const handleExportXls = async () => {
    try {
      const responses = await Promise.all([
        axios.get('/masterdealer/tree', {
          baseURL: process.env.REACT_APP_REST_USER_BASE_URL,
        }),
        axios.get('/United/DealerUniqueEmails', {
          baseURL: process.env.REACT_APP_TX_BASE_URL,
        }),
      ])

      if (Array.isArray(responses) && responses.length > 1) {
        const data = TreeHelper.getTreeDataToExport(
          responses[0].data,
          responses[1].data?.DealerEmails
        )
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
        const fileExtension = '.xlsx'
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const outputData = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(outputData, `Directory${fileExtension}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <S.Main>
      <DashboardBackButton redirectURL="/admin" text="Back to Admin" />
      <SectionUsers title="User Management" />
      <S.PageActions>
        <TabList
          tabKey="activeTab"
          tabs={[
            { description: 'All', tabName: 'all' },
            { description: 'DirecTV', tabName: 'directvAdmin' },
            { description: 'Vendors', tabName: 'vendor' },
            { description: 'Zoners', tabName: 'zoner' },
          ]}
        />

        <S.ActionButtonsContainer>
          <ExportXlsLink onClick={handleExportXls} />
          {isManageDialogOpened && (
            <S.AlertDialogOverlay
              onDismiss={toggleModal}
              leastDestructiveRef={manageCancelRef}
            >
              <S.AlertDialog
                onDismiss={toggleModal}
                leastDestructiveRef={manageCancelRef}
              >
                <AlertDialogContent
                  style={{
                    background: '#fff',
                    padding: '0 20px',
                    margin: '1vh auto',
                  }}
                >
                  <AlertDialogLabel></AlertDialogLabel>
                  <AlertDialogDescription>
                    <UserForm
                      close={toggleModal}
                      actionForm={handleEditUser}
                      initialValues={userToEdit}
                    />
                  </AlertDialogDescription>
                </AlertDialogContent>
              </S.AlertDialog>
            </S.AlertDialogOverlay>
          )}
        </S.ActionButtonsContainer>
      </S.PageActions>
      <S.Separator />
      <S.SearchFiltersContainer>
        <S.Search
          placeholder={t('section_users_list.input_placeholder')}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
        <SearchTerm searchTerm={searchTerm} onClear={handleClearSearchTerm} />
        <PaginationDetails
          totalItems={users?.totalCount as number}
          totalPages={users?.totalPages as number}
          itemsPerPage={users?.pageSize as number}
          pageNumber={users?.page as number}
          onClickPrev={handlePrevPage}
          onClickNext={handleNextPage}
        />
      </S.SearchFiltersContainer>
      <SectionUsersList
        loading={loading}
        users={users?.data as UserProfile[]}
        handleEditUser={handleOpenModalEditUser}
        handleDeleteUser={handleDeleteUser}
      />
      <S.PaginationContainer>
        <Pagination
          onPageChange={handlePageChangeLocal}
          totalCount={users?.totalCount as number}
          page={page}
          pageSize={users?.pageSize as number}
          totalPages={users?.totalPages as number}
        />
      </S.PaginationContainer>
    </S.Main>
  )
}

export default Users
