import * as S from './styles'
import { colors } from "@monorepo/theme"
import ManageTemplate from "../Manage"
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { useFormik } from 'formik'
import { IStroreFrontAsset } from '@monorepo/interfaces'
import { ManageCard } from '../../organisms'
import { IconButton } from '@mui/material'
import { DropzoneArea } from 'material-ui-dropzone'
import { AssetSchema } from './AssetSchema'
import { KeycloakHelper, http } from '@monorepo/infra'
import { useKeycloak } from '@react-keycloak/web'
import axios from 'axios'
import { PageSpinner } from '@monorepo/components'

type SelectItemType = {
  value: string
  label: string
}

type assetType = Omit<
  IStroreFrontAsset,
  'createdBy' | 'createdOn'
>

const values: assetType = {
  _id: '',
  name: '',
  assetType: 1,
  description: '',
  path: ``,
}

const HomepageImage: React.FC = () => {
  const url = `${process.env.REACT_APP_REST_BASE_URL}/asset`
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [currentAsset, setCurrentAsset] = useState<assetType>(values)
  const [newFile, setNewFile] = useState<File>()
  const [imageOptions, setImageOptions] = useState<SelectItemType[]>([])
  const history = useHistory()

  const { errors, touched, handleBlur, ...formik } = useFormik({
    enableReinitialize: true,
    validationSchema: AssetSchema,
    initialValues: values,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        handleSaveAsset()
      })
    },
  })

  useEffect(() => {
    getCurrentAsset()
    setIsLoading(false)
  }, [])

  const getCurrentAsset = async () => {
    await http.get<IStroreFrontAsset>({ url: 'asset/homepage-image' }).then((result) => {
      setCurrentAsset(result.data)
    }).catch((error) => {
      setHasError(true)
      setError(error)
    }
    )
  }

  const handleGoBack = useCallback(() => {
    history.push('/admin')
  }, [history])

  const handleDeleteImage = async () => {
    setIsLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
      },
    }
    await axios
      .get(`${url}/${currentAsset?._id}/unset-homepage-image`, config)
      .then((result) => {
        window.location.reload()
      })
      .catch((error) => {
        setHasError(true)
        setError(error)
      })

  }

  const createImageOption = (item: {
    _id: string
    name: string
    path: string
  }) => ({
    value: item._id,
    label: getImageFileName(item.name) || '',
    path: item.path,
  })

  const getImageFileName = (path?: string) => {
    if (!path) {
      return path
    }

    const match = path.match(/(@)(.+)/)

    return Array.isArray(match) && match.length > 2 ? match[2] : path
  }

  const handleSaveAsset = async () => {
    const form = new FormData()
    form.append('file', newFile ?? '')
    form.append('name', newFile?.name ?? '')
    form.append('description', formik.values.description ?? '')
    form.append('path', ``)

    if (newFile) {
      setIsLoading(true)

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
        },
      }
      await axios
        .post(url, form, config)
        .then((success) => {
          formik.setFieldValue('name', success?.data?._id)
          imageOptions?.push(
            createImageOption(success?.data)
          )
          handleDeleteImage()
        })
        .catch((error) => {
          setHasError(true)
          setError(error)
        })
      setIsLoading(false)
      window.location.reload()
    }
  }

  const handleCreatingNewFile = (file: File | null) => {
    if (!file) return
    setNewFile(file)
  }

  return (
    <ManageTemplate
      onGoBack={handleGoBack}
      backText="Back to Admin"
      titleText="Homepage Image Update"
      headerRightSideChildren={
        <S.ButtonsContainer>
          <S.FormButton
            backgroundColor={colors.blue}
            onClick={() => {
              handleSaveAsset()
            }}
          >
            Save and Publish
          </S.FormButton>
        </S.ButtonsContainer>
      }>
      {isLoading && (
        <PageSpinner />
      )}
      <S.Container>
        <S.TwoThirds>
          <ManageCard title="Homepage Image">
            {currentAsset.path && currentAsset.path !== '' &&
              <>
                <S.BackgroundImageContainer>
                  <S.BackgroundImageImage
                    src={currentAsset.path} />
                  <S.SelectBackgroundImageOptionLabel>
                    {currentAsset.description}
                  </S.SelectBackgroundImageOptionLabel>
                  <S.SelectBackgroundImageOptionLabel>
                    {currentAsset.name}
                  </S.SelectBackgroundImageOptionLabel>
                  <S.BackgroundImageDeleteButtonWrapper>
                    <IconButton onClick={handleDeleteImage}>
                      <DeleteIcon />
                    </IconButton>
                  </S.BackgroundImageDeleteButtonWrapper>
                </S.BackgroundImageContainer>
                <S.Line />
              </>}
            <S.SelectBackgroundImageContainer>
              <DropzoneArea
                maxFileSize={100000000}
                filesLimit={1}
                onChange={(files) => {
                  handleCreatingNewFile(files[0])
                }}
              ></DropzoneArea>
            </S.SelectBackgroundImageContainer>
          </ManageCard>
        </S.TwoThirds>
        <S.OneThird>
          <S.CustomerContainer>
            <S.CustomerTitle>User</S.CustomerTitle>
            <S.Line />
            <S.CustomerInfoContainer>
              <S.CustomerAvatar>
                {`${user.name.charAt(0)}`}
              </S.CustomerAvatar>
              <S.CustomerDetailContainer>
                <S.CustomerName>
                  {user?.name}
                </S.CustomerName>
              </S.CustomerDetailContainer>
            </S.CustomerInfoContainer>
          </S.CustomerContainer>
          <ManageCard title="Description">
            <S.TextInput
              name="text"
              label="Description"
              textarea={true}
              value={formik.values.description}
              onChange={({target: { value }}) => 
                formik.setFieldValue('description', value)
              }
              onBlur={handleBlur}
              invalid={!!errors.description && touched.description}
              invalidMessage={errors.description}
            />
          </ManageCard>
        </S.OneThird>
      </S.Container>
    </ManageTemplate>
  )
}

export default HomepageImage