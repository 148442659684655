import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { HeaderLogoProps } from '.'

export const LogoImage = styled.img.attrs(() => ({
  alt: 'The Marketing Zone by DirecTV logo',
}))<HeaderLogoProps>``

export const LogoLink = styled.a`
  color: ${colors.blue};
  font-weight: bold;
`
