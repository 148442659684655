import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const ManageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

export const BackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
  width: 33.3333%;
`

export const BackButton = styled.button`
  min-width: 145px;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: unset;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const BackText = styled.p`
  font-family: PFDINText;
  letter-spacing: -0.2px;
  font-size: 14px;
  color: ${colors.white};
`

export const PageTitleContainer = styled.div`
  width: 33.3333%;
  text-align: center;
`

export const PageTitle = styled.h1`
  font-family: PFDINText;
  font-size: 28px;
  letter-spacing: -0.2px;
  color: ${colors.white};
  margin: 0 auto;
`

export const RightSideContainer = styled.div`
  margin-left: auto;
  width: 33.3333%;
  height: 100%;
`
