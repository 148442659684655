import React, { useContext, useEffect } from 'react'
import { Address, UserAddresses } from '@monorepo/interfaces'
import { ChangeAddresses } from '@monorepo/components'
import { AddressesContext, ActionAdressType } from '@monorepo/infra'

interface ManageAddressesProps {
  title: string
  addressesList?: Array<Address>
  userId: string
  onSubmit: (data: UserAddresses, addressesListName: string) => void
}

const ManageAddresses: React.FC<ManageAddressesProps> = ({
  title,
  userId,
  addressesList,
  onSubmit,
}) => {
  const {
    billingAddresses,
    shippingAddresses,
    dispatchBillingAddresses,
    dispatchShippingAddresses,
  } = useContext(AddressesContext)

  useEffect(() => {
    dispatchBillingAddresses({
      type: ActionAdressType.SetAddressesList,
      payload: { addresses: addressesList },
    })
    dispatchShippingAddresses({
      type: ActionAdressType.SetAddressesList,
      payload: { addresses: addressesList },
    })
  }, [addressesList, dispatchBillingAddresses, dispatchShippingAddresses])

  return (
    <>
      <ChangeAddresses
        title={title}
        userId={userId}
        addressesList={
          title === 'billing address' ? billingAddresses : shippingAddresses
        }
        dispatchList={
          title === 'billing address'
            ? dispatchBillingAddresses
            : dispatchShippingAddresses
        }
        onSubmit={onSubmit}
      />
    </>
  )
}

export default ManageAddresses
