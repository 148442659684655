import { KeyboardDateTimePicker as MuiKeyboardDateTimePicker } from '@material-ui/pickers'
import { Button, Input } from '@monorepo/components'
import { colors } from '@monorepo/theme'
import { StylesConfig } from 'react-select'
import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

type ButtonProps = { backgroundColor?: string; color?: string }

export const FormButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  text-transform: uppercase;
  margin: 0 12px;
  font-family: PFDINText;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color ?? colors.white};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
  border: ${({ backgroundColor }) =>
    backgroundColor ? 'unset' : `1px solid ${colors.white}`};
  cursor: pointer;
`

export const ColumnsContainer = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  margin-bottom: 80px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const LeftColumn = styled(Column)`
  margin: 0 12px 0 auto;
  width: 767px;
`

export const RightColumn = styled(Column)`
  margin: 0 auto 0 12px;
  width: 379px;
`

export const TextInput = styled(Input)`
  && {
    height: 47px;
    padding: 0 16px;
    font-family: PFDINText;
    letter-spacing: -0.2px;
    font-size: 16px;
    border-radius: 6px;
    ${({ invalid }) => !invalid && `border: 0.3px solid ${colors.grey};`}

    ${({ textarea }) =>
      textarea &&
      `
        padding-top: 12px;
        height: 155px;
        line-height: 22px;
        resize: none;
      `}

    & + label,
    & + label:focus {
      left: 16px;
      font-family: PFDINText;
      font-size: 16px;
      letter-spacing: -0.2px;
      top: ${({ textarea }) => (textarea ? '12px' : '50%')};
      transform: ${({ textarea }) => (textarea ? 'unset' : 'translateY(-50%)')};
      font-weight: normal;
    }

    &:not(:placeholder-shown) + label {
      display: none;
    }
  }
`
export const CheckboxInput = styled.input`
  width: 30px;
  height: 30px;
  left: 0px;
  top: 2.5px;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
  font-family: 'PFDINText';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #000000;
`
export const LocationContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  & > div {
    flex: 1;
  }
`

export const EventDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  & > div {
    flex: 1;
  }
`

export const InputTitle = styled.p`
  color: ${colors.black};
  letter-spacing: -0.2px;
  font-size: 14px;
  font-weight: 500;
  font-family: PFDINText;
  text-transform: uppercase;
  margin-bottom: 4px;
`

type OptionType = {
  label: string
  value: string
}

export const SelectStyle: StylesConfig<OptionType> = {
  container: (base) => ({
    ...base,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 6,
    minHeight: 47,
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'PFDINText',
    fontSize: 16,
    letterSpacing: -0.2,
    fontWeight: 'normal',
    color: colors.mediumGrey,
  }),
}

type IsMulti = true

export const MultiSelectStyle: StylesConfig<OptionType, IsMulti> = {
  container: SelectStyle.container,
  control: SelectStyle.control,
  placeholder: SelectStyle.placeholder,
  multiValueLabel: (base) => ({
    ...base,
    color: colors.white,
    padding: 0,
    marginRight: 10,
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    padding: 0,
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.2s ease-in-out background-color',
    backgroundColor: state.isFocused ? 'black' : 'transparent',
    '&:hover': {
      backgroundColor: 'black',
      color: colors.white,
      cursor: 'pointer',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: colors.blue,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 5,
    borderRadius: 18,
    color: colors.white,
    padding: '0 10px',
  }),
  menu: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  menuList: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  noOptionsMessage: (base, state) => ({
    ...base,
    display: 'none',
  }),
}

export const ProductLifeLabel = styled.p`
  font-family: PFDINText;
  font-size: 15px;
  letter-spacing: -0.2px;
  font-weight: normal;
  margin-bottom: 10px;
`

export const KeyboardDateTimePicker = styled(MuiKeyboardDateTimePicker)`
  width: 197px;

  .MuiInputBase-root {
    height: 47px;
    border: ${({ error }) =>
      error ? '2px solid #E33610' : `0.3px solid ${colors.grey}`};
    border-radius: 6px;
    padding-right: 4px;
  }

  & .MuiInputBase-input {
    height: 47px;
    padding-left: 10px;

    & ~ fieldset.MuiOutlinedInput-notchedOutline {
      display: none;
    }

    & ~ fieldset.MuiOutlinedInput-notchedOutline > legend {
      display: none;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 6px;
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    transform: none;
    top: 16px;
    left: 10px;
    font-family: PFDINText;
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: normal;
    color: ${colors.mediumGrey};

    display: ${({ value }) => (value ? 'none' : 'block')};
  }

  .MuiButtonBase-root {
    width: 34px;
    height: 34px;
    padding: 0;
  }
`

export const EventDateClearContainer = styled.div`
  padding-top: 25px;
`

export const ClearButton = styled(Button)`
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 25px 0 auto; */
`

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const EventDatePickersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StartEndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const EventDateLabel = styled.p`
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 10px;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
`

export const DateRangeDelimiter = styled.hr`
  flex: 0 0 12px;
  height: 1px;
  margin: 36px -15px 0;
  border-top-color: black;
`

export const ButtonForm = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 206px;
  height: 50px;
  background: #337ace;
  order: 0;
  margin: 10px 0px;
  left: calc(50% - 166px / 2);
  top: calc(50% - 22px / 2);
  font-family: 'PFDINText';
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
`
export const Flyer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 21px;
  position: absolute;
  width: 767px;
  height: 120px;
  left: 0px;
  top: 719px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(183, 190, 198, 0.1);
`
