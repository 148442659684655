import * as S from './styles'

import {
  Pagination,
  SectionProducts,
  SectionProductsList,
  TabList,
} from '../../../components'
import {
  useCrud,
  usePaginationSearchParams,
  useQueryParams,
  KeycloakHelper,
} from '@monorepo/infra'

import { Icon, PageSpinner } from '@monorepo/components'
import { ImportLink, PaginationDetails, ExportXlsLink } from '../../atoms'
import { Pagination as PaginationType } from '@monorepo/interfaces'
import { Product } from '@monorepo/interfaces'
import { SearchTerm } from '../../molecules'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import useTextTranslation from '@monorepo/infra/build/locales/i18next/useLocation'
import { useKeycloak } from '@react-keycloak/web'
import { DropzoneArea } from 'material-ui-dropzone'
import axios from 'axios'
import BulkDeleteLink from '../../atoms/BulkDeleteLink'
import { exportXls } from '../Utils'

type ProductsFetch = PaginationType & {
  data: Product[]
}

export type UploadError = {
  line: number
  sku: string
  message: string
}

const Products = (): JSX.Element => {
  const { keycloak } = useKeycloak()
  const productBaseURL = '/product'
  const pageURLKey = 'p'
  const searchURLKey = 's'

  const queryParams = useQueryParams()

  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(searchURLKey) ?? ''
  )
  const [uploadErrors, setUploadErrors] = useState<UploadError[]>([])
  const [showDialog, setShowDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const { t } = useTextTranslation('admin')
  const history = useHistory()

  const { apiSearchParams, handlePageChange, handlePrevPage, handleNextPage } =
    usePaginationSearchParams({
      pageAPIKey: 'pageNum',
      pageURLKey,
      searchAPIKey: 'search',
      searchURLKey,
      searchTerm,
    })

  const apiURL = `${productBaseURL}?${apiSearchParams}`
  const isFeaturedProducts = /featured/i.test(apiURL)

  const { fetch } = useCrud<Product>(apiURL, '_id', {
    revalidateOnFocus: false,
  })

  const { data, loading } = fetch
  const { data: products, ...pagination } = data as ProductsFetch
  pagination.page = Number(pagination.page)

  const handleAddProduct = () => history.push('products/create')

  const handleClearSearchTerm = () => setSearchTerm('')

  const handleUploadFile = (file: File | null) => {
    if (file) {
      setIsUploading(true)
      axios.create({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
      })
      const url = `${process.env.REACT_APP_REST_BASE_URL}/import-product`
      const formData = new FormData()
      formData.append('file', file)
      const config = {
        headers: {
          Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
          'content-type': 'multipart/form-data',
        },
      }
      axios
        .post(url, formData, config)
        .then((success) => {
          setIsUploading(false)
          if (success.data && success.data.hasError) {
            setUploadErrors(success.data.errors)
            setFile(null)
          } else {
            setShowDialog(false)
          }
        })
        .catch((error) => {
          setIsUploading(false)
          setShowDialog(false)
        })
    }
  }

  const handleUploadDeleteFile = (file: File | null) => {
    if (file) {
      setIsUploading(true)
      axios.create({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
      })
      const url = `${process.env.REACT_APP_REST_BASE_URL}/product/bulk-delete`
      const formData = new FormData()
      formData.append('file', file)
      const config = {
        headers: {
          Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
          'content-type': 'multipart/form-data',
        },
      }
      axios
        .post(url, formData, config)
        .then((success) => {
          setIsUploading(false)
          setShowDeleteDialog(false)
          history.go(0)
        })
        .catch((error) => {
          setIsUploading(false)
          setShowDeleteDialog(false)
        })
    }
  }

  const open = () => setShowDialog(true)
  const close = () => {
    setShowDialog(false)
    setUploadErrors([])
  }
  const openDeleteDialog = () => setShowDeleteDialog(true)
  const closeDeleteDialog = () => setShowDeleteDialog(false)

  const handleExportXls = () => {
    exportXls(
      `${process.env.REACT_APP_REST_BASE_URL}/product/export`,
      'product-export.xlsx',
      KeycloakHelper.getToken(keycloak)
    )
  }

  const getDialog = () => {
    return (
      <S.Dialog
        aria-label="Import Products"
        isOpen={showDialog}
        onDismiss={close}
      >
        <S.CloseButton className="close-button" onClick={close}>
          x
        </S.CloseButton>
        <S.DialogTitle>Import products</S.DialogTitle>
        <DropzoneArea
          maxFileSize={100000000}
          filesLimit={1}
          onChange={(files) => {
            setFile(files[0])
          }}
        ></DropzoneArea>
        <S.DialogText>
          Any current products that have the same SKU will not be overwritten.
        </S.DialogText>
        {uploadErrors.length > 0 && (
          <>
            <S.DialogError>
              There are errors with your submission. SKUs with errors will only
              be imported if they contain new variants. If you have previously
              uploaded this file and are missing variants, and missing variants
              will be imported, as well.
            </S.DialogError>
            {uploadErrors.map((uploadError) => {
              return (
                <S.DialogError>{`Line ${uploadError.line}: ${uploadError.message}`}</S.DialogError>
              )
            })}
          </>
        )}
        <S.DialogText>
          <S.DialogLink
            href="https://theindustrious.atlassian.net/wiki/external/2147221505/MDQ1YjYyNTg1MmI5NGFmYWI1MDE5ZGZkODkwOWI4OTY?atlOrigin=eyJpIjoiOGE0NzRjODhkNTkwNGNmYWE5NDdjYTUzZDY3ZTA5ZDYiLCJwIjoiYyJ9"
            target="blank"
          >
            <Icon icon="BooksVertical" size={18} />
            Instructions (for reference)
          </S.DialogLink>
        </S.DialogText>
        <S.ButtonRow>
          <S.CancelButton
            label={`Cancel`}
            colorOption="stroke"
            onClick={close}
            disabled={isUploading}
          />
          <S.UploadButton
            label={isUploading ? `Uploading...` : `Upload and continue`}
            disabled={isUploading}
            onClick={() => handleUploadFile(file)}
          />
        </S.ButtonRow>
        {isUploading && <PageSpinner />}
      </S.Dialog>
    )
  }

  const getDeleteDialog = () => {
    return (
      <S.Dialog
        aria-label="Bulk Delete Products"
        isOpen={showDeleteDialog}
        onDismiss={closeDeleteDialog}
      >
        <S.CloseButton className="close-button" onClick={closeDeleteDialog}>
          x
        </S.CloseButton>
        <S.DialogTitle>Bulk Delete Products</S.DialogTitle>
        <DropzoneArea
          maxFileSize={100000000}
          filesLimit={1}
          onChange={(files) => {
            setFile(files[0])
          }}
        ></DropzoneArea>
        <S.DialogText>
          Please upload an Excel file (.xlsx) with one worksheet titled "Sheet1"
          which contains the word "SKU" in cell A1 and a list of SKUs, one per
          row, in column A
        </S.DialogText>
        <S.ButtonRow>
          <S.CancelButton
            label={`Cancel`}
            colorOption="stroke"
            onClick={closeDeleteDialog}
            disabled={isUploading}
          />
          <S.UploadButton
            label={isUploading ? `Uploading...` : `Upload and continue`}
            disabled={isUploading}
            onClick={() => handleUploadDeleteFile(file)}
          />
        </S.ButtonRow>
        {isUploading && <PageSpinner />}
      </S.Dialog>
    )
  }

  return (
    <>
      <S.Main>
        <SectionProducts />
        <S.PageActions>
          <TabList
            tabKey="status"
            tabs={[
              { description: 'All', tabName: 'all' },
              { description: 'Active', tabName: 'active' },
              { description: 'Featured', tabName: 'featured' },
              { description: 'Draft', tabName: 'draft' },
              { description: 'Archived', tabName: 'archived' },
              { description: 'Expired', tabName: 'expired' },
            ]}
          />
          <S.ActionButtonsContainer>
            <ExportXlsLink onClick={handleExportXls} />
            <ImportLink onClick={open} />
            <BulkDeleteLink onClick={openDeleteDialog} />
            <S.AddProductButton
              label={t('section_products.button_text')}
              colorOption="blue"
              onClick={handleAddProduct}
            />
          </S.ActionButtonsContainer>
        </S.PageActions>
        <S.Separator />
        <S.SearchFiltersContainer>
          <S.Search
            placeholder={t('section_products_list.input_placeholder')}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <SearchTerm searchTerm={searchTerm} onClear={handleClearSearchTerm} />
          <PaginationDetails
            totalItems={pagination.totalCount}
            totalPages={pagination.totalPages}
            itemsPerPage={pagination.pageSize}
            pageNumber={pagination.page}
            onClickPrev={handlePrevPage}
            onClickNext={handleNextPage}
          />
        </S.SearchFiltersContainer>
        <SectionProductsList
          loading={loading}
          products={products}
          isFeaturedProducts={isFeaturedProducts}
        />
        <S.PaginationContainer>
          <Pagination
            onPageChange={handlePageChange}
            totalCount={pagination.totalCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalPages={pagination.totalPages}
          />
        </S.PaginationContainer>
      </S.Main>
      {getDialog()}
      {getDeleteDialog()}
    </>
  )
}

export default Products
