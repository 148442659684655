import React from 'react'
import { SectionUsers, SectionAdminMenus } from '../../organisms'
import * as S from './styles'

const Admin: React.FC = () => (
  <S.Main>
    <SectionUsers title="Admin" />
    <SectionAdminMenus />
  </S.Main>
)

export default Admin
