import { Suspense } from 'react'
import { Header, SideMenu, PageSpinner } from '../../index'
import { mainMenu } from '../../../database/menus'
import * as S from './styles'
import { DashboardHeaderContent } from '../../molecules'

const DashboardTemplate: React.FC = ({ children }) => (
  <>
    <Header>
      <DashboardHeaderContent />
    </Header>
    <S.Container>
      <SideMenu items={mainMenu} />
      <S.Pages>
        <Suspense fallback={<PageSpinner />}>{children}</Suspense>
      </S.Pages>
    </S.Container>
  </>
)

export default DashboardTemplate
