import * as S from './styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { OrderItem, OrderPropsData, Order } from '@monorepo/interfaces'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { formatRelative } from 'date-fns'
import { Spinner } from '@monorepo/components'
import Dinero from 'dinero.js'
import { priceStringToCents } from '@monorepo/infra'

export interface SectionOrdersListProps extends OrderPropsData {
  loading: boolean
}

const SectionOrdersList: React.FC<SectionOrdersListProps> = ({
  orders,
  loading = true,
}) => {
  const getIcons = (items: OrderItem[]) => {
    if (items.length === 0) return <></>
    return (
      <>
        {items.some((item) => item.messages?.length > 0) && (
          <span className="hasMessages">hasMessages</span>
        )}
        {items.some((item) => item.notes && item.notes?.length > 0) && (
          <span className="hasNotes">hasNotes</span>
        )}
      </>
    )
  }

  const getTotal = (items: OrderItem[]) => {
    return Array.isArray(items) && items.length
      ? items
          .reduce(
            (acc, item) =>
              acc.add(
                Dinero({
                  amount: priceStringToCents(item.price),
                }).multiply(item.quantity)
              ),
            Dinero({ amount: 0 })
          )
          .toFormat('0,0.00')
      : '0.00'
  }

  const getPaymentStatus = (paymentStatus: number) => {
    switch (paymentStatus) {
      case 1:
        return 'UNPAID'
      case 2:
        return 'PARTIAL'
      case 3:
        return 'PAID'
    }
    return 'UNKNOWN'
  }

  const getVendors = (items: OrderItem[]) => {
    if (items.length === 0) return <></>
    const uniqueVendors = Array.from(
      new Set(
        items.map((item) => {
          switch (item.product.vendor) {
            case 1:
              return 'PURE'
            case 2:
              return 'DSI'
            default:
              return 'Unknown'
          }
        })
      )
    )

    return (
      <>
        {uniqueVendors.map((vendor) => (
          <span className={`vendor${vendor}`}>{vendor}</span>
        ))}
      </>
    )
  }

  const { t } = useTranslation('admin')
  const history = useHistory()

  const handleViewOrder = (index: number) => {
    const id = orders[index]._id
    history.push(`/orders/view/${id}`)
  }

  return (
    <S.Container>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('section_orders_list.table_title_1')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_2')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_3')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_4')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_5')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_6')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_8')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_9')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_10')}</TableCell>
              <TableCell>{t('section_orders_list.table_title_11')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : (
              orders?.map((order: Order, index: number) => (
                <TableRow key={index} onClick={() => handleViewOrder(index)}>
                  <TableCell>{`#${order.number}`}</TableCell>
                  <TableCell>{getIcons(order.items)}</TableCell>
                  <TableCell>
                    {formatRelative(new Date(order.createdOn), new Date())}
                  </TableCell>
                  <TableCell>
                    {order.customer.userInfo && (
                      <>
                        {`${order.customer.userInfo?.firstName} ${order.customer.userInfo?.lastName}`}
                        <br />
                      </>
                    )}
                    {`(${order.customer.user})`}
                  </TableCell>
                  <TableCell>{order.customer.masterDealerId}</TableCell>
                  <TableCell>{`$ ${getTotal(order.items)}`}</TableCell>
                  <TableCell>{getPaymentStatus(order.paymentStatus)}</TableCell>
                  <TableCell>{`${order.items.length} items`}</TableCell>
                  <TableCell>{getVendors(order.items)}</TableCell>
                  <TableCell>{order.agent}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </S.Container>
  )
}

export default SectionOrdersList
