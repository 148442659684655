import { colors } from '@monorepo/theme'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Container = styled.aside`
  min-width: 240px;
  max-width: 240px;
  width: 240px;
  height: calc(100vh - 88px);
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  background-color: ${colors.white};
`

export const MenuList = styled.ul`
  padding-top: 60px;
`

export const MenuListItem = styled.li`
  height: 40px;
  display: block;
  margin-bottom: 23px;

  &.menu-item-parent {
    margin-bottom: 5px;
  }

  &.menu-item-child {
    margin-bottom: 5px;
  }
`

export const MenuItemLink = styled(NavLink)`
  text-transform: uppercase;
  font-family: PFDINText;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 35px;
  transition: background-color 0.25s ease-in-out;

  &.active {
    padding: 0 35px 0 31px;
    border-left: 4px solid ${colors.blue};
    background-color: ${colors.softGrey};
  }

  &:not(.active):hover {
    background-color: ${colors.softGrey};
  }

  &.menu-item-child {
    font-size: smaller;
    padding 0 0 0 50px;
  }

  &.menu-item-child.active {
    font-size: smaller;
    padding 0 0 0 46px;
  }
`
