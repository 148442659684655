import * as S from './styles'
import { Icon } from '@monorepo/components'

interface ImportLinkProps {
  onClick: () => void
}

const ImportLink: React.FC<ImportLinkProps> = ({ onClick, ...props }) => (
  <S.ImportLink {...props} onClick={onClick}>
    <span>Import</span>
    <Icon icon="ImportIcon" size={17} />
  </S.ImportLink>
)

export default ImportLink
