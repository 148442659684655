import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const SectionTickets: React.FC = () => {
  const { t } = useTranslation('admin')

  return (
    <S.Container>
      <S.TicketsTitle>{t('section_tickets.title')}</S.TicketsTitle>
    </S.Container>
  )
}

export default SectionTickets
