import { colors } from '@monorepo/theme'
import styled from 'styled-components'
import { ManageCardProps } from '.'

export const CardContainer = styled.div<ManageCardProps>`
  padding: 20px 20px 24px;
  background-color: ${({ cardColor }) => cardColor ?? colors.white};
  margin-bottom: 16px;
`

export const CardTitle = styled.h2`
  letter-spacing: -0.2px;
  font-family: PFDINText;
  color: ${colors.black};
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
`
