import { menuCards } from './MenuCards'
import * as S from './styles'

const SectionAdminMenus: React.FC = () => (
  <S.Container>
    {menuCards.map(({ subtitle, cards }, index) => (
      <S.SectionContainer key={index}>
        <S.Subtitle>{subtitle}</S.Subtitle>

        <S.CardsContainer>
          {cards.map(({ iconName, title, link, external }, index) => (
            <S.Card
              to={external ? { pathname: link } : link}
              target={external ? '_blank' : undefined}
              key={index}
            >
              <S.CardTitleContainer>
                <S.Icon icon={iconName} size={50} hasFill={false} />
                <S.CardTitle>{title}</S.CardTitle>
              </S.CardTitleContainer>
              <S.CardDescription></S.CardDescription>
            </S.Card>
          ))}
        </S.CardsContainer>
      </S.SectionContainer>
    ))}
  </S.Container>
)

export default SectionAdminMenus
