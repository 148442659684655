import styled from 'styled-components'
import { Button } from '@monorepo/components'
import BaseSeparator from '../../atoms/Separator'
import { Search as BaseSearch, SearchProps } from '@monorepo/components'
import { colors } from '@monorepo/theme'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SearchFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Search = styled(BaseSearch).attrs({
  backgroundColor: colors.white,
  width: 325,
  height: 36,
})<SearchProps>``

export const FilterButton = styled.button`
  min-width: 65px;
  height: 22px;
  padding: 0;
  font-family: PFDINText;
  font-size: 14px;
  margin: 0 10px 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
  outline: unset;
  cursor: pointer;

  & > figure {
    display: block;
  }
`

export const PageActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 213px;
  height: 38px;
`

export const AddEventButton = styled(Button)`
  padding: 8px 16px;
  width: 131px;
  height: 38px;
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`

export const Separator = styled(BaseSeparator)`
  && {
    width: unset;
    margin: 0 -32px;
    margin-top: -21px;
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`
