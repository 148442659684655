import { ProductStatus } from '@monorepo/interfaces'

const productStatusKeyLabel = Object.entries(ProductStatus).filter(([key]) =>
  parseInt(key)
) as [string, string][]

export const productStatus = productStatusKeyLabel.map(([value, label]) => ({
  value,
  label: `${label.charAt(0)}${label.slice(1).toLowerCase()}`,
}))
