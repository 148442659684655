import React, { useEffect, useState, useCallback } from 'react'
import { ProductsOrderedReport, ProductsOrderedRow } from '@monorepo/interfaces'
import { http, ProductHelper, EventHelper } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

interface ProductsOrderedProps {
  channel: string
  startDate: Date
  endDate: Date
}

const ProductsOrdered: React.FC<ProductsOrderedProps> = ({
  channel,
  startDate,
  endDate,
}) => {
  const [report, setReport] = useState<ProductsOrderedReport | null>(null)
  useEffect(() => {
    http
      .get<ProductsOrderedReport>({
        url: `/reports/productsOrdered?channel=${channel}&dateFrom=${format(
          startDate,
          'yyyy-MM-dd'
        )}&dateTo=${format(endDate, 'yyyy-MM-dd')}`,
      })
      .then(({ data }) => setReport(data))
  }, [channel, endDate, startDate])
  const exportExcel = useCallback(async () => {
    if (report !== null) {
      const reportDataEvents = report.events.map((reportRow) => {
        return {
          Name: reportRow.productName,
          Type: EventHelper.getEventTypeDescription(reportRow.productType),
          Total: reportRow.amountOrdered,
        }
      })
      const reportDataProducts = report.products.map((reportRow) => {
        return {
          Name: reportRow.productName,
          Type: ProductHelper.getTitleFromSize(reportRow.productType),
          Total: reportRow.amountOrdered,
        }
      })
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const eventsWs = XLSX.utils.json_to_sheet(reportDataEvents)
      const productsWs = XLSX.utils.json_to_sheet(reportDataProducts)
      const wb = {
        Sheets: {
          Events: eventsWs,
          Products: productsWs,
        },
        SheetNames: ['Events', 'Products'],
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `ProductsOrdered${fileExtension}`)
    }
  }, [report])
  if (!report || report === null) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Products Ordered
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>{channel.toUpperCase()}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      {report.products.length > 0 && (
        <>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Product Image</S.TableHeadCell>
                  <S.TableHeadCell>Product Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Amount Ordered</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.products.map(
                  (item: ProductsOrderedRow, index: number) => (
                    <S.TableRow key={`products${index}`}>
                      <S.TableCell>
                        <S.TableImageContainer>
                          <S.TableImage src={item.productImage} />
                        </S.TableImageContainer>
                      </S.TableCell>
                      <S.TableCell>{item.productName}</S.TableCell>
                      <S.TableCell>
                        {ProductHelper.getTitleFromSize(item.productType)}
                      </S.TableCell>
                      <S.TableCell>{item.amountOrdered}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
            </S.Table>
          </S.TableContainer>
        </>
      )}
      {report.events.length > 0 && (
        <>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Event Image</S.TableHeadCell>
                  <S.TableHeadCell>Event Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Amount Ordered</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.events.map(
                  (item: ProductsOrderedRow, index: number) => (
                    <S.TableRow key={`events${index}`}>
                      <S.TableCell>
                        <S.TableImageContainer>
                          <S.TableImage src={item.productImage} />
                        </S.TableImageContainer>
                      </S.TableCell>
                      <S.TableCell>{item.productName}</S.TableCell>
                      <S.TableCell>
                        {EventHelper.getEventTypeDescription(item.productType)}
                      </S.TableCell>
                      <S.TableCell>{item.amountOrdered}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
            </S.Table>
          </S.TableContainer>
        </>
      )}
    </S.Container>
  )
}

export default ProductsOrdered
