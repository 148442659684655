import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const BackLink = styled(NavLink)`
  font-family: PFDINText;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  font-weight: 400;
  gap: 10px;
`
