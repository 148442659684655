import styled from 'styled-components'

export const Icon = styled.div`
  > * {
    background-color: lightgrey;
    border-radius: 50px;
    cursor: pointer;
    padding: 3px;
  }
`
