import styled from 'styled-components'
import { Search as BaseSearch, SearchProps } from '@monorepo/components'
import { colors } from '@monorepo/theme'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const SearchTerm = styled.div`
  width: 100%;
  padding: 7px;
`

export const SearchFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Search = styled(BaseSearch).attrs({
  backgroundColor: colors.white,
  width: 325,
  height: 36,
})<SearchProps>``

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const SelectAllContainer = styled.div`
  padding-left: 10px;
`

export const SelectAllLabel = styled.span`
  padding-right: 20px;
`

export const SelectAllButton = styled.span`
  cursor: pointer;
  padding: 0 10px;
  background-color: ${colors.lightGrey};
  border-radius: 20px;
`
