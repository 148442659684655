import styled from 'styled-components'
import { Button } from '@monorepo/components'
import BaseSeparator from '../../atoms/Separator'
import { Search as BaseSearch, SearchProps } from '@monorepo/components'
import { colors } from '@monorepo/theme'
import '@reach/dialog/styles.css'
import { Button as MuiButton } from '@material-ui/core'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SearchFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Search = styled(BaseSearch).attrs({
  backgroundColor: colors.white,
  width: 325,
  height: 36,
})<SearchProps>``

export const PageActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 396px;
  height: 38px;
  gap: 10px;
`

export const AddTicketButton = styled(Button)`
  padding: 8px 16px;
  width: 131px;
  height: 38px;
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`

export const Separator = styled(BaseSeparator)`
  && {
    width: unset;
    margin: 0 -32px;
    margin-top: -21px;
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  gap: 15px;
`

export const Row = styled.div`
  display: flex;
  flex: 3;
  margin: 0;
  padding: 0;
  flex-direction: row;
`

export const TwoThirds = styled.div`
  display: flex;
  flex: 2;
  margin: 0;
  padding: 0;
  flex-direction: column;
  gap: 15px;
`

export const OneThird = styled.div`
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  flex-direction: column;
  gap: 15px;
  justify-items: flex-start;
`

export const SubmitButton = styled(MuiButton)``

export const SelectAllContainer = styled.div`
  padding-left: 10px;
`

export const SelectAllLabel = styled.span`
  padding-right: 20px;
`

export const SelectAllButton = styled.span`
  cursor: pointer;
  padding: 0 10px;
  background-color: ${colors.lightGrey};
  border-radius: 20px;
`
