import React from 'react'
import { Title, EditRole } from '@monorepo/components'
import { RoleProps, FeaturesDataProps } from '@monorepo/interfaces'

interface RoleManageProps extends RoleProps {
  title: string
  features: FeaturesDataProps
}

const ManageRole: React.FC<RoleManageProps> = ({
  features,
  title,
  ...props
}) => {
  return (
    <>
      <Title as={'h1'}>{title}</Title>
      <EditRole {...props} features={features} />
    </>
  )
}

export default ManageRole
