import React from 'react'
import * as S from './styles'
import TmzLogo from '../../../assets/direct-tv-tmz-logo.svg'
import { NavLink } from 'react-router-dom'

export interface HeaderLogoProps {
  redirectLink?: string | null
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({ redirectLink = '/' }) =>
  redirectLink ? (
    <NavLink to={redirectLink}>
      <S.LogoImage src={TmzLogo} />
    </NavLink>
  ) : (
    <S.LogoImage src={TmzLogo} />
  )

export default HeaderLogo
