import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.softGrey};
`

export const Pages = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 0 auto;
  padding: 67px 32px 27px;
  overflow-y: scroll;
  height: calc(100vh - 88px);
  max-height: calc(100vh - 88px);
  position: relative;
`
