import styled from 'styled-components'
import { colors } from '@monorepo/theme'

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  background-color: ${colors.dark};
  height: 88px;
`
