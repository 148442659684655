import * as S from './styles'
import { Icon } from '@monorepo/components'

interface BulkDeleteLinkProps {
  onClick: () => void
}

const BulkDeleteLink: React.FC<BulkDeleteLinkProps> = ({
  onClick,
  ...props
}) => (
  <S.BulkDeleteLink {...props} onClick={onClick}>
    <span>Bulk Remove</span>
    <Icon icon="Close" size={14} />
  </S.BulkDeleteLink>
)

export default BulkDeleteLink
