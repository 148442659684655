import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100vh;
`

const TextContainer = styled.div`
  padding: 40px 30px 60px 30px;
`

const Title = styled.h1`
  font-family: Jubilat;
  font-weight: 600;
  font-style: italic;
  font-size: 52px;
`

const Subtitle = styled.h2`
  font-size: 24px;
`

const Wrap = styled.section`
  display: flex;
  padding: 20px 0;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  > * {
    flex: 50%;
  }
`

const Card = styled.div`
  box-shadow: 1px 0px 16px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
`

const FormTitle = styled.h1`
  font-weight: 500;
  font-size: 24px;
`

type LeftSideProps = {
  background: string
}

const LeftSide = styled.div<LeftSideProps>`
  flex: 50%;
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const Arrow = styled.img`
  position: absolute;
  bottom: 120px;
  left: 80px;
`

const RightSide = styled.div`
  padding: 32px;
  flex: 50%;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  max-height: 100%;
  overflow-y: auto;
`

const Footer = styled.div`
  display: flex;
  padding-top: 120px;
  justify-content: center;
`

const RegistrationError = styled(Subtitle)`
  color: red;
`

export {
  Wrapper,
  Arrow,
  Wrap,
  Column,
  Row,
  Title,
  Subtitle,
  Card,
  FormTitle,
  TextContainer,
  LeftSide,
  RightSide,
  Footer,
  RegistrationError,
}
