import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers'
import { Button, Input } from '@monorepo/components'
import { colors } from '@monorepo/theme'
import { StylesConfig } from 'react-select'
import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

type ButtonProps = { backgroundColor?: string; color?: string }

export const FormButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  text-transform: uppercase;
  margin: 0 12px;
  font-family: PFDINText;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color ?? colors.white};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
  border: ${({ backgroundColor }) =>
    backgroundColor ? 'unset' : `1px solid ${colors.white}`};
  cursor: pointer;
`

export const ColumnsContainer = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  margin-bottom: 80px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const LeftColumn = styled(Column)`
  margin: 0 12px 0 auto;
  width: 767px;
`

export const RightColumn = styled(Column)`
  margin: 0 auto 0 12px;
  width: 379px;
`

export const TextInput = styled(Input)`
  && {
    height: 47px;
    padding: 0 16px;
    font-family: PFDINText;
    letter-spacing: -0.2px;
    font-size: 16px;
    border-radius: 6px;
    ${({ invalid }) => !invalid && `border: 0.3px solid ${colors.grey};`}

    ${({ textarea }) =>
      textarea &&
      `
        padding-top: 12px;
        height: 155px;
        line-height: 22px;
        resize: none;
      `}

    & + label,
    & + label:focus {
      left: 16px;
      font-family: PFDINText;
      font-size: 16px;
      letter-spacing: -0.2px;
      top: ${({ textarea }) => (textarea ? '12px' : '50%')};
      transform: ${({ textarea }) => (textarea ? 'unset' : 'translateY(-50%)')};
      font-weight: normal;
    }

    &:not(:placeholder-shown) + label {
      display: none;
    }
  }
`

type OptionType = {
  label: string
  value: string
}

export const SelectStyle: StylesConfig<OptionType> = {
  container: (base) => ({
    ...base,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 6,
    minHeight: 47,
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'PFDINText',
    fontSize: 16,
    letterSpacing: -0.2,
    fontWeight: 'normal',
    color: colors.mediumGrey,
  }),
}

export const PopUpDateLabel = styled.p`
  font-family: PFDINText;
  font-size: 15px;
  letter-spacing: -0.2px;
  font-weight: normal;
  margin-bottom: 10px;
`

export const KeyboardDatePicker = styled(MuiKeyboardDatePicker)`
  width: 132px;

  .MuiInputBase-root {
    height: 47px;
    border: ${({ error }) =>
      error ? '2px solid #E33610' : `0.3px solid ${colors.grey}`};
    border-radius: 6px;
    padding-right: 4px;
  }

  & .MuiInputBase-input {
    height: 47px;
    padding-left: 10px;

    & ~ fieldset.MuiOutlinedInput-notchedOutline {
      display: none;
    }

    & ~ fieldset.MuiOutlinedInput-notchedOutline > legend {
      display: none;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 6px;
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    transform: none;
    top: 16px;
    left: 10px;
    font-family: PFDINText;
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: normal;
    color: ${colors.mediumGrey};

    display: ${({ value }) => (value ? 'none' : 'block')};
  }

  .MuiButtonBase-root {
    width: 34px;
    height: 34px;
    padding: 0;
  }
`

export const ClearButton = styled(Button)`
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 25px 0 auto; */
`

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const CTAContainer = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const BackgroundImageContainer = styled.div`
  display: flex;
  align-items: center;
`

export const BackgroundImageImageWrapper = styled.div`
  width: 95%;
`

export const BackgroundImageImage = styled.img`
  width: 100%;
  height: 100%;
`

export const BackgroundImageDeleteButtonWrapper = styled.div`
  width: 5%;
`

export const SelectBackgroundImageContainer = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const SelectBackgroundImageOptionContainer = styled.div``

export const SelectBackgroundImageOptionImage = styled.img`
  width: 150px;
  height: 150px;
`

export const SelectBackgroundImageOptionLabel = styled.span`
  margin-left: 20px;
`
