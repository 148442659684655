import moment from 'moment'
import 'moment-timezone'
import { History } from '@monorepo/interfaces'
import * as S from './styles'

export interface TimeLineListProps {
  history?: History[]
}

const TimeLineList: React.FC<TimeLineListProps> = ({ history }) => {
  const compareFn = (a: History, b: History) =>
    new Date(b.date).getTime() - new Date(a.date).getTime()

  return Array.isArray(history) ? (
    <S.TimelineContainer>
      <S.TimelineList>
        {history
          .sort(compareFn)
          .map((historyItem: History, index: number, arr: History[]) => {
            const currentDate = moment(historyItem.date)
            const newDate = currentDate
              .tz('America/Los_Angeles')
              .format('MMMM D, YYYY')
            const previousItem = index > 0 ? arr[index - 1] : { date: '0' }
            const previousDate = moment(new Date(previousItem.date))
              .tz('America/Los_Angeles')
              .format('MMMM D, YYYY')

            return (
              <S.TimelineListItem key={`${index}`}>
                <S.TimelineDescription>
                  {newDate !== previousDate && (
                    <S.TimelineDate>{newDate}</S.TimelineDate>
                  )}
                  <S.TimelineDescriptionText>
                    {historyItem.description}
                  </S.TimelineDescriptionText>
                </S.TimelineDescription>
                <S.TimelineTime>
                  {currentDate.tz('America/Los_Angeles').format('h:mm a z')}
                </S.TimelineTime>
              </S.TimelineListItem>
            )
          })}
      </S.TimelineList>
    </S.TimelineContainer>
  ) : null
}

export default TimeLineList
