import { colors } from '@monorepo/theme'
import { Icon } from '@monorepo/components'
import * as S from './styles'

interface SearchTermProps {
  searchTerm: string
  onClear: () => void
}

type SearchTermReturnType = JSX.Element | null

const SearchTerm = ({
  searchTerm,
  onClear,
}: SearchTermProps): SearchTermReturnType =>
  searchTerm?.trim() === '' ? null : (
    <S.SearchTermContainer>
      <S.ClearSearchButton onClick={onClear}>
        <Icon icon="Close" size={14} color={colors.black} hasFill={false} />
      </S.ClearSearchButton>
      Showing results for:
      <S.SearchTerm>{searchTerm}</S.SearchTerm>
    </S.SearchTermContainer>
  )

export default SearchTerm
