import { colors } from '@monorepo/theme'
import { Form as FormFormik } from 'formik'
import { Button as MuiButton } from '@material-ui/core'
import styled from 'styled-components'

const Form = styled(FormFormik)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    margin: 0 auto;
    max-width: 1440px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    margin: 0 auto;
    max-width: 1440px;
  }
`

const Principal = styled.section`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 969px) {
    width: 70%;
  }
`

const Card = styled.div`
  background-color: #fff;
  padding: 30px;
  margin-bottom: 20px;

  &:not(:first-child) {
    margin-top: 20px;
  }
`

const Options = styled.section`
  width: 100%;

  @media screen and (min-width: 969px) {
    width: 30%;
  }
`

const SubTitle = styled.h2`
  font-family: 'PFDINText';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.2px;
  margin-bottom: 20px;

  color: #000000;
`

const ItemContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 30px;
  background-color: ${colors.white};
`
const ItemList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ItemListItem = styled.li`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

interface ItemRowProps {
  justifyContent?: string
  gap?: string
}

const ItemRow = styled.div<ItemRowProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
  gap: ${(props) => props.gap ?? 'auto'};
  width: 100%;
  line-height: 24px;
  font-size: 18px;
`

const ItemImage = styled.img`
  max-width: 130px;
  max-height: 130px;
  flex: 1;
`

const ItemDetail = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  flex: 2;
`

const ItemTitle = styled.h4`
  font-family: PFDINText;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
`

const ItemText = styled.p`
  font-family: PFDINText;
  font-size: 18px;
  font-weight: 400;
`

const ItemPrice = styled.div`
  display: flex;
  gap: 16px;
`

const ItemPriceField = styled.div`
  display: flex;
  flex-flow: column;

  & > input {
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204);
    height: 40px;
    margin: 0;
    padding: 6px;
    min-width: 0;
  }
`

const AddressField = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 20px;

  & > label {
    margin-bottom: 6px;
  }

  & > input {
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204);
    height: 40px;
    margin: 0;
    padding: 6px;
    min-width: 0;
  }
`

const Line = styled.hr`
  margin: 30px 0 25px 0;
  border: 0;
  border-top: 0.5px solid ${colors.black};
`

const ItemActionButton = styled(MuiButton)`
  &.MuiButton-root {
    border-radius: 0;
    border: 2px solid ${colors.black};
    background-color: ${colors.white};
    align-self: flex-end;
  }

  .MuiButton-label {
    color: ${colors.black};
    font-size: 14px;
    font-weight: 400;
    font-family: PFDINText;
    text-transform: uppercase;
    padding: 8px 16px;
  }
`

const PriceRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  line-height: 24px;
`

const PriceDescription = styled.p`
  flex: 6;
  font-family: PFDINText;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  justify-self: flex-start;
  line-height: 30px;
`

interface PriceAmountProps {
  fontWeight?: string
}

const PriceAmount = styled.p<PriceAmountProps>`
  flex: 1;
  font-family: PFDINText;
  font-size: 18px;
  font-weight: ${(props) => props.fontWeight ?? '400'};
  text-transform: none;
  justify-self: flex-end;
  text-align: right;
  line-height: 30px;
`

export const ButtonsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

type ButtonProps = { backgroundColor?: string; color?: string }

export const FormButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  text-transform: uppercase;
  margin: 0 12px;
  font-family: PFDINText;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color ?? colors.white};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
  border: ${({ backgroundColor }) =>
    backgroundColor ? 'unset' : `1px solid ${colors.white}`};
  cursor: pointer;
`

const RemoveButton = styled.small`
  color: red;
`

export const SelectWithErrorContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const SelectError = styled.div`
  margin: 5px 10px;
`

export const SelectErrorMessage = styled.p`
  color: red;
  font-size: smaller;
`

export {
  Form,
  Principal,
  Options,
  PriceAmount,
  PriceDescription,
  PriceRow,
  ItemActionButton,
  Line,
  ItemPrice,
  ItemText,
  ItemTitle,
  ItemDetail,
  ItemImage,
  ItemRow,
  ItemListItem,
  ItemList,
  ItemContainer,
  Wrapper,
  SubTitle,
  Card,
  ItemPriceField,
  AddressField,
  RemoveButton,
}
