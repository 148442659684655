import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const SectionEvents: React.FC = () => {
  const { t } = useTranslation('admin')

  return (
    <S.Container>
      <S.EventsTitle>{t('section_events.title')}</S.EventsTitle>
    </S.Container>
  )
}

export default SectionEvents
