import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { MessageBlast, ISelectItem } from '@monorepo/interfaces'
import * as Yup from 'yup'
import { Prompt, useHistory, useParams } from 'react-router-dom'
import ManageTemplate from '../../Manage'
import { http } from '@monorepo/infra'
import * as S from './styles'
import { colors } from '@monorepo/theme'
import { ManageCard } from '../../../organisms'
import { Autocomplete, TextField } from '@mui/material'

export enum ManageMessagesEditingMode {
  CREATING = 0,
  EDITING,
}

const channels: ISelectItem[] = [
  { value: 'LSP', id: 'lsp' },
  { value: 'MDU', id: 'mdu' },
  { value: 'D2D', id: 'd2d' },
  { value: 'EM', id: 'em' },
  { value: 'NR', id: 'nr' },
  { value: 'ISP', id: 'isp' },
]

const LoadingStatus = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHING_SUCCESS: 'FETCHING_SUCCESS',
  FETCHING_ERROR: 'FETCHING_ERROR',
  SAVING: 'SAVING',
  SAVING_SUCCESS: 'SAVING_SUCCESS',
  SAVING_ERROR: 'SAVING_ERROR',
  UPLOADING: 'UPLOADING',
  UPLOADING_SUCCESS: 'UPLOADING_SUCCESS',
  UPLOADING_ERROR: 'UPLOADING_ERROR',
}

export interface ManageMessagesProps {
  editingMode: ManageMessagesEditingMode
}

type MessageBlastType = Omit<
  MessageBlast,
  'createdBy' | 'createdOn' | 'updatedBy' | 'updatedOn' | 'from'
>

const initialValues = {
  subject: '',
  message: '',
  type: 3,
  channels: [],
  status: 1,
  relation: '',
}

const ManageNotification: React.FC<ManageMessagesProps> = ({ editingMode }) => {
  const [loadingStatus, setLoadingStatus] = useState<string>(
    LoadingStatus.INITIAL
  )
  const [message, setMessage] = useState<MessageBlastType>(initialValues)
  const MessageSchema = Yup.object().shape({
    subject: Yup.string().required('subject is required'),
    message: Yup.string().required('message is required'),
    channels: Yup.array()
      .of(Yup.string())
      .required('at least one channel is required'),
  })
  const history = useHistory()
  const { id: messageId } = useParams<{ id: string }>()

  const { errors, touched, handleBlur, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        handleSubmit(values)
      })
    },
    validationSchema: MessageSchema,
    initialValues: message,
  })

  const handleGoBack = useCallback(() => {
    history.push('/admin')
  }, [history])

  const handleCreateMessage = useCallback(
    (values: MessageBlastType) => {
      setLoadingStatus(LoadingStatus.SAVING)
      const { channels, ...notification } = values

      http
        .post({
          url: `/notification/channel`,
          data: {
            channels,
            notification,
          },
        })
        .then((response) => {
          setLoadingStatus(LoadingStatus.SAVING_SUCCESS)
          formik.resetForm({ values })
          history.push(`/admin`)
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
          throw error
        })
    },
    [formik, history]
  )

  const handleEditMessage = useCallback(
    (values: MessageBlastType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .patch({ url: `/notification/${messageId}`, data: values })
        .then(() => {
          setLoadingStatus(LoadingStatus.SAVING_SUCCESS)
          formik.resetForm({ values })
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
        })
    },
    [messageId, formik]
  )

  const handleSubmit = useCallback(
    (values: MessageBlastType) => {
      if (editingMode === ManageMessagesEditingMode.CREATING) {
        handleCreateMessage(values)
        return
      }

      if (editingMode === ManageMessagesEditingMode.EDITING) {
        handleEditMessage(values)
        return
      }
    },
    [editingMode, handleCreateMessage, handleEditMessage]
  )

  useEffect(() => {
    const isEditing =
      messageId && editingMode === ManageMessagesEditingMode.EDITING
    const isInitializing = loadingStatus === LoadingStatus.INITIAL
    const shouldSearchResource = isEditing && isInitializing
    if (!shouldSearchResource) return

    setLoadingStatus(LoadingStatus.FETCHING)

    http
      .get({ url: `/notification/${messageId}` })
      .then((response) => {
        const message = response?.data as MessageBlastType
        setMessage(message)
        setLoadingStatus(LoadingStatus.FETCHING_SUCCESS)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.FETCHING_ERROR)
      })
  }, [editingMode, formik, loadingStatus, messageId])

  const handleDiscardChanges = useCallback(() => {
    formik.setValues(message)
  }, [formik, message])

  return (
    <ManageTemplate
      backText="Back to Admin"
      titleText={`${
        editingMode === ManageMessagesEditingMode.CREATING ? 'Create' : 'Manage'
      } Message`}
      onGoBack={handleGoBack}
      headerRightSideChildren={
        <S.ButtonsContainer>
          {formik.dirty && (
            <>
              <S.FormButton onClick={handleDiscardChanges}>
                Discard
              </S.FormButton>
              {formik.isValid && (
                <>
                  <S.FormButton
                    backgroundColor={colors.blue}
                    onClick={() => handleSubmit(formik.values)}
                  >
                    Send
                  </S.FormButton>
                </>
              )}
            </>
          )}
        </S.ButtonsContainer>
      }
    >
      <S.Wrapper>
        <S.ColumnsContainer>
          <Prompt
            when={!!formik.dirty}
            message={
              'You have made changes to this message blast. If you leave, any unsaved changes will be lost. Are you sure?'
            }
          />
          <Prompt
            when={loadingStatus === LoadingStatus.SAVING_SUCCESS}
            message={'Your message blast was sent successfully!'}
          />
          <S.LeftColumn>
            <div className="two-thirds">
              <ManageCard title="Subject">
                <S.TextInput
                  id="subject"
                  name="subject"
                  label="Subject"
                  value={formik.values.subject}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('subject', value)
                  }
                  onBlur={handleBlur}
                  invalid={!!errors.subject && touched.subject}
                  invalidMessage={errors.subject}
                />
              </ManageCard>
              <ManageCard title="Text">
                <S.TextInput
                  id="message"
                  name="message"
                  label="Message"
                  textarea
                  value={formik.values.message}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('message', value)
                  }
                  onBlur={handleBlur}
                  invalid={!!errors.message && touched.message}
                  invalidMessage={errors.message}
                />
              </ManageCard>
            </div>
          </S.LeftColumn>
          <S.RightColumn>
            <div className="one-third">
              <ManageCard title="Channels">
                <Autocomplete
                  multiple
                  id="channels-outlined"
                  options={channels}
                  value={channels.filter((channel) =>
                    formik.values.channels?.includes(channel.id)
                  )}
                  getOptionLabel={(option: ISelectItem) => option.value}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="channels"
                      name="Channels"
                      label="Channels"
                    />
                  )}
                  onBlur={handleBlur}
                  onChange={(event, values) => {
                    formik.setFieldValue(
                      'channels',
                      values.map((value) => {
                        return value.id
                      })
                    )
                  }}
                />
              </ManageCard>
            </div>
          </S.RightColumn>
        </S.ColumnsContainer>
      </S.Wrapper>
    </ManageTemplate>
  )
}

export default ManageNotification
