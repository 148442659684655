import { Vendor } from '@monorepo/interfaces'

export const mainMenu = [
  {
    text: 'Notifications',
    link: '/notifications',
  },
  {
    text: 'Orders',
    link: '/orders',
    permissions: ['order.view.own', 'order.view.sub', 'order.view.all'],
    className: 'menu-item-parent',
  },
  {
    text: 'Products',
    link: '/orders',
    permissions: ['order.view.own', 'order.view.sub', 'order.view.all'],
    search: `vendor=${Vendor.PURE}`,
    className: 'menu-item-child',
  },
  {
    text: 'Events',
    link: '/orders',
    permissions: ['order.view.own', 'order.view.sub', 'order.view.all'],
    search: `vendor=${Vendor.DSI}`,
    className: 'menu-item-child',
  },
  {
    text: 'Events',
    link: '/events',
    permissions: ['event.create'],
  },
  {
    text: 'Products',
    link: '/products',
    permissions: ['product.create'],
  },
  {
    text: 'Support Center',
    link: '/tickets',
    permissions: ['ticket.view.own', 'ticket.view.sub', 'ticket.view.all'],
    className: 'menu-item-parent',
  },
  {
    text: 'Products',
    link: '/tickets',
    permissions: ['ticket.view.own', 'ticket.view.sub', 'ticket.view.all'],
    search: `vendor=${Vendor.PURE}`,
    className: 'menu-item-child',
  },
  {
    text: 'Events',
    link: '/tickets',
    permissions: ['ticket.view.own', 'ticket.view.sub', 'ticket.view.all'],
    search: `vendor=${Vendor.DSI}`,
    className: 'menu-item-child',
  },
  {
    text: 'Reports & Analytics',
    link: '/reports',
    permissions: ['report.view'],
  },
  {
    text: 'Admin',
    link: '/admin',
  },
]
