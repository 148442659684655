import { Suspense } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Header, PageSpinner } from '../../index'
import { ManageHeaderContent } from '../../molecules'
import * as S from './styles'

interface ManageTemplateProps {
  backText?: string
  titleText?: string
  headerRightSideChildren?: JSX.Element
  onGoBack?: () => void
}

const ManageTemplate: React.FC<ManageTemplateProps> = ({
  backText,
  onGoBack,
  titleText,
  headerRightSideChildren,
  children,
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Header>
      <ManageHeaderContent
        backText={backText}
        titleText={titleText}
        rightSideChildren={headerRightSideChildren}
        onGoBack={onGoBack}
      />
    </Header>
    <S.ManagePageContainer>
      <Suspense fallback={<PageSpinner />}>{children}</Suspense>
    </S.ManagePageContainer>
  </MuiPickersUtilsProvider>
)

export default ManageTemplate
