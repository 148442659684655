import * as S from './styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { useFormik } from 'formik'
import _ from 'lodash'
import {
  http,
  TicketHelper,
  httpUserInfo,
  UserHelper,
  isNumber,
  KeycloakHelper,
} from '@monorepo/infra'
import { colors } from '@monorepo/theme'
import { Ticket, DealerResponse, TicketStatus } from '@monorepo/interfaces'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import moment from 'moment'
import 'moment-timezone'
import { vendors } from '../../../../database'
import ManageTemplate from '../../Manage'
import { PageSpinner } from '../../..'
import { ManageCard } from '../../../organisms'
import {
  TicketCreatingSchema,
  TicketEditingSchema,
  initialValues,
} from './ManageFormHelpers'

export enum ManageTicketsEditingMode {
  CREATING = 0,
  EDITING,
}

const LoadingStatus = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHING_SUCCESS: 'FETCHING_SUCCESS',
  FETCHING_ERROR: 'FETCHING_ERROR',
  SAVING: 'SAVING',
  SAVING_SUCCESS: 'SAVING_SUCCESS',
  SAVING_ERROR: 'SAVING_ERROR',
}

export interface ManageTicketsProps {
  editingMode: ManageTicketsEditingMode
}

type SelectItemType = {
  value: string
  label: string
}

type TicketType = Omit<Ticket, 'number' | 'createdBy' | 'createdOn'> & {
  message?: string
}

const ticketCategoryOptions = [
  TicketHelper.getTicketCategoryProductOptions(),
  TicketHelper.getTicketCategoryEventOptions(),
]

const ManageTickets: React.FC<ManageTicketsProps> = ({ editingMode }) => {
  const [loadingStatus, setLoadingStatus] = useState<string>(
    LoadingStatus.INITIAL
  )
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)

  const [hasSavePermissions, setHasSavePermissions] = useState<boolean>(false)
  const [hasMessageAddPermissions, setHasMessageAddPermissions] =
    useState<boolean>(false)
  const [hasDeletePermissions, setHasDeletePermissions] =
    useState<boolean>(false)
  const [hasClosePermissions, setHasClosePermissions] = useState<boolean>(false)

  const [categoryOptions, setCategoryOptions] =
    useState<{ value: string; label: string }[]>()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [masterDealers, setMasterDealers] = useState<any[]>()
  const [channelOptions, setChannelOptions] =
    useState<{ value: string; label: string }[]>()

  const requiredCreatingTicketPermissions = [
    'ticket.create.own',
    'ticket.create.all',
  ]
  const requiredEditingTicketPermissions = 'ticket.update.all'
  const requiredDeletingTicketPermissions = 'ticket.update.all'
  const requiredAddingAllMessagePermissions = 'ticket.message.add.all'
  const requiredAddingOwnMessagePermissions = 'ticket.message.add.own'

  const setPermissions = (ticket?: Ticket) => {
    const hasSavePermissions =
      UserHelper.isImpersonatedUserAdmin(keycloak) ||
      UserHelper.isAdmin(user?.groups) ||
      (editingMode === ManageTicketsEditingMode.CREATING &&
        (UserHelper.hasImpersonatedUserPermissions(
          keycloak,
          requiredCreatingTicketPermissions
        ) ||
          UserHelper.hasPermissions(
            user?.permissions,
            requiredCreatingTicketPermissions
          ))) ||
      (editingMode === ManageTicketsEditingMode.EDITING &&
        (UserHelper.hasImpersonatedUserPermissions(
          keycloak,
          requiredEditingTicketPermissions
        ) ||
          UserHelper.hasPermissions(
            user?.permissions,
            requiredEditingTicketPermissions
          )))
    setHasSavePermissions(hasSavePermissions)

    const hasMessageAddPermissions =
      UserHelper.isImpersonatedUserAdmin(keycloak) ||
      UserHelper.isAdmin(user?.groups) ||
      UserHelper.hasImpersonatedUserPermissions(
        keycloak,
        requiredAddingAllMessagePermissions
      ) ||
      UserHelper.hasPermissions(
        user?.permissions,
        requiredAddingAllMessagePermissions
      ) ||
      (ticket?.createdBy === user?.email &&
        (UserHelper.hasImpersonatedUserPermissions(
          keycloak,
          requiredAddingOwnMessagePermissions
        ) ||
          UserHelper.hasPermissions(
            user?.permissions,
            requiredAddingOwnMessagePermissions
          )))
    setHasMessageAddPermissions(hasMessageAddPermissions)

    const hasDeletePermissions =
      UserHelper.isImpersonatedUserAdmin(keycloak) ||
      UserHelper.isAdmin(user?.groups) ||
      UserHelper.hasImpersonatedUserPermissions(
        keycloak,
        requiredDeletingTicketPermissions
      ) ||
      UserHelper.hasPermissions(
        user?.permissions,
        requiredDeletingTicketPermissions
      )
    setHasDeletePermissions(hasDeletePermissions)

    const hasClosePermissions =
      UserHelper.isImpersonatedUserAdmin(keycloak) ||
      UserHelper.isAdmin(user?.groups) ||
      UserHelper.hasImpersonatedUserPermissions(
        keycloak,
        requiredEditingTicketPermissions
      ) ||
      UserHelper.hasPermissions(
        user?.permissions,
        requiredEditingTicketPermissions
      )
    setHasClosePermissions(hasClosePermissions)
  }

  const [ticket, setTicket] = useState<TicketType>(initialValues)
  const history = useHistory()
  const { id: ticketId } = useParams<{ id: string }>()

  const { errors, touched, handleBlur, ...formik } = useFormik({
    enableReinitialize: true,
    validationSchema:
      editingMode === ManageTicketsEditingMode.CREATING
        ? TicketCreatingSchema
        : TicketEditingSchema,
    initialValues: ticket,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        handleSubmit(values)
      })
    },
  })

  const priorities = TicketHelper.getTicketPriorityOptions()
  const statuses = TicketHelper.getTicketStatusOptions()

  const ticketsUrl = '/tickets'
  const ticketApiUrl = '/ticket'

  const handleGoBack = useCallback(() => {
    history.push(`${ticketsUrl}?status=active`)
  }, [history])

  const handleCreateTicket = useCallback(
    (values: TicketType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .post({
          url: ticketApiUrl,
          data: {
            ...(!!values.masterDealerId && {
              masterDealerId: values.masterDealerId,
            }),
            subject: values.subject,
            priority: values.priority,
            status: values.status,
            ...(!!values.vendor && { vendor: values.vendor }),
            ...(!!values.channel && { channel: values.channel }),
            ...(!!values.category && { category: values.category }),
            tags: values.tags,
            ...(!!values.agent && { agent: values.agent }),
            ...(!!values.dealer && { dealer: values.dealer }),
            ...(!!values.assignee && { assignee: values.assignee }),
            messages: [
              {
                message: values.message,
                createdBy: user?.email,
                createdOn: new Date().toISOString(),
              },
            ],
            createdBy: user?.email,
            createdOn: new Date().toISOString(),
          },
        })
        .then((response) => {
          const ticket = response?.data as Ticket

          setLoadingStatus(LoadingStatus.INITIAL)
          history.push(`${ticketsUrl}/edit/${ticket?._id}`)
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  )

  const handleEditTicket = useCallback(
    (values: TicketType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .patch({
          url: `${ticketApiUrl}/${ticketId}`,
          data: {
            masterDealerId: !!values.masterDealerId
              ? values.masterDealerId
              : null,
            subject: values.subject,
            priority: values.priority,
            status: values.status,
            vendor: values.vendor ? values.vendor : null,
            channel: values.channel ? values.channel : null,
            category: !!values.category ? values.category : null,
            tags: values.tags,
            agent: !!values.agent ? values.agent : null,
            dealer: !!values.dealer ? values.dealer : null,
            assignee: !!values.assignee ? values.assignee : null,
            updatedBy: user?.email,
            updatedOn: new Date().toISOString(),
          },
        })
        .then(() => {
          setLoadingStatus(LoadingStatus.INITIAL)
          history.push(`${ticketsUrl}/edit/${ticketId}`)
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, ticketId, formik]
  )

  const handleSubmit = useCallback(
    (values: TicketType) => {
      if (editingMode === ManageTicketsEditingMode.CREATING) {
        handleCreateTicket(values)
        return
      }

      if (editingMode === ManageTicketsEditingMode.EDITING) {
        handleEditTicket(values)
        return
      }
    },
    [editingMode, handleCreateTicket, handleEditTicket]
  )

  const handleDiscardChanges = useCallback(() => {
    formik.setValues(ticket)
  }, [ticket, formik])

  const handleDeleteTicket = useCallback(() => {
    setLoadingStatus(LoadingStatus.SAVING)

    http
      .delete({ url: `${ticketApiUrl}/${ticketId}` })
      .then(() => {
        setLoadingStatus(LoadingStatus.INITIAL)
        history.push(`${ticketsUrl}/edit/${ticketId}`)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.SAVING_ERROR)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const handleCloseTicket = useCallback(() => {
    setLoadingStatus(LoadingStatus.SAVING)

    http
      .patch({ url: `${ticketApiUrl}/${ticketId}/close` })
      .then(() => {
        setLoadingStatus(LoadingStatus.INITIAL)
        history.push(`${ticketsUrl}/edit/${ticketId}`)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.SAVING_ERROR)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const handleReactivateTicket = useCallback(() => {
    if (!ticket) {
      return
    }

    setLoadingStatus(LoadingStatus.SAVING)

    http
      .patch({
        url: `${ticketApiUrl}/${ticketId}`,
        data: {
          masterDealerId: ticket.masterDealerId,
          subject: ticket.subject,
          priority: ticket.priority,
          status: TicketStatus.NEW,
          vendor: ticket.vendor,
          channel: ticket.channel,
          category: ticket.category,
          tags: ticket.tags,
          agent: ticket.agent,
          dealer: ticket.dealer,
          assignee: ticket.assignee,
          updatedBy: user?.email,
          updatedOn: new Date().toISOString(),
        },
      })
      .then(() => {
        setLoadingStatus(LoadingStatus.INITIAL)
        history.push(`${ticketsUrl}/edit/${ticketId}`)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.SAVING_ERROR)
      })
  }, [history, ticket])

  const handleAddMessage = useCallback(
    (message?: string) => {
      if (!message) {
        return
      }

      setLoadingStatus(LoadingStatus.SAVING)

      http
        .patch({
          url: `${ticketApiUrl}/${ticketId}/add-message`,
          data: {
            message,
            createdBy: user?.email,
            createdOn: new Date().toISOString(),
          },
        })
        .then(() => {
          setLoadingStatus(LoadingStatus.INITIAL)
          history.push(`${ticketsUrl}/edit/${ticketId}`)
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  )

  useEffect(() => {
    setPermissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const isEditing =
      ticketId && editingMode === ManageTicketsEditingMode.EDITING
    const isInitializing = loadingStatus === LoadingStatus.INITIAL
    const shouldSearchTicket = isEditing && isInitializing

    if (!shouldSearchTicket) return

    setLoadingStatus(LoadingStatus.FETCHING)

    http
      .get({ url: `${ticketApiUrl}/${ticketId}` })
      .then((response) => {
        const ticket = response?.data as Ticket

        setTicket(ticket)
        if (!!ticket.channel) {
          setChannelOptions([
            {
              value: ticket.channel.toLowerCase(),
              label: ticket.channel.toUpperCase(),
            },
          ])
        }

        if (ticket.vendor && isNumber(ticket.vendor) && ticket.vendor > 0) {
          setCategoryOptions(ticketCategoryOptions[ticket.vendor - 1])
        }

        setPermissions(ticket)
        setLoadingStatus(LoadingStatus.FETCHING_SUCCESS)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.FETCHING_ERROR)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingMode, ticketId, formik, loadingStatus])

  const handleMasterDealerIdSearch = (
    inputValue: string,
    callback: (options: SelectItemType[]) => void
  ) => {
    if (!callback) {
      return
    }

    if (!inputValue) {
      callback([])
      return
    }

    httpUserInfo
      .get<{ data?: unknown }>({
        url: `/masterdealer/search?masterDealerId=${inputValue}`,
      })
      .then((res) => res?.data)
      .then((res) => {
        const masterDealers = Array.isArray(res?.data)
          ? res.data.filter((item) => !!item.masterDealerId)
          : []

        setMasterDealers(masterDealers)

        callback(
          masterDealers.map(
            (item) =>
              mapMasterDealerIdToSelectItemType(
                item.masterDealerId
              ) as SelectItemType
          )
        )
      })
      .catch(() => callback([]))
  }

  const handleAgentSearch = (
    inputValue: string,
    callback: (options: SelectItemType[]) => void
  ) => {
    if (!callback) {
      return
    }

    if (!inputValue) {
      callback([])
      return
    }

    httpUserInfo
      .get<{ data?: unknown }>({
        url: `/user/agents?page=1&pageSize=50&search=${inputValue}`,
      })
      .then((res) => res?.data)
      .then((res) =>
        callback(
          Array.isArray(res?.data)
            ? res.data
                .filter((item) => !!item.email)
                .map(
                  (item) =>
                    mapAgentToSelectItemType(item.email) as SelectItemType
                )
            : []
        )
      )
      .catch(() => callback([]))
  }

  const handleDealerSearch = (
    inputValue: string,
    callback: (options: SelectItemType[]) => void
  ) => {
    if (!callback) {
      return
    }

    if (!inputValue) {
      callback([])
      return
    }

    httpUserInfo
      .get<DealerResponse>({
        url: `/dealer/?page=1&pageSize=50&showSelf=true&search=${inputValue}`,
      })
      .then((res) => res?.data)
      .then((res) =>
        callback(
          Array.isArray(res?.data)
            ? res.data
                .filter((item) => !!item.email)
                .map(
                  (item) =>
                    mapDealerToSelectItemType(item.email) as SelectItemType
                )
            : []
        )
      )
      .catch(() => callback([]))
  }

  const handleAssigneeSearch = (
    inputValue: string,
    callback: (options: SelectItemType[]) => void
  ) => {
    if (!callback) {
      return
    }

    if (!inputValue) {
      callback([])
      return
    }

    httpUserInfo
      .get<DealerResponse>({
        url: `/dealer/?page=1&pageSize=50&showSelf=true&search=${inputValue}`,
      })
      .then((res) => res?.data)
      .then((res) =>
        callback(
          Array.isArray(res?.data)
            ? res.data
                .filter((item) => !!item.email)
                .map(
                  (item) =>
                    mapAssigneeToSelectItemType(item.email) as SelectItemType
                )
            : []
        )
      )
      .catch(() => callback([]))
  }

  const debouncedHandleMasterDealerIdSearch = _.debounce(
    handleMasterDealerIdSearch,
    500
  )
  const debouncedHandleAgentSearch = _.debounce(handleAgentSearch, 500)
  const debouncedHandleDealerSearch = _.debounce(handleDealerSearch, 500)
  const debouncedHandleAssigneeSearch = _.debounce(handleAssigneeSearch, 500)

  const mapMasterDealerIdToSelectItemType = (
    masterDealerId?: string
  ): SelectItemType | undefined =>
    !!masterDealerId
      ? ({ value: masterDealerId, label: masterDealerId } as SelectItemType)
      : undefined

  const mapAgentToSelectItemType = (
    email?: string
  ): SelectItemType | undefined =>
    !!email ? ({ value: email, label: email } as SelectItemType) : undefined

  const mapDealerToSelectItemType = (
    email?: string
  ): SelectItemType | undefined =>
    !!email ? ({ value: email, label: email } as SelectItemType) : undefined

  const mapAssigneeToSelectItemType = (
    email?: string
  ): SelectItemType | undefined =>
    !!email ? ({ value: email, label: email } as SelectItemType) : undefined

  const getChannels = (masterDealerId?: string) => {
    const masterDealer = Array.isArray(masterDealers)
      ? masterDealers.find((item) => item.masterDealerId === masterDealerId)
      : undefined

    return Array.isArray(masterDealer?.channels)
      ? masterDealer?.channels.map((item: string) => ({
          value: item.toLowerCase(),
          label: item.toUpperCase(),
        }))
      : []
  }

  return (
    <div>
      <ManageTemplate
        onGoBack={handleGoBack}
        backText="Back to active Tickets"
        titleText={`${
          editingMode === ManageTicketsEditingMode.CREATING
            ? 'Create'
            : 'Manage'
        } Ticket`}
        headerRightSideChildren={
          <S.ButtonsContainer>
            <S.FormButton onClick={handleDiscardChanges}>Discard</S.FormButton>
            {hasDeletePermissions &&
              editingMode === ManageTicketsEditingMode.EDITING &&
              ticket?.status !== TicketStatus.DELETED && (
                <S.FormButton onClick={handleDeleteTicket}>Delete</S.FormButton>
              )}
            {hasClosePermissions &&
              editingMode === ManageTicketsEditingMode.EDITING &&
              ticket?.status !== TicketStatus.CLOSED && (
                <S.FormButton onClick={handleCloseTicket}>Close</S.FormButton>
              )}
            {hasSavePermissions &&
              editingMode === ManageTicketsEditingMode.EDITING &&
              (ticket?.status === TicketStatus.DELETED ||
                ticket?.status === TicketStatus.CLOSED) && (
                <S.FormButton onClick={handleReactivateTicket}>
                  Reactivate
                </S.FormButton>
              )}
            {hasSavePermissions && formik.isValid && formik.dirty && (
              <S.FormButton
                backgroundColor={colors.blue}
                onClick={() => handleSubmit(formik.values)}
              >
                Save
              </S.FormButton>
            )}
          </S.ButtonsContainer>
        }
      >
        {[LoadingStatus.FETCHING, LoadingStatus.SAVING].includes(
          loadingStatus
        ) ? (
          <PageSpinner />
        ) : (
          <S.ColumnsContainer>
            <S.LeftColumn>
              <ManageCard title="Subject">
                <S.TextInput
                  name="subject"
                  label="Subject"
                  value={formik.values.subject}
                  onBlur={handleBlur}
                  invalid={!!errors.subject && touched.subject}
                  invalidMessage={errors.subject}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('subject', value)
                  }
                />
              </ManageCard>
              <ManageCard title="Message">
                {Array.isArray(ticket?.messages) && ticket.messages.length > 0 && (
                  <S.MessagesContainer>
                    <S.MessagesList>
                      {ticket.messages.map((message, index) => {
                        return (
                          <S.MessagesListItem key={index}>
                            <S.Message>
                              <S.MessageCreated>{`${moment(message.createdOn)
                                .tz('America/Los_Angeles')
                                .format('MM/DD/YYYY hh:mm:ss a z')} ${
                                message.createdBy
                              }`}</S.MessageCreated>
                              <S.MessageMessage>
                                {message.message}
                              </S.MessageMessage>
                            </S.Message>
                          </S.MessagesListItem>
                        )
                      })}
                    </S.MessagesList>
                  </S.MessagesContainer>
                )}
                <S.TextInput
                  name="message"
                  label="Message"
                  value={formik.values.message}
                  onBlur={handleBlur}
                  invalid={!!errors.message && touched.message}
                  invalidMessage={errors.message}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('message', value)
                  }
                />
                {hasMessageAddPermissions &&
                  editingMode === ManageTicketsEditingMode.EDITING &&
                  !!formik.values.message && (
                    <S.MessagesButtonsContainer>
                      <S.ButtonForm
                        onClick={() => handleAddMessage(formik.values.message)}
                      >
                        Send
                      </S.ButtonForm>
                    </S.MessagesButtonsContainer>
                  )}
              </ManageCard>
            </S.LeftColumn>
            <S.RightColumn>
              <ManageCard title="Properties">
                <S.PropertyLabel>Master Dealer ID</S.PropertyLabel>
                <AsyncSelect
                  name="masterDealerId"
                  placeholder="Search master dealer id..."
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={mapMasterDealerIdToSelectItemType(
                    formik.values.masterDealerId
                  )}
                  isClearable={true}
                  isSearchable={true}
                  loadOptions={debouncedHandleMasterDealerIdSearch}
                  onChange={(newValue) => {
                    const value = (newValue as SelectItemType)?.value ?? ''
                    setChannelOptions(getChannels(value))
                    formik.setFieldValue('masterDealerId', value)
                  }}
                  onBlur={handleBlur}
                  styles={S.SelectStyle}
                />
                <S.PropertyLabel>Channel</S.PropertyLabel>
                <Select
                  name="channel"
                  placeholder=""
                  options={channelOptions}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={channelOptions?.find(
                    ({ value }) => value === formik.values.channel
                  )}
                  onChange={(newValue) => {
                    const channel = (newValue as SelectItemType)
                      ?.value as string
                    formik.setFieldValue('channel', channel)
                  }}
                  styles={S.SelectStyle}
                  onBlur={handleBlur}
                />
                <S.PropertyLabel>Priority</S.PropertyLabel>
                <Select
                  name="priority"
                  placeholder=""
                  options={priorities}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    typeof formik.values.priority === 'number'
                      ? priorities.find(
                          ({ value }) =>
                            value === String(formik.values.priority)
                        )
                      : formik.values.priority
                  }
                  onChange={(newValue) => {
                    const priority = (newValue as SelectItemType)
                      ?.value as string

                    formik.setFieldValue('priority', parseInt(priority))
                  }}
                  styles={S.SelectStyle}
                  onBlur={handleBlur}
                />
                <S.PropertyLabel>Status</S.PropertyLabel>
                <Select
                  name="status"
                  placeholder=""
                  options={statuses}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    typeof formik.values.status === 'number'
                      ? statuses.find(
                          ({ value }) => value === String(formik.values.status)
                        )
                      : formik.values.status
                  }
                  onChange={(newValue) => {
                    const status = (newValue as SelectItemType)?.value as string

                    formik.setFieldValue('status', parseInt(status))
                  }}
                  styles={S.SelectStyle}
                  onBlur={handleBlur}
                />
                <S.PropertyLabel>Vendor</S.PropertyLabel>
                <Select
                  name="vendor"
                  placeholder=""
                  options={vendors}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    typeof formik.values.vendor === 'number'
                      ? vendors.find(
                          ({ value }) => value === String(formik.values.vendor)
                        )
                      : formik.values.vendor
                  }
                  onChange={(newValue) => {
                    const vendor = parseInt(
                      (newValue as SelectItemType)?.value as string
                    )

                    formik.setFieldValue('vendor', vendor)
                    setCategoryOptions(ticketCategoryOptions[vendor - 1])
                    formik.setFieldValue('category', undefined)
                  }}
                  styles={S.SelectStyle}
                  onBlur={handleBlur}
                />
                <S.PropertyLabel>Category</S.PropertyLabel>
                <Select
                  name="category"
                  placeholder=""
                  options={categoryOptions}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    typeof formik.values.category === 'number'
                      ? categoryOptions?.find(
                          ({ value }) =>
                            value === String(formik.values.category)
                        )
                      : formik.values.category
                  }
                  onChange={(newValue) => {
                    const category = parseInt(
                      (newValue as SelectItemType)?.value as string
                    )

                    formik.setFieldValue('category', category)
                  }}
                  styles={S.SelectStyle}
                  onBlur={handleBlur}
                />
                <S.PropertyLabel>Agent (optional)</S.PropertyLabel>
                <AsyncSelect
                  name="agent"
                  placeholder="Search agent..."
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={mapAgentToSelectItemType(formik.values.agent)}
                  isClearable={true}
                  isSearchable={true}
                  loadOptions={debouncedHandleAgentSearch}
                  onChange={(newValue) =>
                    formik.setFieldValue(
                      'agent',
                      (newValue as SelectItemType)?.value ?? ''
                    )
                  }
                  onBlur={handleBlur}
                  styles={S.SelectStyle}
                />
                <S.PropertyLabel>Dealer (optional)</S.PropertyLabel>
                <AsyncSelect
                  name="dealer"
                  placeholder="Search dealer..."
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={mapDealerToSelectItemType(formik.values.dealer)}
                  isClearable={true}
                  isSearchable={true}
                  loadOptions={debouncedHandleDealerSearch}
                  onChange={(newValue) =>
                    formik.setFieldValue(
                      'dealer',
                      (newValue as SelectItemType)?.value ?? ''
                    )
                  }
                  onBlur={handleBlur}
                  styles={S.SelectStyle}
                />
                <S.PropertyLabel>Assignee (optional)</S.PropertyLabel>
                <AsyncSelect
                  name="assignee"
                  placeholder="Search assignee..."
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={mapAssigneeToSelectItemType(formik.values.assignee)}
                  isClearable={true}
                  isSearchable={true}
                  loadOptions={debouncedHandleAssigneeSearch}
                  onChange={(newValue) =>
                    formik.setFieldValue(
                      'assignee',
                      (newValue as SelectItemType)?.value ?? ''
                    )
                  }
                  onBlur={handleBlur}
                  styles={S.SelectStyle}
                />
              </ManageCard>
            </S.RightColumn>
          </S.ColumnsContainer>
        )}
      </ManageTemplate>
    </div>
  )
}

export default ManageTickets
