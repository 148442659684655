import { TicketHelper, isObject } from '@monorepo/infra'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const populateTicketReportData = (data: any) => {
  if (!data || !isObject(data)) {
    return { data: undefined, keys: undefined }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: { [key: string]: any } = {}
  const keys = Object.keys(data)

  for (let i = 0, l = keys.length; i < l; ++i) {
    result[keys[i]] = TicketHelper.populateTickets(data[keys[i]])
  }

  return { data: result, keys }
}
