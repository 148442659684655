import * as Yup from 'yup'

import { Event, OrderProps, Product } from '@monorepo/interfaces'
import { Field, Form, Formik } from 'formik'

import { ProductSearch } from '../../../organisms'
import React from 'react'
import { useCrud } from '@monorepo/infra'

type OrderEditProps = OrderProps

const OrderSchema = Yup.object().shape({
  customer: Yup.object().shape({
    user: Yup.string().required(),
  }),
  items: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number().required('quantity is required'),
      price: Yup.string().required('price is required'),
      dealerName: Yup.string().optional(),
      dealerAddress: Yup.string().optional(),
      dealerAddress2: Yup.string().optional(),
      dealerPhone: Yup.string().optional(),
      notes: Yup.string().optional(),
      status: Yup.number().required('order item status is required'),
    })
  ),
})

const EditOrders: React.FC<OrderEditProps> = ({ order }) => {
  const { fetch: productFetch, search: productSearch } = useCrud(
    '/product',
    '_id',
    { revalidateOnFocus: false }
  )
  const { data: products } = productFetch

  const { fetch: eventFetch, search: eventSearch } = useCrud('/event', '_id', {
    revalidateOnFocus: false,
  })
  const { data: events } = eventFetch
  const onProductSearchClick = (item: Product | Event) => {
    console.log(item)
  }
  const onProductSearch = (search: Record<string, unknown>) => {
    productSearch(search)
    eventSearch(search)
  }

  return (
    <div>
      <Formik
        initialValues={order}
        validationSchema={OrderSchema}
        onSubmit={(values, actions) => {
          console.log({ values, actions })
          alert(JSON.stringify(values, null, 2))
          actions.setSubmitting(false)
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="two-thirds">
              <label htmlFor="productSearch">Add Product</label>
              <ProductSearch
                onClick={onProductSearchClick}
                onSearch={onProductSearch}
                products={products}
                events={events}
              />
              <label htmlFor="status">Status</label>
              <Field id="status" name="status" placeholder="" />
              {touched.status && errors.status && <div>{errors.status}</div>}
              <button type="submit">Submit</button>
            </div>
            <div className="one-third">
              <label htmlFor="dealerSearch">Customer</label>
              <Field
                id="dealerSearch"
                name="dealerSearch"
                placeholder="Search Dealer Directory"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EditOrders
