import * as S from './styles'

export interface ManageCardProps {
  cardColor?: string
  title?: string
}

const ManageCard: React.FC<ManageCardProps> = ({
  children,
  title,
  ...props
}) => (
  <S.CardContainer {...props}>
    {title && <S.CardTitle>{title}</S.CardTitle>}
    {children}
  </S.CardContainer>
)

export default ManageCard
