import ManageTemplate from '../../Manage'
import * as S from './styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { colors } from '@monorepo/theme'
import { ISelectItem, Resource } from '@monorepo/interfaces'
import { useCallback, useState, useEffect } from 'react'
import { Prompt, useHistory, useParams } from 'react-router-dom'
import { ManageCard } from '../../../organisms'
import { Autocomplete, TextField } from '@mui/material'
import { DropzoneArea } from 'material-ui-dropzone'
import axios from 'axios'
import { useKeycloak } from '@react-keycloak/web'
import { http, ChannelHelper, KeycloakHelper } from '@monorepo/infra'
import CreatableSelect from 'react-select/creatable'

export enum ManageResourcesEditingMode {
  CREATING = 0,
  EDITING,
}

const channels: ISelectItem[] = ChannelHelper.getChannelSelectItems()

const LoadingStatus = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHING_SUCCESS: 'FETCHING_SUCCESS',
  FETCHING_ERROR: 'FETCHING_ERROR',
  SAVING: 'SAVING',
  SAVING_SUCCESS: 'SAVING_SUCCESS',
  SAVING_ERROR: 'SAVING_ERROR',
  UPLOADING: 'UPLOADING',
  UPLOADING_SUCCESS: 'UPLOADING_SUCCESS',
  UPLOADING_ERROR: 'UPLOADING_ERROR',
}

export interface ManageResourcesProps {
  editingMode: ManageResourcesEditingMode
}

type ResourceType = Omit<
  Resource,
  'createdBy' | 'createdOn' | 'updatedBy' | 'updatedOn'
>

const initialValues = {
  title: '',
  description: '',
  document: '',
  status: 1,
  channels: [],
  categories: [],
}

const ManageResources: React.FC<ManageResourcesProps> = ({ editingMode }) => {
  const [loadingStatus, setLoadingStatus] = useState<string>(
    LoadingStatus.INITIAL
  )
  const [resource, setResource] = useState<ResourceType>(initialValues)
  const [categoriesOptions, setCategoriesOptions] = useState<
    { value: string; label: string }[]
  >([])
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)
  const ResourceSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().optional(),
    document: Yup.string().required(),
    status: Yup.number().required(),
    channels: Yup.array().of(Yup.string()).optional(),
    categories: Yup.array().of(Yup.string()).optional(),
  })
  const history = useHistory()
  const { id: resourceId } = useParams<{ id: string }>()

  const { errors, touched, handleBlur, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        handleSubmit(values)
      })
    },
    validationSchema: ResourceSchema,
    initialValues: resource,
  })

  const handleGoBack = useCallback(() => {
    history.push('/admin/resources')
  }, [history])

  const handleCreateResource = useCallback(
    (values: ResourceType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .post({ url: `/resource`, data: values })
        .then((response) => {
          const product = response?.data as ResourceType
          history.push(`/admin/resources/edit/${product?._id}`)
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
          throw error
        })
    },
    [history]
  )

  const handleEditResource = useCallback(
    (values: ResourceType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .patch({ url: `/resource/${resourceId}`, data: values })
        .then(() => {
          setLoadingStatus(LoadingStatus.SAVING_SUCCESS)
          formik.resetForm({ values })
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
        })
    },
    [resourceId, formik]
  )

  const handleSubmit = useCallback(
    (values: ResourceType) => {
      if (editingMode === ManageResourcesEditingMode.CREATING) {
        handleCreateResource(values)
        return
      }

      if (editingMode === ManageResourcesEditingMode.EDITING) {
        handleEditResource(values)
        return
      }
    },
    [editingMode, handleCreateResource, handleEditResource]
  )

  const handleUploadFiles = (file: File, fileType?: string) => {
    if (file) {
      setLoadingStatus(LoadingStatus.UPLOADING)
      axios.create({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
      })
      const url = `${process.env.REACT_APP_MEDIA_URL}`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('isInDesign', 'false')
      formData.append('tags', '')
      formData.append('email', user?.email)
      formData.append('mediaType', fileType || '')
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      axios
        .post(url, formData, config)
        .then((success) => {
          formik.setFieldValue('document', success?.data.path)
          setLoadingStatus(LoadingStatus.UPLOADING_SUCCESS)
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.UPLOADING_ERROR)
        })
    }
  }

  useEffect(() => {
    const isEditing =
      resourceId && editingMode === ManageResourcesEditingMode.EDITING
    const isInitializing = loadingStatus === LoadingStatus.INITIAL
    const shouldSearchResource = isEditing && isInitializing
    if (!shouldSearchResource) return

    setLoadingStatus(LoadingStatus.FETCHING)

    http
      .get({ url: `/resource/${resourceId}` })
      .then((response) => {
        const resource = response?.data as ResourceType
        setResource(resource)
        setLoadingStatus(LoadingStatus.FETCHING_SUCCESS)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.FETCHING_ERROR)
      })
  }, [editingMode, resourceId, formik, loadingStatus])

  const handleDiscardChanges = useCallback(() => {
    formik.setValues(resource)
  }, [formik, resource])

  useEffect(() => {
    http
      .get({ url: '/resource/category' })
      .then((response) => {
        if (Array.isArray(response?.data)) {
          setCategoriesOptions(
            response?.data.map((item) => ({ value: item, label: item }))
          )
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <ManageTemplate
      backText="Back to Resources"
      titleText={`${
        editingMode === ManageResourcesEditingMode.CREATING
          ? 'Create'
          : 'Manage'
      } Resource`}
      // onGoBack={handleGoBack}
      onGoBack={handleGoBack}
      headerRightSideChildren={
        <S.ButtonsContainer>
          {formik.dirty && (
            <>
              <S.FormButton onClick={handleDiscardChanges}>
                Discard
              </S.FormButton>
              {formik.isValid && (
                <>
                  <S.FormButton
                    backgroundColor={colors.blue}
                    onClick={() => handleSubmit(formik.values)}
                  >
                    Save
                  </S.FormButton>
                </>
              )}
            </>
          )}
        </S.ButtonsContainer>
      }
    >
      <S.Wrapper>
        <S.ColumnsContainer>
          <Prompt
            when={!!formik.dirty}
            message={
              'You have made changes to this resource. If you leave, any unsaved changes will be lost. Are you sure?'
            }
          />
          <S.LeftColumn>
            <div className="two-thirds">
              <ManageCard title="Title">
                <S.TextInput
                  id="title"
                  name="title"
                  label="Title"
                  value={formik.values.title}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('title', value)
                  }
                  onBlur={handleBlur}
                  invalid={!!errors.title && touched.title}
                  invalidMessage={errors.title}
                />
              </ManageCard>
              <ManageCard title="Description">
                <S.TextInput
                  id="description"
                  name="description"
                  label="Description"
                  value={formik.values.description}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('description', value)
                  }
                  onBlur={handleBlur}
                  invalid={!!errors.description && touched.description}
                  invalidMessage={errors.description}
                />
              </ManageCard>
              <ManageCard title="Document">
                <DropzoneArea
                  // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                  maxFileSize={100000000}
                  filesLimit={1}
                  onChange={(files) => {
                    handleUploadFiles(files[0], 'thumb')
                  }}
                ></DropzoneArea>
              </ManageCard>
            </div>
          </S.LeftColumn>
          <S.RightColumn>
            <div className="one-third">
              <ManageCard title="Status">
                <S.TextInput
                  as="select"
                  name="status"
                  value={formik.values.status}
                  onChange={(e: { target: { value: string } }) => {
                    formik.setFieldValue('status', parseInt(e.target.value))
                  }}
                >
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                  <option value="3">Archived</option>
                </S.TextInput>
                {touched.status && errors.status && <div>{errors.status}</div>}
              </ManageCard>
              <ManageCard title="Channels">
                <Autocomplete
                  multiple
                  id="channels-outlined"
                  options={channels}
                  value={channels.filter((channel) =>
                    formik.values.channels?.includes(channel.id)
                  )}
                  getOptionLabel={(option: ISelectItem) => option.value}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="channels"
                      name="Channels"
                      label="Channels"
                    />
                  )}
                  onBlur={handleBlur}
                  onChange={(event, values) => {
                    formik.setFieldValue(
                      'channels',
                      values.map((value) => {
                        return value.id
                      })
                    )
                  }}
                />
              </ManageCard>
              <ManageCard title="Categories">
                <CreatableSelect
                  name="categories"
                  placeholder="DIRECTV, AT&T"
                  isMulti
                  options={categoriesOptions}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  value={
                    Array.isArray(formik.values.categories)
                      ? formik.values.categories.map((category) => ({
                          value: category,
                          label: category,
                        }))
                      : []
                  }
                  onChange={(newValue) =>
                    formik.setFieldValue(
                      'categories',
                      newValue.map(({ value }) => value)
                    )
                  }
                  styles={S.MultiSelectStyle}
                />
              </ManageCard>
            </div>
          </S.RightColumn>
        </S.ColumnsContainer>
      </S.Wrapper>
    </ManageTemplate>
  )
}

export default ManageResources
