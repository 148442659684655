import { Icon } from '@monorepo/components'
import { colors } from '@monorepo/theme'

import * as S from './styles'

interface DashboardBackButtonProps {
  redirectURL: string
  text: string
}

const DashboardBackButton: React.FC<DashboardBackButtonProps> = ({
  text,
  redirectURL,
  ...props
}) => (
  <S.BackLink {...props} to={redirectURL}>
    <Icon icon="ChevronLeft" size={20} color={colors.black} hasFill={false} />{' '}
    {text ?? ''}
  </S.BackLink>
)

export default DashboardBackButton
