import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { useDebounce } from '@monorepo/infra'
import { Dealer } from '@monorepo/interfaces'

interface DealerSearchProps {
  onClick: (item: Dealer, type?: string) => void
  onSearch: (search: Record<string, unknown>) => void
  dealers: Dealer | undefined
}

const DealerSearch: React.FC<DealerSearchProps> = ({
  onClick,
  onSearch,
  dealers,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const handleClick = (item: Dealer, type?: string) => {
    setSearchTerm('')
    onClick(item, type)
  }

  useEffect(() => {
    onSearch({ search: debouncedSearchTerm })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])
  return (
    <S.Container>
      <S.InputBase
        placeholder="Search Dealer Directory"
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        endAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      {debouncedSearchTerm ? (
        <div>
          <article key="products">
            {dealers && (
              <S.Item>
                <section onClick={() => handleClick(dealers, 'dealer')}>
                  <S.ItemTitle>{`${dealers.firstName} ${dealers.lastName}`}</S.ItemTitle>
                  <S.ItemTitle>{`${dealers.email}`}</S.ItemTitle>
                </section>
              </S.Item>
            )}
          </article>
        </div>
      ) : null}
    </S.Container>
  )
}

export default DealerSearch
