import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { Button as BaseButton } from '@monorepo/components'
import { StylesConfig } from 'react-select'
import { Form } from 'formik'

export const DialogForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  font-family: PFDINText;
  margin-bottom: 20px;
`

export const Title = styled.div`
  position: relative;
  font-weight: bold;
  font-size: x-large;
`

export const TitleText = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  justify-self: end;
`

export const DropdownButton = styled.div`
  border-radius: 20px;
  height: 40px;
  width: 120px;
  text-transform: uppercase;
  color: ${colors.white};
  position: relative;
  background-color: green;

  &:hover {
    cursor: pointer;
  }
`

export const DropdownButtonText = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;

  &:after {
    padding-left: 10px;
    font-weight: 900;
  }

  &[data-open='false']:after {
    content: '▼';
  }

  &[data-open='true']:after {
    content: '▲';
  }
`

export const DropdownContent = styled.div`
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px ${colors.highlightGrey} solid;
  margin-top: 10px;
  padding: 10px;

  &[data-open='false'] {
    display: none;
  }

  &[data-open='true'] {
    display: block;
  }
`

type OptionType = {
  label: string
  value: string
}

type IsMulti = true

export const SelectStyle: StylesConfig<OptionType> = {
  container: (base) => ({
    ...base,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
    minHeight: 75,
    paddingLeft: 20,
    paddingRight: 20,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: '#21528C',
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'PFDINText',
    fontSize: 16,
    letterSpacing: -0.2,
    fontWeight: 'normal',
    color: colors.mediumGrey,
  }),
}

export const MultiSelectStyle: StylesConfig<OptionType, IsMulti> = {
  container: SelectStyle.container,
  control: SelectStyle.control,
  placeholder: SelectStyle.placeholder,
  multiValueLabel: (base) => ({
    ...base,
    color: colors.white,
    padding: 0,
    marginRight: 10,
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    padding: 0,
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.2s ease-in-out background-color',
    backgroundColor: state.isFocused ? 'black' : 'transparent',
    '&:hover': {
      backgroundColor: 'black',
      color: colors.white,
      cursor: 'pointer',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#21528C',
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 5,
    borderRadius: 18,
    color: colors.white,
    padding: '0 10px',
  }),
  menu: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  menuList: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    display: 'none',
  }),
}

export const DialogActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const ModalButton = styled(BaseButton)`
  width: 100px;
  justify-content: center;
`
