import { colors } from '@monorepo/theme'
import { Form as FormFormik } from 'formik'
import { Button as MuiButton } from '@material-ui/core'
import styled from 'styled-components'
import { StylesConfig } from 'react-select'
import { Input } from '@monorepo/components'

const Form = styled(FormFormik)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    margin: 0 auto;
    max-width: 1440px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    margin: 0 auto;
    max-width: 1440px;
  }
`

const Principal = styled.section`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 969px) {
    width: 70%;
  }
`

export const ColumnsContainer = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  margin-bottom: 80px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const LeftColumn = styled(Column)`
  margin: 0 12px 0 auto;
  width: 767px;
`

const RightColumn = styled(Column)`
  margin: 0 auto 0 12px;
  width: 379px;
`

const TextInput = styled(Input)`
  && {
    height: 47px;
    padding: 0 16px;
    font-family: PFDINText;
    letter-spacing: -0.2px;
    font-size: 16px;
    border-radius: 6px;
    ${({ invalid }) => !invalid && `border: 0.3px solid ${colors.grey};`}

    ${({ textarea }) =>
      textarea &&
      `
        padding-top: 12px;
        height: 155px;
        line-height: 22px;
        resize: none;
      `}

    & + label,
    & + label:focus {
      left: 16px;
      font-family: PFDINText;
      font-size: 16px;
      letter-spacing: -0.2px;
      top: ${({ textarea }) => (textarea ? '12px' : '50%')};
      transform: ${({ textarea }) => (textarea ? 'unset' : 'translateY(-50%)')};
      font-weight: normal;
    }

    &:not(:placeholder-shown) + label {
      display: none;
    }
  }
`

const Options = styled.section`
  width: 100%;

  @media screen and (min-width: 969px) {
    width: 30%;
  }
`

const SubTitle = styled.h2`
  font-family: 'PFDINText';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.2px;
  margin-bottom: 20px;

  color: #000000;
`

const ItemContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 30px;
  background-color: ${colors.white};
`
const ItemList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ItemListItem = styled.li`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

interface ItemRowProps {
  justifyContent?: string
  gap?: string
}

const ItemRow = styled.div<ItemRowProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
  gap: ${(props) => props.gap ?? 'auto'};
  width: 100%;
  line-height: 24px;
  font-size: 18px;
`

const ItemImage = styled.img`
  max-width: 130px;
  max-height: 130px;
  flex: 1;
`

const ItemDetail = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  flex: 2;
`

const ItemTitle = styled.h4`
  font-family: PFDINText;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
`

const ItemText = styled.p`
  font-family: PFDINText;
  font-size: 18px;
  font-weight: 400;
`

const ItemPrice = styled.div`
  display: flex;
  gap: 16px;
`

const ItemPriceField = styled.div`
  display: flex;
  flex-flow: column;

  & > input {
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204);
    height: 40px;
    margin: 0;
    padding: 6px;
    min-width: 0;
  }
`

const AddressField = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 20px;

  & > label {
    margin-bottom: 6px;
  }

  & > input {
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204);
    height: 40px;
    margin: 0;
    padding: 6px;
    min-width: 0;
  }
`

const Line = styled.hr`
  margin: 30px 0 25px 0;
  border: 0;
  border-top: 0.5px solid ${colors.black};
`

const ItemActionButton = styled(MuiButton)`
  &.MuiButton-root {
    border-radius: 0;
    border: 2px solid ${colors.black};
    background-color: ${colors.white};
    align-self: flex-end;
  }

  .MuiButton-label {
    color: ${colors.black};
    font-size: 14px;
    font-weight: 400;
    font-family: PFDINText;
    text-transform: uppercase;
    padding: 8px 16px;
  }
`

const PriceRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  line-height: 24px;
`

const PriceDescription = styled.p`
  flex: 6;
  font-family: PFDINText;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  justify-self: flex-start;
  line-height: 30px;
`

interface PriceAmountProps {
  fontWeight?: string
}

const PriceAmount = styled.p<PriceAmountProps>`
  flex: 1;
  font-family: PFDINText;
  font-size: 18px;
  font-weight: ${(props) => props.fontWeight ?? '400'};
  text-transform: none;
  justify-self: flex-end;
  text-align: right;
  line-height: 30px;
`

export type OptionType = {
  label: string
  value: string
}

export const SelectStyle: StylesConfig<OptionType> = {
  container: (base) => ({
    ...base,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 3,
    minHeight: 40,
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'PFDINText',
    fontSize: 16,
    letterSpacing: -0.2,
    fontWeight: 'normal',
    color: '#333',
  }),
}

type IsMulti = true

const MultiSelectStyle: StylesConfig<OptionType, IsMulti> = {
  container: SelectStyle.container,
  control: SelectStyle.control,
  placeholder: SelectStyle.placeholder,
  multiValueLabel: (base) => ({
    ...base,
    color: colors.white,
    padding: 0,
    marginRight: 10,
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    padding: 0,
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.2s ease-in-out background-color',
    backgroundColor: state.isFocused ? 'black' : 'transparent',
    '&:hover': {
      backgroundColor: 'black',
      color: colors.white,
      cursor: 'pointer',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: colors.blue,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 5,
    borderRadius: 18,
    color: colors.white,
    padding: '0 10px',
  }),
  menu: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  menuList: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  noOptionsMessage: (base, state) => ({
    ...base,
    display: 'none',
  }),
}

export const ButtonsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

type ButtonProps = { backgroundColor?: string; color?: string }

export const FormButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  text-transform: uppercase;
  margin: 0 12px;
  font-family: PFDINText;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color ?? colors.white};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
  border: ${({ backgroundColor }) =>
    backgroundColor ? 'unset' : `1px solid ${colors.white}`};
  cursor: pointer;
`

const RemoveButton = styled.small`
  color: red;
`

export {
  Form,
  Principal,
  Options,
  PriceAmount,
  PriceDescription,
  PriceRow,
  ItemActionButton,
  Line,
  ItemPrice,
  ItemText,
  ItemTitle,
  ItemDetail,
  ItemImage,
  ItemRow,
  ItemListItem,
  ItemList,
  ItemContainer,
  Wrapper,
  SubTitle,
  ItemPriceField,
  MultiSelectStyle,
  AddressField,
  RemoveButton,
  TextInput,
  LeftColumn,
  RightColumn,
}
