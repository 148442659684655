import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Spinner } from '@monorepo/components'
import { TableBody } from '@material-ui/core'
import { PopUp, PopUpStatus } from '@monorepo/interfaces'
import { PopUpHelper, isExpired, ChannelHelper, except } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from './styles'

export interface SectionPopUpsListProps {
  popUps: PopUp[]
  loading: boolean
}

const SectionPopUpsList: React.FC<SectionPopUpsListProps> = ({
  popUps,
  loading = true,
}) => {
  const history = useHistory()
  const [list, setList] = useState<PopUp[] | null | undefined>()

  useEffect(() => {
    if (loading) {
      return
    }

    setList([
      ...(Array.isArray(popUps) ? popUps : []),
      ...getPopUpsFakeChannels(
        except(
          ChannelHelper.getChannelOptions().map((item) => item.value),
          getPopUpsChannels(popUps)
        )
      ),
    ])
  }, [popUps, loading])

  const getPopUpsChannels = (popUps?: PopUp[]): string[] => {
    return Array.isArray(popUps)
      ? Array.from(new Set(popUps.map((item: PopUp) => item.channel)))
      : []
  }

  const getPopUpsFakeChannels = (channels?: string[]): PopUp[] => {
    return Array.isArray(channels)
      ? channels.map(
          (item) =>
            ({ _id: `${item}-fake`, channel: item.toUpperCase() } as PopUp)
        )
      : []
  }

  const handleEditResource = (id: string) => {
    if (isFake(id)) {
      return
    }

    history.push(`/admin/pop-ups/edit/${id}`)
  }

  const isFake = (id?: string): boolean => {
    return !!id && /fake/i.test(id)
  }

  return (
    <S.Container>
      <S.TableContainer>
        <S.Table aria-label="Pop-Ups Table">
          <S.TableHead>
            <S.TableRow>
              <S.TableHeadCell>Channels</S.TableHeadCell>
              <S.TableHeadCell>Headline</S.TableHeadCell>
              <S.TableHeadCell>Activation date</S.TableHeadCell>
              <S.TableHeadCell>Expiration date</S.TableHeadCell>
              <S.TableHeadCell>Status</S.TableHeadCell>
            </S.TableRow>
          </S.TableHead>
          <TableBody>
            {loading ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : (
              list?.map((popUp: PopUp, index: number) => (
                <S.TbodyRow
                  key={index}
                  onClick={() => handleEditResource(popUp._id as string)}
                >
                  <S.TBodyCell scope="row">
                    {popUp.channel.toUpperCase()}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">{popUp.headline}</S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {!isFake(popUp._id)
                      ? popUp.activationDate
                        ? format(new Date(popUp.activationDate), 'MM/dd/yyyy')
                        : 'N/D'
                      : undefined}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {!isFake(popUp._id)
                      ? popUp.expirationDate
                        ? format(new Date(popUp.expirationDate), 'MM/dd/yyyy')
                        : 'N/D'
                      : undefined}
                  </S.TBodyCell>
                  <S.TBodyCell>
                    {!isFake(popUp._id) ? (
                      <S.TagsContainer>
                        <S.StatusTag
                          data-status={
                            popUp.status === PopUpStatus.ACTIVE &&
                            isExpired(popUp.expirationDate)
                              ? undefined
                              : popUp.status
                          }
                        >
                          {popUp.status === PopUpStatus.ACTIVE &&
                          isExpired(popUp.expirationDate)
                            ? 'Expired'
                            : PopUpHelper.getPopUpStatusOptionDescription(
                                popUp.status as number
                              )}
                        </S.StatusTag>
                      </S.TagsContainer>
                    ) : undefined}
                  </S.TBodyCell>
                </S.TbodyRow>
              ))
            )}
          </TableBody>
        </S.Table>
      </S.TableContainer>
    </S.Container>
  )
}

export default SectionPopUpsList
