import React, { useEffect, useState } from 'react'
import { SpendByMediaReport } from '@monorepo/interfaces'
import { http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import { ResponsiveBar } from '@nivo/bar'

interface SpendByMediaProps {
  channel: string
  startDate: Date
  endDate: Date
}

const SpendByMedia: React.FC<SpendByMediaProps> = ({
  channel,
  startDate,
  endDate,
}) => {
  const [report, setReport] = useState<SpendByMediaReport | null>(null)
  useEffect(() => {
    http
      .get<SpendByMediaReport>({
        url: `/reports/spendByMedia?channel=${channel}&dateFrom=${format(
          startDate,
          'yyyy-MM-dd'
        )}&dateTo=${format(endDate, 'yyyy-MM-dd')}`,
      })
      .then(({ data }) => {
        setReport(data)
        console.log(JSON.stringify(data))
      })
  }, [channel, endDate, startDate])
  if (!report || report === null) {
    return <PageSpinner />
  }

  const colors = [
    '#7a267b',
    '#337ace',
    '#21528C',
    '#1ebcf8',
    '#e88534',
    '#fdd746',
  ]
  const totalProducts = report.products.spend + report.products.coop
  const totalEvents = report.events.spend + report.events.coop
  const totalMedia = report.media.spend + report.media.coop
  const totalMicrosites = report.microsites.spend + report.microsites.coop
  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  return (
    <S.Container>
      <S.ReportTitle>Executive Summary</S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>{channel}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Date</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(new Date(), 'MM/dd/yyyy')}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.BarChartList>
        <S.BarChartListItem>
          <S.BarChartTitle>Total Ad Material Spend</S.BarChartTitle>
          <S.BarChartSubtitle>
            {moneyFormatter.format(totalProducts)}
          </S.BarChartSubtitle>
          <ResponsiveBar
            margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
            data={[
              {
                id: 'Spend',
                label: moneyFormatter.format(report.products.spend),
                value: Number(report.products.spend.toFixed(0)),
              },
              {
                id: 'Coop',
                label: moneyFormatter.format(report.products.coop),
                value: Number(report.products.coop.toFixed(0)),
              },
            ]}
            colors={[colors[0], colors[1]]}
            colorBy="indexValue"
            // keys={['spend', 'coop']}
            axisBottom={{
              tickSize: 0,
            }}
            enableGridY={false}
            enableLabel={false}
          />
        </S.BarChartListItem>
        <S.BarChartListItem>
          <S.BarChartTitle>Total Media Spend</S.BarChartTitle>
          <S.BarChartSubtitle>
            {moneyFormatter.format(totalMedia)}
          </S.BarChartSubtitle>
          <ResponsiveBar
            margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
            data={[
              {
                id: 'Spend',
                label: moneyFormatter.format(report.media.spend),
                value: Number(report.media.spend.toFixed(0)),
              },
              {
                id: 'Coop',
                label: moneyFormatter.format(report.media.coop),
                value: Number(report.media.coop.toFixed(0)),
              },
            ]}
            colors={[colors[2], colors[3]]}
            colorBy="indexValue"
            // keys={['spend', 'coop']}
            axisBottom={{
              tickSize: 0,
            }}
            enableGridY={false}
            enableLabel={false}
          />
        </S.BarChartListItem>
        <S.BarChartListItem>
          <S.BarChartTitle>Total Microsite Spend</S.BarChartTitle>
          <S.BarChartSubtitle>
            {moneyFormatter.format(totalMicrosites)}
          </S.BarChartSubtitle>
          <ResponsiveBar
            margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
            data={[
              {
                id: 'Spend',
                label: moneyFormatter.format(report.microsites.spend),
                value: Number(report.microsites.spend.toFixed(0)),
              },
              {
                id: 'Coop',
                label: moneyFormatter.format(report.microsites.coop),
                value: Number(report.microsites.coop.toFixed(0)),
              },
            ]}
            colors={[colors[4], colors[5]]}
            colorBy="indexValue"
            // keys={['spend', 'coop']}
            axisBottom={{
              tickSize: 0,
            }}
            enableGridY={false}
            enableLabel={false}
          />
        </S.BarChartListItem>
        <S.BarChartListItem>
          <S.BarChartTitle>Total Event Spend</S.BarChartTitle>
          <S.BarChartSubtitle>
            {moneyFormatter.format(totalEvents)}
          </S.BarChartSubtitle>
          <ResponsiveBar
            margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
            data={[
              {
                id: 'Spend',
                label: moneyFormatter.format(report.events.spend),
                value: Number(report.events.spend.toFixed(0)),
              },
              {
                id: 'Coop',
                label: moneyFormatter.format(report.events.coop),
                value: Number(report.events.coop.toFixed(0)),
              },
            ]}
            colors={[colors[6], colors[0]]}
            colorBy="indexValue"
            // keys={['spend', 'coop']}
            axisBottom={{
              tickSize: 0,
            }}
            enableGridY={false}
            enableLabel={false}
          />
        </S.BarChartListItem>
      </S.BarChartList>
    </S.Container>
  )
}

export default SpendByMedia
