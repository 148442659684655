import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeItem from '@mui/lab/TreeItem'

export interface DirectoryTreeViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

const DirectoryTreeView: React.FC<DirectoryTreeViewProps> = ({
  data = undefined,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getStyledTreeItem(node: any) {
    return (
      <TreeItem
        key={node?.id}
        nodeId={node?.id}
        label={`${!!node?.name?.trim() ? node?.name?.trim() : ''}${
          !!node?.name?.trim() && !!node?.email?.trim() ? ' ' : ''
        }${!!node?.email?.trim() ? `(${node?.email?.trim()})` : ''}`}
      >
        {Array.isArray(node?.children)
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            node.children.map((item: any) => getStyledTreeItem(item))
          : null}
      </TreeItem>
    )
  }

  return (
    <TreeView
      aria-label="tree-dialog"
      defaultExpanded={[data?.id]}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 264, flexGrow: 1 }}
    >
      {getStyledTreeItem(data)}
    </TreeView>
  )
}

export default DirectoryTreeView
