import React, { useCallback, useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import { Button, Input } from '@monorepo/components'
import { KeycloakHelper, httpUserInfo } from '@monorepo/infra'
import { Field, Form, Formik, setNestedObjectValues } from 'formik'

import Arrow from '../../../assets/registration/arrow.svg'
import Image from '../../../assets/registration/left-side.png'

import * as S from './styles'

interface MasterDealer {
  _id: string
  channels: string[]
  billingAddresses?: {
    address: string
    city: string
    state: string
    zipCode: string
    default: boolean
  }
  shippingAddresses?: {
    address: string
    city: string
    state: string
    zipCode: string
    default: boolean
  }
  corpId: string
  masterDealerId: string
  contractId: string
  name: string
  __v: number
}

interface AssociatedMasterDealer {
  masterDealerId: string
  masterDealer: MasterDealer[]
}

interface Dealer {
  _id: string
  associatedMasterDealers: AssociatedMasterDealer[]
  userId: string[]
  __v: number
}

interface RegistrationUserResponse {
  _id: string
  keycloakUserId: string
  groups: string[]
  phoneNumber?: string
  email: string
  lastName: string
  firstName: string
  __v: number
  dealer: Dealer
}

type RegistrationUserPayload = {
  _id: string
  userId: string
  firstNAme: string
  lastName: string
  password: string
  email: string
}

interface ValidateEmailForm {
  email: string
}

interface RegistrationUserForm {
  lastName: string
  password: string
  firstName: string
}

const ValidateEmailSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your e-mail'),
})

const RegistrationUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name.'),
  lastName: Yup.string().required('Please enter your last name.'),
  password: Yup.string()
    .required('Please enter your password.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
})

const REGISTRATION_STEPS = {
  EMAIL: 'EMAIL',
  REGISTER: 'REGISTER',
}

const Registration: React.FC = () => {
  const [registrationStep, setRegistrationStep] = useState(
    REGISTRATION_STEPS.EMAIL
  )
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [registrationErrorMessage, setRegistrationErrorMessage] = useState('')

  const [registrationUserPayload, setRegistrationUserPayload] =
    useState<Partial<RegistrationUserPayload> | null>(null)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { initialized, keycloak } = useKeycloak()
  const history = useHistory()

  const user = KeycloakHelper.getTokenParsed(keycloak)

  const getUserInfoByEmail = useCallback((formValues: ValidateEmailForm) => {
    const { email } = formValues

    if (!email) return

    setIsSubmitting(true)

    httpUserInfo
      .get({ url: `/user/validate/${email}` })
      .then((response) => {
        if (response.status !== 200) {
          setEmailErrorMessage('Error fetching information for this e-mail.')
          return
        }

        const registrationUser = response.data as RegistrationUserResponse

        const { _id, email, dealer } = registrationUser

        setRegistrationUserPayload({
          _id,
          email,
          userId: dealer?.userId?.[0],
        })
        setRegistrationStep(REGISTRATION_STEPS.REGISTER)
      })
      .catch(() => {
        setEmailErrorMessage('Error fetching information for this e-mail.')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }, [])

  const registerUser = useCallback(
    (formValues: RegistrationUserForm) => {
      const { password, firstName, lastName } = formValues

      const registrationPayload = {
        ...registrationUserPayload,
        password,
        firstName,
        lastName,
      } as RegistrationUserPayload

      setIsSubmitting(true)

      httpUserInfo
        .post({ url: `/user/registration`, data: registrationPayload })
        .then((response) => {
          history.push('/')
        })
        .catch(() => {
          setRegistrationErrorMessage(
            'An error has occurred in your registration, please contact support for more information.'
          )
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    },
    [history, registrationUserPayload]
  )

  const handleSubmit = useCallback(
    (values: ValidateEmailForm | RegistrationUserForm) => {
      if (registrationStep === REGISTRATION_STEPS.EMAIL) {
        getUserInfoByEmail(values as ValidateEmailForm)
        return
      }

      registerUser(values as RegistrationUserForm)
    },
    [getUserInfoByEmail, registerUser, registrationStep]
  )

  useEffect(() => {
    if (initialized && user) {
      history.push('/')
    }
  }, [history, initialized, user])

  if (!initialized) return null

  return (
    <S.Wrapper>
      <S.LeftSide background={Image}>
        <S.Arrow src={Arrow}></S.Arrow>
      </S.LeftSide>
      <S.RightSide>
        <S.TextContainer>
          <S.Title>Welcome to the Marketing ZONE!</S.Title>
          <S.Subtitle>
            With your business in mind, we’ve improved functionality,
            streamlined checkout and taken the guesswork out of growing your
            business.
            <br />
            <br />
            Complete your registration now to get in the ZONE!
          </S.Subtitle>
        </S.TextContainer>
        {!registrationErrorMessage ? (
          <S.Card>
            <Formik
              initialValues={
                registrationStep === REGISTRATION_STEPS.EMAIL
                  ? { email: '' }
                  : {
                      lastName: '',
                      password: '',

                      firstName: '',
                    }
              }
              validationSchema={
                registrationStep === REGISTRATION_STEPS.EMAIL
                  ? ValidateEmailSchema
                  : RegistrationUserSchema
              }
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                handleSubmit(values)
                actions.setSubmitting(false)
              }}
            >
              {({
                errors,
                touched,
                values,
                submitForm,
                setFieldValue,
                setTouched,
                setFieldTouched,
                validateForm,
              }) => (
                <Form>
                  <S.FormTitle>
                    {registrationStep === REGISTRATION_STEPS.EMAIL
                      ? 'Enter your e-mail'
                      : 'Complete your registration'}
                  </S.FormTitle>
                  <S.Wrap>
                    {registrationStep === REGISTRATION_STEPS.EMAIL ? (
                      <S.Column>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          component={Input}
                          invalid={
                            emailErrorMessage || (touched.email && errors.email)
                          }
                          invalidMessage={emailErrorMessage || errors.email}
                          label="E-mail"
                          onBlur={() => setFieldTouched('email')}
                          onChange={(option: { target: { value: string } }) => {
                            setFieldValue('email', option.target.value)
                          }}
                        />
                      </S.Column>
                    ) : (
                      <S.Column>
                        <S.Row>
                          <Field
                            id="firstName"
                            name="firstName"
                            component={Input}
                            invalid={touched.firstName && errors.firstName}
                            invalidMessage={errors.firstName}
                            label="First Name"
                            onBlur={() => setFieldTouched('firstName')}
                            onChange={(option: {
                              target: { value: string }
                            }) => {
                              setFieldValue('firstName', option.target.value)
                            }}
                          />

                          <Field
                            id="lastName"
                            name="lastName"
                            component={Input}
                            invalid={touched.lastName && errors.lastName}
                            invalidMessage={errors.lastName}
                            label="Last Name"
                            onBlur={() => {
                              setFieldTouched('lastName')
                            }}
                            onChange={(option: {
                              target: { value: string }
                            }) => {
                              setFieldValue('lastName', option.target.value)
                            }}
                          />
                        </S.Row>

                        <S.Column>
                          <Field
                            id="password"
                            name="password"
                            type="password"
                            component={Input}
                            invalid={touched.password && errors.password}
                            invalidMessage={errors.password}
                            label="Create Password"
                            onBlur={() => {
                              setFieldTouched('password')
                            }}
                            onChange={(option: {
                              target: { value: string }
                            }) => {
                              setFieldValue('password', option.target.value)
                            }}
                          />
                        </S.Column>
                      </S.Column>
                    )}
                  </S.Wrap>
                  <Button
                    colorOption="black"
                    label={
                      registrationStep === REGISTRATION_STEPS.EMAIL
                        ? 'VERIFY YOUR E-MAIL'
                        : 'COMPLETE REGISTRATION'
                    }
                    onClick={() => {
                      validateForm().then((errors) => {
                        setTouched(setNestedObjectValues(errors, true))
                      })

                      submitForm()
                    }}
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          </S.Card>
        ) : (
          <S.TextContainer>
            <S.RegistrationError>
              {registrationErrorMessage}
            </S.RegistrationError>
          </S.TextContainer>
        )}
        <S.Footer>
          <img src="/images/logo-dark.svg" alt="logo" />
        </S.Footer>
      </S.RightSide>
    </S.Wrapper>
  )
}

export default Registration
