import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { Input } from '@monorepo/components'
import { StylesConfig } from 'react-select'

type ButtonProps = {
  backgroundColor?: string
  color?: string
}

type OptionType = {
  label: string
  value: string
}

export const ButtonsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const FormButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  text-transform: uppercase;
  margin: 0 12px;
  font-family: PFDINText;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color ?? colors.white};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
  border: ${({ backgroundColor }) =>
    backgroundColor ? 'unset' : `1px solid ${colors.white}`};
  cursor: pointer;
`

export const ColumnsContainer = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  margin-bottom: 80px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const LeftColumn = styled(Column)`
  margin: 0 12px 0 auto;
  width: 767px;
`

export const RightColumn = styled(Column)`
  margin: 0 auto 0 12px;
  width: 379px;
`

export const TextInput = styled(Input)`
  && {
    height: 47px;
    padding: 0 16px;
    font-family: PFDINText;
    letter-spacing: -0.2px;
    font-size: 16px;
    border-radius: 6px;
    ${({ invalid }) => !invalid && `border: 0.3px solid ${colors.grey};`}

    ${({ textarea }) =>
      textarea &&
      `
        padding-top: 12px;
        height: 155px;
        line-height: 22px;
        resize: none;
      `}

    & + label,
    & + label:focus {
      left: 16px;
      font-family: PFDINText;
      font-size: 16px;
      letter-spacing: -0.2px;
      top: ${({ textarea }) => (textarea ? '12px' : '50%')};
      transform: ${({ textarea }) => (textarea ? 'unset' : 'translateY(-50%)')};
      font-weight: normal;
    }

    &:not(:placeholder-shown) + label {
      display: none;
    }
  }
`

export const MessagesList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #b7bec6;
    top: 0;
    bottom: 0;
    left: 0;
  }
`

export const MessagesListItem = styled.li`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 0 0 10px 21px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Message = styled.div`
  flex: 6;
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
`

export const MessageCreated = styled.span`
  font-family: PFDINText;
  font-size: 14px;
  font-weight: 400;
  color: #707d8d;
`

export const MessageMessage = styled.p`
  font-family: PFDINText;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  line-height: 30px;
`

export const MessagesContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  margin: 10px;
`

export const MessagesButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ButtonForm = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 206px;
  height: 50px;
  background: #337ace;
  order: 0;
  margin: 10px 0px;
  left: calc(50% - 166px / 2);
  top: calc(50% - 22px / 2);
  font-family: 'PFDINText';
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
`

export const PropertyLabel = styled.p`
  font-family: PFDINText;
  font-size: 15px;
  letter-spacing: -0.2px;
  font-weight: normal;
  margin-bottom: 10px;
`

export const SelectStyle: StylesConfig<OptionType> = {
  container: (base) => ({
    ...base,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 6,
    minHeight: 47,
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'PFDINText',
    fontSize: 16,
    letterSpacing: -0.2,
    fontWeight: 'normal',
    color: colors.mediumGrey,
  }),
}
