import React from 'react'
import * as S from './styles'
import { usePagination, DOTS } from '@monorepo/infra'
import { Icon } from '@monorepo/components'

interface PaginationProps {
  onPageChange: (page: number) => void
  totalCount: number
  page: number
  pageSize: number
  totalPages: number
  siblingCount?: number
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  page,
  pageSize,
  totalPages,
  siblingCount,
  onPageChange,
}) => {
  const paginationRange = usePagination({
    page,
    totalCount,
    totalPages,
    pageSize,
    siblingCount: siblingCount ?? 1,
  })
  if (totalPages === 0 || (page === 1 && totalPages === 1)) return null

  const onNext = () => {
    if (page === totalPages) return
    onPageChange(page + 1)
  }

  const onPrevious = () => {
    if (page === 1) return
    onPageChange(page - 1)
  }

  if (!totalPages) return null

  return (
    <S.Container>
      <S.PageList>
        <S.PageListItem onClick={onPrevious}>
          <Icon icon={'ArrowLeft'} size={12} color={'#337ACE'} />
        </S.PageListItem>
        {paginationRange?.map((pageNumber: number, index: number) => {
          if (pageNumber === DOTS) {
            return <S.PageListItem key={index}>&#8230;</S.PageListItem>
          }
          return (
            <S.PageListItem
              key={index}
              onClick={() => onPageChange(pageNumber)}
              className={pageNumber === page ? 'active' : ''}
            >
              {pageNumber}
            </S.PageListItem>
          )
        })}
        <S.PageListItem onClick={onNext}>
          <Icon icon={'ArrowRight'} size={12} color={'#337ACE'} />
        </S.PageListItem>
      </S.PageList>
    </S.Container>
  )
}

export default Pagination
