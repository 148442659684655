import {
    Button as MuiButton,
    Chip as MuiChip,
    Drawer as MuiDrawer,
  } from '@material-ui/core'
  import { Button, Input } from '@monorepo/components'
  import { colors } from '@monorepo/theme'
  import styled from 'styled-components'
  
  export const Main = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 80px 106px;
  `
  export const Container = styled.div`
    padding:0px 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    gap: 15px;
  `
  
  export const TwoThirds = styled.div`
    display: flex;
    flex: 2;
    margin: 0;
    padding: 0;
    flex-direction: column;
    gap: 15px;
  `
  
  export const OneThird = styled.div`
    display: flex;
    flex: 1;
    margin: 0;
    padding: 0;
    flex-direction: column;
    gap: 15px;
    justify-items: flex-start;
  `
  
  export const Row = styled.div`
    display: flex;
    flex: 3;
    margin: 0;
    padding: 0;
    flex-direction: row;
  `
  
  export const ItemContainer = styled.div`
    padding: 19px 15px 15px 30px;
    background-color: ${colors.white};
  `
  
  export const ItemList = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: column;
  `
  
  export const ItemListItem = styled.li`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px 0;
  `
  
  interface ItemRowProps {
    justifyContent?: string
    gap?: string
  }
  
  export const ItemRow = styled.div<ItemRowProps>`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
    gap: ${(props) => props.gap ?? 'auto'};
  `
  
  export const ItemImage = styled.img`
    max-width: 130px;
    max-height: 130px;
    flex: 1;
  `
  
  export const ItemDetail = styled.div`
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    flex: 2;
  `
  
  export const ItemTitle = styled.h4`
    font-family: PFDINText;
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
    text-transform: uppercase;
  `
  
  export const ItemText = styled.p`
    font-family: PFDINText;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
  `
  
  export const ItemPrice = styled.div`
    display: flex;
    flex: 1;
  `
  
  export const OrderTitle = styled.h2`
    font-family: PFDINText;
    font-size: 28px;
    font-weight: 400;
  `
  
  export const ItemStatus = styled.h3`
    font-family: PFDINText;
    font-size: 24px;
    font-weight: 400;
    flex: 1;
  `
  
  export const ChipContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-left: 10px;
  `
  
  interface ChipProps {
    bgColor?: string
    fgColor?: string
  }
  
  export const Chip = styled(MuiChip)<ChipProps>`
    height: 25px;
  
    &.MuiChip-root {
      height: 25px;
      background-color: ${(props) => props.bgColor ?? '#f5f5f5'};
    }
  
    .MuiChip-label {
      padding-left: 8px;
      color: ${(props) => props.fgColor ?? colors.black};
      min-width: 50px;
      text-align: center;
    }
  `
  
  export const ViewMessagesButton = styled(MuiButton)`
    padding: 10px;
  
    &.MuiButton-root {
      height: 36px;
      border-radius: 18px;
      border: 1px solid #337ace;
      background-color: #337ace;
      align-self: flex-end;
    }
  
    .MuiButton-label {
      color: ${colors.white};
      font-size: 14px;
      font-weight: 400;
      font-family: PFDINText;
      text-transform: none;
    }
  `
  
  export const MessagesDrawer = styled(MuiDrawer)``
  
  export const Line = styled.hr`
    margin: 30px 0 25px 0;
    border: 0;
    border-top: 0.5px solid ${colors.lightGrey};
  `
  
  export const ItemActionButton = styled(MuiButton)`
    &.MuiButton-root {
      border-radius: 0;
      border: 2px solid ${colors.black};
      background-color: ${colors.white};
      align-self: flex-end;
    }
  
    .MuiButton-label {
      color: ${colors.black};
      font-size: 14px;
      font-weight: 400;
      font-family: PFDINText;
      text-transform: uppercase;
      padding: 8px 16px;
    }
  `
  
  export const PriceRow = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    line-height: 24px;
  `
  
  export const PriceDescription = styled.p`
    flex: 6;
    font-family: PFDINText;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    justify-self: flex-start;
    line-height: 30px;
  `
  
  interface PriceAmountProps {
    fontWeight?: string
  }
  
  export const PriceAmount = styled.p<PriceAmountProps>`
    flex: 1;
    font-family: PFDINText;
    font-size: 18px;
    font-weight: ${(props) => props.fontWeight ?? '400'};
    text-transform: none;
    justify-self: flex-end;
    text-align: right;
    line-height: 30px;
  `
  
  export const CustomerContainer = styled(ItemContainer)``
  
  export const CustomerInfoContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
  `
  
  export const CustomerTitle = styled.h4`
    font-family: PFDINText;
    font-size: 18px;
    font-weight: 400;
  `
  
  export const CustomerAvatar = styled.span`
    height: 48px;
    width: 48px;
    border-radius: 50%;
    font-family: Jubilat;
    font-size: 22px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    background-color: #102641;
    color: ${colors.white};
    display: flex;
    justify-content: center;
  `
  
  export const CustomerText = styled.p`
    font-family: PFDINText;
    font-size: 15px;
    font-weight: 400;
  `
  
  export const CustomerDetailContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
  `
  
  export const CustomerAddressContainer = styled.div`
    margin-top: 15px;
  `

  export const ManageImage = styled.div``

  export const HomePageImage = styled.image``
  
  export const ImageName = styled.p``
  
  export const DeleteIcon = styled.svg``
  
  export const CustomerName = styled.p`
    font-family: PFDINText;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
  `
  
  export const CustomerEmail = styled.p`
    font-family: PFDINText;
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
  `
  
  export const ButtonsContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `
  
  type ButtonProps = { backgroundColor?: string; color?: string }
  
  export const FormButton = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    height: 38px;
    padding: 8px 16px;
    text-transform: uppercase;
    margin: 0 12px;
    font-family: PFDINText;
    letter-spacing: 0.5px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ color }) => color ?? colors.white};
    background-color: ${({ backgroundColor }) =>
      backgroundColor ?? 'transparent'};
    border: ${({ backgroundColor }) =>
      backgroundColor ? 'unset' : `1px solid ${colors.white}`};
    cursor: pointer;
  `
  export const BackgroundImageDeleteButtonWrapper = styled.div`
  width: 5%;
`

export const SelectBackgroundImageContainer = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const SelectBackgroundImageOptionContainer = styled.div``

export const SelectBackgroundImageOptionImage = styled.img`
  width: auto;
  height: 130px;
`

export const SelectBackgroundImageOptionLabel = styled.span`
  margin-left: 20px;
`

export const BackgroundImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BackgroundImageImageWrapper = styled.div`
  width: 95%;
`

export const BackgroundImageImage = styled.img`
  width: 40%;
  height: auto;
`

export const TextInput = styled(Input)`
  && {
    height: 47px;
    padding: 0 16px;
    font-family: PFDINText;
    letter-spacing: -0.2px;
    font-size: 16px;
    border-radius: 6px;
    ${({ invalid }) => !invalid && `border: 0.3px solid ${colors.grey};`}

    ${({ textarea }) =>
      textarea &&
      `
        padding-top: 12px;
        height: 155px;
        line-height: 22px;
        resize: none;
      `}

    & + label,
    & + label:focus {
      left: 16px;
      font-family: PFDINText;
      font-size: 16px;
      letter-spacing: -0.2px;
      top: ${({ textarea }) => (textarea ? '12px' : '50%')};
      transform: ${({ textarea }) => (textarea ? 'unset' : 'translateY(-50%)')};
      font-weight: normal;
    }

    &:not(:placeholder-shown) + label {
      display: none;
    }
  }
`