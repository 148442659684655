import { useCallback, useState, useEffect } from 'react'
import { Prompt, useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { useKeycloak } from '@react-keycloak/web'
import { useFormik } from 'formik'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { DropzoneArea } from 'material-ui-dropzone'
import axios from 'axios'
import {
  DealerChannel,
  PopUp,
  PopUpStatus,
  Resource,
} from '@monorepo/interfaces'
import {
  http,
  ChannelHelper,
  PopUpHelper,
  isDate,
  utcDate,
  localDate,
  except,
  KeycloakHelper,
} from '@monorepo/infra'
import { colors } from '@monorepo/theme'
import { PageSpinner, PopUp as PopUpModal } from '@monorepo/components'
import ManageTemplate from '../../Manage'
import { ManageCard } from '../../../organisms'
import { PopUpSchema, initialValues } from './ManageFormHelpers'
import * as S from './styles'

export enum ManagePopUpsEditingMode {
  CREATING = 0,
  EDITING,
}

type SelectItemType = {
  value: string
  label: string
}

const popUpStatusOptions = PopUpHelper.getPopUpStatusOptions()
const channels = ChannelHelper.getChannelSelectItems().map((item) => ({
  value: item.id,
  label: item.value,
}))

export const LoadingStatus = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHING_SUCCESS: 'FETCHING_SUCCESS',
  FETCHING_ERROR: 'FETCHING_ERROR',
  SAVING: 'SAVING',
  SAVING_SUCCESS: 'SAVING_SUCCESS',
  SAVING_ERROR: 'SAVING_ERROR',
  UPLOADING: 'UPLOADING',
  UPLOADING_SUCCESS: 'UPLOADING_SUCCESS',
  UPLOADING_ERROR: 'UPLOADING_ERROR',
}

const predefinedBackgroundImageIds = [
  '64af7d66f153dfee3fa5a30c',
  '64af7e18f153dfee3fa5a310',
  '64af7e92f153dfee3fa5a314',
]

export interface ManagePopUpsProps {
  editingMode: ManagePopUpsEditingMode
}

type PopUpType = Omit<
  PopUp,
  'createdBy' | 'createdOn' | 'updatedBy' | 'updatedOn'
>

const ManagePopUps: React.FC<ManagePopUpsProps> = ({ editingMode }) => {
  const { id: popUpId } = useParams<{ id: string }>()
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)
  const [loadingStatus, setLoadingStatus] = useState<string>(
    LoadingStatus.INITIAL
  )
  const [popUp, setPopUp] = useState<PopUpType>(initialValues)
  const [backgroundImageIds, setBackgroundImageIds] = useState(
    predefinedBackgroundImageIds
  )
  const [backgroundImageURL, setBackgroundImageURL] = useState<
    string | undefined
  >(undefined)
  const [backgroundImageOptions, setBackgroundImageOptions] =
    useState<SelectItemType[]>()
  const [resourceOptions, setResourceOptions] = useState<SelectItemType[]>()
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [ctaURL, setCTAUrl] = useState<string | null | undefined>(undefined)
  const history = useHistory()

  const { errors, touched, handleBlur, ...formik } = useFormik({
    enableReinitialize: true,
    validationSchema: PopUpSchema,
    initialValues: popUp,
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        handleSubmit(values)
      })
    },
  })

  useEffect(() => {
    getPopUpBackgroundImages()
  }, [])

  useEffect(() => {
    getBackgroundImageOptions()
  }, [backgroundImageIds])

  useEffect(() => {
    if (!!formik.values.channel) {
      getResourceOptions(formik.values.channel)
    }
  }, [formik.values.channel])

  useEffect(() => {
    getBackgroundImageURL(formik.values.backgroundImage)
  }, [formik.values.backgroundImage])

  useEffect(() => {
    const isEditing =
      !!popUpId && editingMode === ManagePopUpsEditingMode.EDITING
    const isInitializing = loadingStatus === LoadingStatus.INITIAL
    const shouldSearchPopUp = isEditing && isInitializing
    if (!shouldSearchPopUp) return

    setLoadingStatus(LoadingStatus.FETCHING)

    http
      .get({ url: `/popup/${popUpId}` })
      .then((response) => {
        const popUp = response?.data as PopUpType
        setPopUp(patchValuesToEdit(popUp))
        setLoadingStatus(LoadingStatus.FETCHING_SUCCESS)
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.FETCHING_ERROR)
      })
  }, [editingMode, popUpId, formik, loadingStatus])

  const patchValuesToEdit = (popUp: PopUpType): PopUpType => {
    if (popUp.activationDate) {
      popUp.activationDate = localDate(popUp.activationDate)
    }
    if (popUp.expirationDate) {
      popUp.expirationDate = localDate(popUp.expirationDate)
    }

    return popUp
  }

  const patchValuesToSave = (values: PopUpType): PopUpType => ({
    ...values,
    ...(isDate(values.activationDate) && {
      activationDate: utcDate(values.activationDate as Date, true),
    }),
    ...(isDate(values.expirationDate) && {
      expirationDate: utcDate(values.expirationDate as Date, true),
    }),
  })

  const getResourceOptions = (channel: DealerChannel) => {
    http
      .get<{ data: Resource[] }>({
        url: `/resource?status=active&channel=${channel}`,
      })
      .then((response) => {
        if (Array.isArray(response.data?.data)) {
          setResourceOptions(
            response.data?.data.map(
              (item) =>
                ({ value: item._id, label: item.title } as SelectItemType)
            )
          )
        }
      })
      .catch((error) => console.log(error))
  }

  const getResource = async (id?: string) => {
    if (!id) {
      return undefined
    }

    let response

    try {
      response = await http.get<Resource>({ url: `/resource/${id}` })
    } catch (error) {
      console.log(error)
    }

    return response?.data
  }

  const getBackgroundImageURL = (id?: string) => {
    if (!id) {
      setBackgroundImageURL(undefined)
      return
    }

    http
      .get<{ path?: string }>({
        url: `${process.env.REACT_APP_MEDIA_URL}/${id}`,
      })
      .then((response) => {
        setBackgroundImageURL(response.data?.path)
      })
      .catch((error) => console.log(error))
  }

  const getPopUpBackgroundImages = () => {
    http
      .get({ url: '/popup/backgroundimages' })
      .then((response) => {
        if (Array.isArray(response?.data)) {
          const newItems = except(response?.data, backgroundImageIds)
          if (newItems.length) {
            const tmp = [...backgroundImageIds, ...newItems]
            setBackgroundImageIds(tmp)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getBackgroundImageOptions = () => {
    http
      .get({
        url: `${process.env.REACT_APP_MEDIA_URL}/byid`,
        params: { id: backgroundImageIds },
      })
      .then((response) => {
        if (Array.isArray(response?.data)) {
          setBackgroundImageOptions([
            ...response?.data
              .filter(
                (item) => predefinedBackgroundImageIds.indexOf(item._id) !== -1
              )
              .map((item) => createBackgroundImageOption(item)),
            ...response?.data
              .filter(
                (item) => predefinedBackgroundImageIds.indexOf(item._id) === -1
              )
              .map((item) => createBackgroundImageOption(item)),
          ])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const createBackgroundImageOption = (item: {
    _id: string
    name: string
    path: string
  }) => ({
    value: item._id,
    label: getBackgroundImageFileName(item.name) || '',
    path: item.path,
  })

  const handleGoBack = useCallback(() => {
    history.push('/admin/pop-ups')
  }, [history])

  const handleCreatePopUp = useCallback(
    (values: PopUpType) => {
      setLoadingStatus(LoadingStatus.SAVING)

      http
        .post({ url: `/popup`, data: values })
        .then((response) => {
          const popUp = response?.data as PopUpType
          history.push(`/admin/pop-ups/edit/${popUp?._id}`)
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.SAVING_ERROR)
          throw error
        })
    },
    [history]
  )

  const handleEditPopUp = (values: PopUpType) => {
    setLoadingStatus(LoadingStatus.SAVING)

    http
      .patch({ url: `/popup/${popUpId}`, data: values })
      .then((data) => {
        setLoadingStatus(LoadingStatus.SAVING_SUCCESS)
        setPopUp(patchValuesToEdit(data.data as PopUpType))
        formik.resetForm({ values })
      })
      .catch(() => {
        setLoadingStatus(LoadingStatus.SAVING_ERROR)
      })
  }

  const handleSubmit = (values: PopUpType) => {
    const valuesToSave = patchValuesToSave(values)
    const handler =
      editingMode === ManagePopUpsEditingMode.CREATING
        ? handleCreatePopUp
        : handleEditPopUp
    handler(valuesToSave)
  }

  const handleDiscardChanges = () => {
    formik.setValues(popUp)
  }

  const handleUploadFiles = (file: File | null, fileType?: string) => {
    if (file) {
      setLoadingStatus(LoadingStatus.UPLOADING)
      axios.create({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
      })
      const url = `${process.env.REACT_APP_MEDIA_URL}`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('isInDesign', 'false')
      formData.append('tags', '')
      formData.append('email', user?.email)
      formData.append('mediaType', fileType || '')
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      axios
        .post(url, formData, config)
        .then((success) => {
          formik.setFieldValue('backgroundImage', success?.data?._id)
          backgroundImageIds?.push(success?.data?._id)
          backgroundImageOptions?.push(
            createBackgroundImageOption(success?.data)
          )
          setLoadingStatus(LoadingStatus.UPLOADING_SUCCESS)
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.UPLOADING_ERROR)
        })
    }
  }

  const handleDeleteBackgroundImage = () => {
    formik.setFieldValue('backgroundImage', undefined)
    setBackgroundImageURL(undefined)
  }

  const handlePreviewClick = async () => {
    const resource = await getResource(formik.values.ctaResource)
    setCTAUrl(resource?.document)
    setIsPopUpOpen(true)
  }

  const getBackgroundImageFileName = (path?: string) => {
    if (!path) {
      return path
    }

    const match = path.match(/(@)(.+)/)

    return Array.isArray(match) && match.length > 2 ? match[2] : path
  }

  return (
    <ManageTemplate
      onGoBack={handleGoBack}
      backText="Back to all Pop-Ups"
      titleText={`${
        editingMode === ManagePopUpsEditingMode.CREATING ? 'Create' : 'Manage'
      } Pop-Up`}
      headerRightSideChildren={
        <S.ButtonsContainer>
          {formik.dirty && (
            <>
              <S.FormButton onClick={handleDiscardChanges}>
                Discard
              </S.FormButton>
              <S.FormButton onClick={handlePreviewClick}>Preview</S.FormButton>
              {formik.isValid && (
                <>
                  {editingMode === ManagePopUpsEditingMode.CREATING && (
                    <S.FormButton
                      backgroundColor={colors.mediumGrey}
                      onClick={() => {
                        formik.setFieldValue(
                          'status',
                          popUpStatusOptions.find(
                            ({ value }) =>
                              value === PopUpStatus.DRAFT.toString()
                          )
                        )

                        handleSubmit({
                          ...formik.values,
                          status: parseInt(PopUpStatus.DRAFT.toString()),
                        })
                      }}
                    >
                      Save as Draft
                    </S.FormButton>
                  )}
                  <S.FormButton
                    backgroundColor={colors.blue}
                    onClick={() => handleSubmit(formik.values)}
                  >
                    Save
                  </S.FormButton>
                </>
              )}
            </>
          )}
        </S.ButtonsContainer>
      }
    >
      {[
        LoadingStatus.FETCHING,
        LoadingStatus.SAVING,
        LoadingStatus.UPLOADING,
      ].includes(loadingStatus) ? (
        <PageSpinner />
      ) : (
        <S.ColumnsContainer>
          <Prompt
            when={!!formik.dirty}
            message={
              'You have made changes to this pop-up. If you leave, any unsaved changes will be lost. Are you sure?'
            }
          />
          <S.LeftColumn>
            <ManageCard title="Headline">
              <S.TextInput
                name="headline"
                label="Headline"
                value={formik.values.headline}
                onChange={({
                  target: { value },
                }: {
                  target: { value: string }
                }) => formik.setFieldValue('headline', value)}
                onBlur={handleBlur}
                invalid={!!errors.headline && touched.headline}
                invalidMessage={errors.headline}
              />
            </ManageCard>
            <ManageCard title="Text">
              <S.TextInput
                name="text"
                label="Text"
                textarea
                value={formik.values.text}
                onChange={({
                  target: { value },
                }: {
                  target: { value: string }
                }) => formik.setFieldValue('text', value)}
                onBlur={handleBlur}
                invalid={!!errors.text && touched.text}
                invalidMessage={errors.text}
              />
            </ManageCard>
            <ManageCard title="Background Image">
              {!!backgroundImageURL ? (
                <S.BackgroundImageContainer>
                  <S.BackgroundImageImageWrapper>
                    <S.BackgroundImageImage src={backgroundImageURL} />
                  </S.BackgroundImageImageWrapper>
                  <S.BackgroundImageDeleteButtonWrapper>
                    <IconButton onClick={handleDeleteBackgroundImage}>
                      <DeleteIcon />
                    </IconButton>
                  </S.BackgroundImageDeleteButtonWrapper>
                </S.BackgroundImageContainer>
              ) : (
                <S.SelectBackgroundImageContainer>
                  <DropzoneArea
                    maxFileSize={100000000}
                    filesLimit={1}
                    onChange={(files) => {
                      handleUploadFiles(files[0])
                    }}
                  ></DropzoneArea>
                  <Select
                    placeholder="Select a Background Image"
                    options={backgroundImageOptions}
                    formatOptionLabel={(option) => (
                      <S.SelectBackgroundImageOptionContainer>
                        <S.SelectBackgroundImageOptionImage
                          src={
                            (
                              option as {
                                value: string
                                label: string
                                path: string
                              }
                            ).path
                          }
                        />
                        <S.SelectBackgroundImageOptionLabel>
                          {option.label}
                        </S.SelectBackgroundImageOptionLabel>
                      </S.SelectBackgroundImageOptionContainer>
                    )}
                    onChange={(newValue) =>
                      formik.setFieldValue(
                        'backgroundImage',
                        (newValue as SelectItemType)?.value
                      )
                    }
                  />
                </S.SelectBackgroundImageContainer>
              )}
            </ManageCard>
            <ManageCard title="CTA">
              <S.CTAContainer>
                <S.TextInput
                  name="ctaText"
                  label="LEARN MORE"
                  value={formik.values.ctaText}
                  onChange={({
                    target: { value },
                  }: {
                    target: { value: string }
                  }) => formik.setFieldValue('ctaText', value)}
                  onBlur={handleBlur}
                  invalid={!!errors.ctaText && touched.ctaText}
                  invalidMessage={errors.ctaText}
                />
                <Select
                  name="ctaResource"
                  placeholder="Select a Resource"
                  options={resourceOptions}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    typeof formik.values.ctaResource === 'string'
                      ? resourceOptions?.find(
                          ({ value }) => value === formik.values.ctaResource
                        )
                      : formik.values.ctaResource
                  }
                  onChange={(newValue) => {
                    const resource = (newValue as SelectItemType)
                      ?.value as string

                    formik.setFieldValue('ctaResource', resource)
                  }}
                  styles={S.SelectStyle}
                />
              </S.CTAContainer>
            </ManageCard>
          </S.LeftColumn>
          <S.RightColumn>
            <ManageCard title="Status">
              <Select
                name="status"
                placeholder=""
                options={popUpStatusOptions}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={
                  typeof formik.values.status === 'number'
                    ? popUpStatusOptions.find(
                        ({ value }) => value === String(formik.values.status)
                      )
                    : formik.values.status
                }
                onChange={(newValue) => {
                  const status = (newValue as SelectItemType)?.value as string

                  formik.setFieldValue('status', parseInt(status))
                }}
                styles={S.SelectStyle}
              />
            </ManageCard>
            <ManageCard title="Channel">
              <Select
                name="channel"
                placeholder={channels[0].label}
                options={channels}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={channels.find(
                  ({ value }) => value === formik.values.channel
                )}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    'channel',
                    (newValue as SelectItemType)?.value
                  )
                }
                styles={S.SelectStyle}
              />
            </ManageCard>
            <ManageCard title="Message Dates">
              <S.PopUpDateLabel>Activation Date</S.PopUpDateLabel>
              <S.DatePickerContainer>
                <S.KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Select a date"
                  format="MM/dd/yy"
                  disableToolbar
                  value={formik.values.activationDate ?? null}
                  onChange={(date) => {
                    if (isDate(date)) {
                      date?.setHours(0, 0, 0, 0)
                    }
                    formik.setFieldValue('activationDate', date)
                  }}
                />
                <S.ClearButton
                  colorOption="delicateGrey"
                  icon="Close"
                  borderRadius
                  size="small"
                  onClick={() => {
                    formik.setFieldValue('activationDate', null)
                  }}
                />
              </S.DatePickerContainer>
              <S.PopUpDateLabel>Expiration Date (optional)</S.PopUpDateLabel>
              <S.DatePickerContainer>
                <S.KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Select a date"
                  format="MM/dd/yy"
                  disableToolbar
                  minDate={formik.values.activationDate}
                  value={formik.values.expirationDate ?? null}
                  onChange={(date) => {
                    if (isDate(date)) {
                      date?.setHours(0, 0, 0, 0)
                    }
                    formik.setFieldValue('expirationDate', date)
                  }}
                />
                <S.ClearButton
                  colorOption="delicateGrey"
                  icon="Close"
                  borderRadius
                  size="small"
                  onClick={() => {
                    formik.setFieldValue('expirationDate', null)
                  }}
                />
              </S.DatePickerContainer>
            </ManageCard>
          </S.RightColumn>
        </S.ColumnsContainer>
      )}
      <PopUpModal
        isOpen={isPopUpOpen}
        title={formik.values.headline}
        text={formik.values.text}
        backgroundImage={backgroundImageURL}
        ctaText={formik.values.ctaText}
        ctaURL={ctaURL as string}
        toggleModal={() => setIsPopUpOpen(false)}
        size="medium"
      />
    </ManageTemplate>
  )
}

export default ManagePopUps
