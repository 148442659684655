import * as Yup from 'yup'

const TicketSchema = Yup.object().shape({
  masterDealerId: Yup.string().required('masterDealerId is required'),
  channel: Yup.string().required('channel is required'),
  subject: Yup.string().required('subject is required'),
  priority: Yup.number().required('priority is required'),
  status: Yup.number().required('status is required'),
  vendor: Yup.number().required('vendor is required'),
  category: Yup.number().required('category is required'),
  agent: Yup.string().nullable().optional(),
  dealer: Yup.string().nullable().optional(),
  assignee: Yup.string().nullable().optional(),
  tags: Yup.array().of(Yup.string()).optional(),
})

export const TicketCreatingSchema = TicketSchema.concat(
  Yup.object().shape({
    message: Yup.string().required('message is required'),
  })
)

export const TicketEditingSchema = TicketSchema.concat(
  Yup.object().shape({
    message: Yup.string().optional(),
  })
)

export const initialValues = {
  masterDealerId: '',
  channel: '',
  subject: '',
  priority: 1,
  status: 1,
  vendor: 0,
  category: 0,
  agent: '',
  dealer: '',
  assignee: '',
  tags: [],
  messages: [],
  message: '',
}
