import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const SectionProducts: React.FC = () => {
  const { t } = useTranslation('admin')

  return (
    <S.Container>
      <S.ProductsTitle>{t('section_products.title')}</S.ProductsTitle>
    </S.Container>
  )
}

export default SectionProducts
