import React, { useEffect, useState } from 'react'
import { SectionUsers } from '../../organisms'
import { KeycloakHelper, ReportType } from '@monorepo/infra'
import { useFormik } from 'formik'
import Select from 'react-select'

import * as S from './styles'
import { useHistory } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { PageSpinner } from '@monorepo/components'
import { ProductType } from '@monorepo/interfaces'

type SelectItemType = {
  value: string
  label: string
}

type ReportItem = {
  id: ReportType
  title: string
  filters: string[]
}

const reports = [
  {
    id: ReportType.ACTIVITY_BY_DEALER,
    title: 'Activity by Dealer',
    filters: ['date', 'channel', 'dealer', 'teamMember'],
  },
  {
    id: ReportType.ACTIVITY_BY_PRODUCT,
    title: 'Activity by Product',
    filters: ['date', 'channel', 'product', 'type'],
  },
  {
    id: ReportType.PRODUCTS_ORDERED,
    title: 'Products Ordered',
    filters: ['date', 'channel'],
  },
  {
    id: ReportType.PRODUCT_CATALOG,
    title: 'Product Catalog',
    filters: ['channel', 'product'],
  },
  {
    id: ReportType.SPEND_BY_MEDIA,
    title: 'Spend by Media',
    filters: ['date', 'channel', 'showSummary'],
  },
  {
    id: ReportType.EXECUTIVE_SUMMARY,
    title: 'Executive Summary',
    filters: ['date'],
  },
  {
    id: ReportType.PRODUCT_DOWNLOAD_BREAKDOWN,
    title: 'Product Download Breakdown',
    filters: ['date', 'channel'],
  },
  {
    id: ReportType.ACTIVE_USERS_BY_CHANNEL,
    title: 'Active Users by Channel',
    filters: [],
  },
  {
    id: ReportType.FINANCIAL_REPORTING_BY_VENDOR,
    title: 'Financial Reporting by Vendor',
    filters: ['date', 'vendor'],
  },
  {
    id: ReportType.RESOURCE_CENTER_UTILIZATION,
    title: 'Resource Center Utilization',
    filters: ['date'],
  },
  {
    id: ReportType.DEALER_ENGAGEMENT,
    title: 'Dealer Engagement',
    filters: ['date', 'channel'],
  },
  {
    id: ReportType.SUMMARY_OF_OPEN_CLOSED_TICKETS,
    title: 'Summary of open/closed tickets',
    filters: ['date', 'channel'],
  },
  {
    id: ReportType.SUMMARY_OF_TICKETS_BY_CHANNEL,
    title: 'Summary of tickets by channel',
    filters: ['date', 'channel'],
  },
  {
    id: ReportType.SUPPORT_TICKET_LEVEL_DETAILS,
    title: 'Support ticket level details',
    filters: ['date', 'channel'],
  },
]

const reportOptions = reports.map(({ id: value, title: label }) => ({
  label,
  value: value.toString(),
}))
console.log(JSON.stringify(reportOptions))

const channelOptions = [
  { label: 'LSP', value: 'lsp' },
  { label: 'MDU', value: 'mdu' },
  { label: 'D2D', value: 'd2d' },
  { label: 'EM', value: 'em' },
  { label: 'ND', value: 'nd' },
  { label: 'ISP', value: 'isp' },
  { label: 'All Channels', value: 'all' },
]

const productOptions = [
  { label: 'Creative', value: ProductType.CREATIVE },
  { label: 'Media', value: ProductType.CREATIVE_CUSTOM },
  { label: 'Event', value: ProductType.EVENT },
  { label: 'Microsite', value: ProductType.MICROSITE },
]

const vendorOptions = [
  { label: 'PURE', value: 1 },
  { label: 'DSI', value: 2 },
]

const now = new Date()

const Reports: React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [selectedReport, setSelectedReport] = useState<ReportItem | null>(null)
  const { keycloak } = useKeycloak()
  const [userProfile, setUserProfile] = useState<{
    firstName: string
    lastName: string
    email: string
  } | null>(null)
  useEffect(() => {
    const currentUser = KeycloakHelper.getTokenParsed(keycloak)
    if (!currentUser) {
      return
    }

    setUserProfile({
      firstName: currentUser.given_name ?? '',
      lastName: currentUser.family_name ?? '',
      email: currentUser.email ?? '',
    })

    setLoading(false)
  }, [keycloak])
  const { errors, touched, handleBlur, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      report: '',
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: now,
      channel: '',
      product: 0,
      vendor: 0,
    },
    validateOnBlur: true,
    onSubmit: (values, { validateForm }) => {
      validateForm().then(() => {
        console.log(values)
      })
    },
  })
  useEffect(() => {
    if (formik.values.report === null) return
    const report = reports.find(
      ({ id }) => id.toString() === formik.values.report
    )
    if (report) {
      setSelectedReport(report)
    }
  }, [formik.values.report])

  if (loading) return <PageSpinner />

  console.log(JSON.stringify(userProfile))

  return (
    <S.Main>
      <SectionUsers title="Reports & Analytics" />
      {/* <S.Section>
        <S.SectionTitle>OVERVIEW</S.SectionTitle>
      </S.Section> */}
      <S.Section>
        <S.SectionTitle>CREATE A REPORT</S.SectionTitle>
        <S.RowContainer>
          <S.OneHalf>
            <Select
              name="report"
              placeholder="Select Report"
              options={reportOptions}
              value={
                typeof formik.values.report === 'string'
                  ? reportOptions.find(
                      ({ value }) => value === formik.values.report
                    )
                  : formik.values.report
              }
              onChange={(newValue: unknown) => {
                const report = (newValue as SelectItemType)?.value as string

                formik.setFieldValue('report', report)
              }}
            />
            {selectedReport && selectedReport.filters.includes('channel') && (
              <Select
                name="channel"
                placeholder="Select Channel"
                options={channelOptions}
                value={
                  typeof formik.values.channel === 'string'
                    ? channelOptions.find(
                        ({ value, label }) =>
                          value === formik.values.channel ||
                          label === formik.values.channel
                      )
                    : formik.values.channel
                }
                onChange={(newValue: unknown) => {
                  const channel = (newValue as SelectItemType)?.value as string
                  formik.setFieldValue('channel', channel)
                }}
              />
            )}
            {selectedReport && selectedReport.filters.includes('product') && (
              <Select
                name="product"
                placeholder="Select Product"
                options={productOptions}
                value={
                  typeof formik.values.product === 'number'
                    ? productOptions.find(
                        ({ value }) => value === formik.values.product
                      )
                    : formik.values.product
                }
                onChange={(newValue: unknown) => {
                  const product = (newValue as SelectItemType)?.value as string
                  formik.setFieldValue('product', product)
                }}
              />
            )}
            {selectedReport && selectedReport.filters.includes('vendor') && (
              <Select
                name="vendor"
                placeholder="Select Vendor"
                options={vendorOptions}
                value={
                  typeof formik.values.vendor === 'number'
                    ? vendorOptions.find(
                        ({ value }) => value === formik.values.vendor
                      )
                    : formik.values.vendor
                }
                onChange={(newValue: unknown) => {
                  const vendor = (newValue as SelectItemType)?.value as string
                  formik.setFieldValue('vendor', vendor)
                }}
              />
            )}
          </S.OneHalf>
          <S.OneHalf>
            {selectedReport && selectedReport.filters.includes('date') && (
              <S.DatePickerContainer>
                <S.StartEndDateContainer>
                  <S.EventDateLabel>Start Date</S.EventDateLabel>
                  <S.KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Select a date"
                    format="MM/dd/yy"
                    disableToolbar
                    defaultValue={
                      new Date(now.getFullYear(), now.getMonth(), 1)
                    }
                    name="startDate"
                    onBlur={handleBlur}
                    // error={!!errors.startDate && touched.startDate}
                    value={formik.values.startDate}
                    onChange={(date) => {
                      formik.setFieldValue('startDate', date)

                      if (formik.values.endDate === null) {
                        formik.setFieldValue('endDate', date)
                      }
                    }}
                  />
                </S.StartEndDateContainer>
                <S.StartEndDateContainer>
                  <S.EventDateLabel>End Date</S.EventDateLabel>
                  <S.KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Select a date"
                    format="MM/dd/yy"
                    disableToolbar
                    defaultValue={now}
                    name="endDate"
                    minDate={formik.values.startDate}
                    value={formik.values.endDate}
                    onChange={(date) => formik.setFieldValue('endDate', date)}
                  />
                </S.StartEndDateContainer>
                {/* <S.EventDateClearContainer>
                  <S.ClearButton
                    colorOption="delicateGrey"
                    icon="Close"
                    borderRadius
                    size="small"
                    onClick={() => {
                      formik.setFieldValue('startDate', null)
                      formik.setFieldValue('endDate', null)
                    }}
                  />
                </S.EventDateClearContainer> */}
              </S.DatePickerContainer>
            )}
          </S.OneHalf>
        </S.RowContainer>
        <S.RowContainer>
          <S.ActionButton
            onClick={() =>
              history.push({
                pathname: '/viewReport',
                state: { ...formik.values, userProfile },
              })
            }
          >
            Run Report
          </S.ActionButton>
        </S.RowContainer>
      </S.Section>
    </S.Main>
  )
}

export default Reports
