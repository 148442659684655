import { useKeycloak } from '@react-keycloak/web'
import { useCallback, useEffect, useRef, useState } from 'react'
import { HeaderLogo } from '../../molecules'
import { IconsType } from '@monorepo/components'
import * as S from './styles'
import { KeycloakHelper, useImpersonatedUser } from '@monorepo/infra'

type EventListenerCallback = DocumentEventMap['mousedown'] & { target: Node }

type MouseDownEventListener = (
  this: Document,
  ev: DocumentEventMap['mousedown']
) => void

const DashboardHeaderContent: React.FC = () => {
  const [isUserProfileOpened, setIsUserProfileOpened] = useState(false)
  const profileOptionsRef = useRef<HTMLUListElement>(null)
  const { name: impersonatedUserName, setState: setImpersonatedUserName } =
    useImpersonatedUser()
  const [impersonatedUser, setImpersonatedUser] = useState<
    string | null | undefined
  >()

  // TODO: After implement notifications context, get this info from there
  const hasNotifications = true

  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)

  useEffect(() => {
    if (!impersonatedUserName) {
      return
    }
    setImpersonatedUser(impersonatedUserName)
  }, [impersonatedUserName])

  const handleLogout: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      event.preventDefault()
      setImpersonatedUserName(undefined)
      setImpersonatedUser(undefined)
      KeycloakHelper.clearTokenExchange(keycloak)
      keycloak.logout()
    },
    [keycloak]
  )

  const handleOpenCloseProfileOptions = useCallback(() => {
    setIsUserProfileOpened((isOpened) => !isOpened)
  }, [])

  useEffect(() => {
    function handleClickOutside(event: EventListenerCallback) {
      const containsEventTarget = profileOptionsRef.current?.contains?.(
        event?.target
      )

      if (profileOptionsRef.current && !containsEventTarget) {
        setIsUserProfileOpened(false)
      }
    }

    document.addEventListener(
      'mousedown',
      handleClickOutside as MouseDownEventListener
    )

    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutside as MouseDownEventListener
      )
    }
  }, [profileOptionsRef])

  return (
    <>
      <HeaderLogo />
      <S.HeaderRightSide>
        {/* 
          TODO: just uncomment the component below when the admin global search be implemented and get started from that
          <S.Search />
        */}
        <S.IconLinkContainer>
          <S.IconLink to="/help">
            <IconsType.QuestionMarkCircle />
          </S.IconLink>
          <S.IconLink to="/notifications">
            {hasNotifications ? <IconsType.BellBadge /> : <IconsType.Bell />}
          </S.IconLink>
        </S.IconLinkContainer>
        <S.UserContainer
          role="button"
          onClick={handleOpenCloseProfileOptions}
          ref={profileOptionsRef}
        >
          <S.UserName>{user?.name ? user?.name : 'Loading user...'}</S.UserName>
          {!!impersonatedUser ? (
            <S.UserName color="red">{impersonatedUser}</S.UserName>
          ) : undefined}
          <S.UserProfileBadge>
            {user?.name?.charAt(0) ?? 'T'}
          </S.UserProfileBadge>
          {!isUserProfileOpened ? null : (
            <S.UserProfileOptions>
              <S.UserProfileOption>
                <S.UserProfileLink to="/settings">Settings</S.UserProfileLink>
              </S.UserProfileOption>
              <S.UserProfileOption>
                <S.UserProfileLink to="/sign-out" onClick={handleLogout}>
                  Sign Out
                </S.UserProfileLink>
              </S.UserProfileOption>
            </S.UserProfileOptions>
          )}
        </S.UserContainer>
      </S.HeaderRightSide>
    </>
  )
}

export default DashboardHeaderContent
