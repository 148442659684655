import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DirectoryTreeView from '../DirectoryTreeView'
import { isFunction } from '@monorepo/infra'
import { List, ListItem } from '@material-ui/core'
import { ListItemText } from '@mui/material'
import { Label } from '@monorepo/components'

export interface DirectoryTreeViewDialogProps {
  open: boolean
  title?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userActivity?: any
  onClose?: () => void
}

const DirectoryTreeViewDialog: React.FC<DirectoryTreeViewDialogProps> = ({
  open = false,
  title = undefined,
  data = undefined,
  userActivity = undefined,
  onClose = undefined,
}) => {
  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose?.()
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogContent>
        {userActivity.length > 0 ?
          (
            <>
              <DialogTitle>User Activity</DialogTitle>
              <List>
                {
                  userActivity?.map((item: string, key: number) => {
                    return item ? 
                          (<ListItem key={key}>
                            <ListItemText>{item}</ListItemText>
                          </ListItem>
                        ) : undefined
                  })}
              </List>
            </>
              ):(undefined)
        }
        {data?(
            <>
        {!!title ? <DialogTitle>{title}</DialogTitle> : undefined}
            <DirectoryTreeView data={data} />
            </>
          ):undefined
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DirectoryTreeViewDialog
