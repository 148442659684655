import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { useDebounce, EventHelper } from '@monorepo/infra'
import { Product, Event } from '@monorepo/interfaces'

interface ProductSearchProps {
  onClick: (item: Product | Event, type?: string) => void
  onSearch: (search: Record<string, unknown>) => void
  products: { data: Product[] }
  events: { data: Event[] }
}

const ProductSearch: React.FC<ProductSearchProps> = ({
  onClick,
  onSearch,
  products,
  events,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const { t } = useTranslation('admin')

  const handleClick = (item: Product | Event, type?: string) => {
    setSearchTerm('')
    onClick(item, type)
  }

  useEffect(() => {
    onSearch({ search: debouncedSearchTerm })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])
  return (
    <S.Container>
      <S.InputBase
        placeholder={t('section_products_list.input_placeholder')}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        endAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      {debouncedSearchTerm ? (
        <div>
          <article key="products">
            <h1>Products</h1>
            {products?.data?.length > 0 ? (
              products?.data?.map((product: Product, index: number) => {
                return (
                  <S.Item
                    key={index}
                    onClick={() => handleClick(product, 'product')}
                  >
                    {product.variants[0].media[0] ? (
                      <img
                        src={product.variants[0].media[0]}
                        alt={product.title}
                        style={{ maxWidth: '120px' }}
                      />
                    ) : (
                      <S.EmptyImage>No Image</S.EmptyImage>
                    )}
                    <S.ItemTitle>{product.title}</S.ItemTitle>
                  </S.Item>
                )
              })
            ) : (
              <section>No products found</section>
            )}
          </article>
          <article key="events">
            <h1>Events</h1>
            {events?.data?.length > 0 ? (
              events?.data?.map((event: Event, index: number) => {
                const image = EventHelper.getImageFile(EventHelper.getEventTypeDescription(event.type))
                return (
                  <section
                    key={index}
                    onClick={() => handleClick(event, 'event')}
                  >
                    <S.Item>
                      {image ? (
                        <img
                          src={image}
                          alt={event.title}
                          style={{ maxWidth: '120px' }}
                        />
                      ) : (
                        <S.EmptyImage>No Image</S.EmptyImage>
                      )}
                      <S.ItemTitle>{event.title}</S.ItemTitle>
                    </S.Item>
                  </section>
                )
              })
            ) : (
              <section>No events found</section>
            )}
          </article>
        </div>
      ) : null}
    </S.Container>
  )
}

export default ProductSearch
