import React from 'react'
import { Title, EditUser } from '@monorepo/components'
import { UserProfileProps, FeaturesDataProps } from '@monorepo/interfaces'

interface UserProfileManageProps extends UserProfileProps {
  title: string
  features: FeaturesDataProps
}

const ManageUser: React.FC<UserProfileManageProps> = ({
  features,
  title,
  ...props
}) => {
  return (
    <>
      <Title as={'h1'}>{title}</Title>
      <EditUser {...props} features={features} />
    </>
  )
}

export default ManageUser
