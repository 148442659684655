import React, { useCallback, useEffect, useState } from 'react'
import { ActiveUsersByChannelRow } from '@monorepo/interfaces'
import { http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import { Pie } from '@nivo/pie'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

const ActiveUsersByChannel: React.FC = () => {
  const [report, setReport] = useState<ActiveUsersByChannelRow[] | null>(null)
  useEffect(() => {
    http
      .get<ActiveUsersByChannelRow[]>({
        url: `/reports/activeUsersByChannel`,
      })
      .then(({ data }) => setReport(data))
  }, [])
  const exportExcel = useCallback(async () => {
    if (report !== null) {
      const reportData = report.map((reportRow) => {
        return {
          Channel: reportRow.channel,
          Registered: reportRow.registered,
          Total: reportRow.total,
        }
      })
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const ws = XLSX.utils.json_to_sheet(reportData)
      const wb = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `ActiveUsersByChannel${fileExtension}`)
    }
  }, [report])
  if (!report || report === null) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Active Users by Channel
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Date</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(new Date(), 'MM/dd/yyyy')}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.PieChartList>
        {report.length > 0 &&
          report.map((channelInfo) => (
            <S.PieChartListItem>
              <S.PieChartTitle>{`${channelInfo.channel.toUpperCase()} Active Users`}</S.PieChartTitle>
              <S.PieChartSubtitle>{channelInfo.registered}</S.PieChartSubtitle>
              <Pie
                enableArcLabels={false}
                enableArcLinkLabels={false}
                data={[
                  {
                    id: 'Active',
                    value: channelInfo.registered,
                    color: '#76D4F8',
                  },
                  {
                    id: 'Inactive',
                    value: channelInfo.total - channelInfo.registered,
                    color: '#213C56',
                  },
                ]}
                colors={['#76D4F8', '#213C56']}
                height={150}
                width={150}
              />
              <S.PieChartInfo>{`${channelInfo.total} Registered Users`}</S.PieChartInfo>
            </S.PieChartListItem>
          ))}
      </S.PieChartList>
    </S.Container>
  )
}

export default ActiveUsersByChannel
