import { Spinner } from '@monorepo/components'
import * as S from './styles'

const PageSpinner = (): JSX.Element => (
  <S.SpinnerContainer>
    <Spinner spinnerSize={12} />
  </S.SpinnerContainer>
)

export default PageSpinner
