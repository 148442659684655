import styled from 'styled-components'
import { InputBase as MuiInputBase } from '@material-ui/core'
import { colors } from '@monorepo/theme'

import {
  TableCell as MuiTableCell,
  Button as MuiButton,
} from '@material-ui/core'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const InputBase = styled(MuiInputBase)`
  width: 100%;
  padding: 3px 10px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 2px;

  .MuiInputBase-input::placeholder {
    opacity: 1;
    font-size: 0.85em;
  }
`

export const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
    border-bottom: 2px solid gray;
  }
`

export const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding: 0 5px 0 5px;
    border-bottom: 2px solid gray;
  }
`

export const Button = styled(MuiButton).attrs({ disableRipple: true })`
  &.MuiButton-root {
    border: 2px solid ${colors.lightGrey};
  }

  .MuiButton-label {
    color: ${colors.lightGrey};
    font-size: 0.9em;
    font-family: 'ATTAleckSansBold';
    text-transform: none;
  }
`

export const ItemTitle = styled.p`
  font-family: PFDINText;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${colors.black};
  text-transform: uppercase;
  font-weight: 400;
  width: 100%;
`

export const Item = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  padding: 8px 0;
  width: 100%;
`

export const EmptyImage = styled.p`
  font-family: PFDINText;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${colors.black};
  text-transform: uppercase;
  font-weight: 400;
  width: 200px;
`
