import * as S from './styles'

import { Resource } from '@monorepo/interfaces'

import { Spinner } from '@monorepo/components'
import { TableBody } from '@material-ui/core'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'

export interface SectionResourcesListProps {
  resources: Resource[]
  loading: boolean
}

const SectionResourcesList: React.FC<SectionResourcesListProps> = ({
  resources,
  loading = true,
}) => {
  const getStatus = (status: number): string => {
    switch (status) {
      case 1:
        return 'Active'
      case 2:
        return 'Inactive'
      case 3:
        return 'Archived'
    }
    return 'Unknown'
  }

  const history = useHistory()

  const handleEditResource = (index: number) => {
    const id = resources[index]._id
    history.push(`/admin/resources/edit/${id}`)
  }

  return (
    <S.Container>
      <S.TableContainer>
        <S.Table aria-label="Resources Table">
          <S.TableHead>
            <S.TableRow>
              <S.TableHeadCell>Title</S.TableHeadCell>
              <S.TableHeadCell>Channels</S.TableHeadCell>
              <S.TableHeadCell>Last Updated</S.TableHeadCell>
              <S.TableHeadCell>Status</S.TableHeadCell>
            </S.TableRow>
          </S.TableHead>
          <TableBody>
            {loading ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : (
              resources?.map((resource: Resource, index: number) => (
                <S.TbodyRow
                  key={index}
                  onClick={() => handleEditResource(index)}
                >
                  <S.TBodyCell scope="row">
                    <S.ResourceTitle>{resource.title}</S.ResourceTitle>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">{resource.channels ? resource.channels.join(', ') : ''}</S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {resource.updatedOn
                      ? format(new Date(resource.updatedOn), 'MM/dd/yyyy')
                      : 'N/D'}
                  </S.TBodyCell>
                  <S.TBodyCell>
                    <S.TagsContainer>
                      <S.StatusTag data-status={resource.status}>
                        {getStatus(resource.status)}
                      </S.StatusTag>
                    </S.TagsContainer>
                  </S.TBodyCell>
                </S.TbodyRow>
              ))
            )}
          </TableBody>
        </S.Table>
      </S.TableContainer>
    </S.Container>
  )
}

export default SectionResourcesList
