import styled from 'styled-components'

import { Button as MuiButton } from '@material-ui/core'

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
`

export const Button = styled(MuiButton).attrs({ disableRipple: true })`
  padding: 5px;

  &.MuiButton-root {
    border: 2px solid gray;
    background-color: gray;
  }

  .MuiButton-label {
    color: white;
    font-size: 0.9em;
    font-family: 'ATTAleckSansBold';
    text-transform: none;
  }
`
