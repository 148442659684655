import React, { useEffect, useState, useCallback } from 'react'
import {
  SummaryOfTicketsByChannelReport,
  SummaryOfTicketsByChannelRow,
  TicketStatus,
} from '@monorepo/interfaces'
import { TicketHelper, formatDateToUTC, http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { populateTicketReportData } from '../helpers/ticket'

interface SummaryOfTicketsByChannelProps {
  startDate: Date
  endDate: Date
  channel?: string
}

const SummaryOfTicketsByChannel: React.FC<SummaryOfTicketsByChannelProps> = ({
  startDate,
  endDate,
  channel,
}) => {
  const [report, setReport] = useState<
    SummaryOfTicketsByChannelReport | null | undefined
  >(undefined)
  const [channels, setChannels] = useState<string[] | null | undefined>(
    undefined
  )

  const statusClosed = TicketHelper.getTicketStatusName(TicketStatus.CLOSED)
  const statusDeleted = TicketHelper.getTicketStatusName(TicketStatus.DELETED)

  useEffect(() => {
    http
      .get<SummaryOfTicketsByChannelReport>({
        url: `/reports/summaryofticketsbychannel?dateFrom=${format(
          startDate,
          'yyyy-MM-dd'
        )}&dateTo=${format(endDate, 'yyyy-MM-dd')}${
          !!channel ? `&channel=${channel}` : ''
        }`,
      })
      .then(({ data }) => {
        const { data: report, keys: channels } = populateTicketReportData(data)
        setChannels(channels)
        setReport(report)
      })
  }, [endDate, startDate])

  const exportExcel = useCallback(async () => {
    if (report && Array.isArray(channels)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sheets: { [key: string]: any } = {}
      const sheetNames = []

      for (let i = 0, l = channels.length; i < l; ++i) {
        sheets[channels[i]] = XLSX.utils.json_to_sheet(
          getDataForExport(report[channels[i]])
        )
        sheetNames.push(channels[i])
      }

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const wb = {
        Sheets: sheets,
        SheetNames: sheetNames,
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `SummaryOfTicketsByChannel${fileExtension}`)
    }
  }, [report])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDataForExport = (data?: any[]) => {
    return Array.isArray(data)
      ? data.map((item) => ({
          Channel: item.channel,
          'Ticket #': item.number,
          Vendor: item.vendor,
          Category: item.category,
          Status: item.status,
          'Date Opened': item.createdOn,
          Priority: item.priority,
          Agent: item.agent,
          Assignee: item.assignee,
        }))
      : []
  }

  const getTable = (dataName?: string) => {
    const data: SummaryOfTicketsByChannelRow[] = (
      report as {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any
      }
    )?.[dataName as string]

    return Array.isArray(data) && data.length ? (
      <>
        <S.ReportTitle>{dataName}</S.ReportTitle>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableRow>
                <S.TableHeadCell>Channel</S.TableHeadCell>
                <S.TableHeadCell>Ticket #</S.TableHeadCell>
                <S.TableHeadCell>Vendor</S.TableHeadCell>
                <S.TableHeadCell>Category</S.TableHeadCell>
                <S.TableHeadCell>Status</S.TableHeadCell>
                <S.TableHeadCell>Priority</S.TableHeadCell>
                <S.TableHeadCell>Date Opened</S.TableHeadCell>
                <S.TableHeadCell>Agent</S.TableHeadCell>
                <S.TableHeadCell>Assignee</S.TableHeadCell>
              </S.TableRow>
            </S.TableHead>
            <S.TableBody>
              {data.map((item: SummaryOfTicketsByChannelRow, index: number) => (
                <S.TableRow key={`row${index}`}>
                  <S.TableCell>{item.channel}</S.TableCell>
                  <S.TableCell>{item.number}</S.TableCell>
                  <S.TableCell>{item.vendor}</S.TableCell>
                  <S.TableCell>{item.category}</S.TableCell>
                  <S.TableCell>{item.status}</S.TableCell>
                  <S.TableCell>{item.priority}</S.TableCell>
                  <S.TableCell>
                    {formatDateToUTC(item.createdOn as string)}
                  </S.TableCell>
                  <S.TableCell>{item.agent}</S.TableCell>
                  <S.TableCell>{item.assignee}</S.TableCell>
                </S.TableRow>
              ))}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </>
    ) : undefined
  }

  if (!report) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Summary of tickets by channel
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {channel?.toUpperCase()}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.ReportTitle>Summary</S.ReportTitle>
      <S.SummaryList>
        {Array.isArray(channels) && channels.length
          ? channels.map((item, index) => (
              <S.SummaryListItem key={index}>
                <S.SummaryListItemTitle>{item}</S.SummaryListItemTitle>
                <S.SummaryListItemDivider />
                <S.SummaryListItemText>{`Open: ${
                  report?.[item]?.filter(
                    (item) =>
                      item.status.toString() !== statusClosed &&
                      item.status.toString() !== statusDeleted
                  ).length
                }`}</S.SummaryListItemText>
                <S.SummaryListItemText>{`Closed: ${
                  report?.[item]?.filter(
                    (item) => item.status.toString() === statusClosed
                  ).length
                }`}</S.SummaryListItemText>
                <S.SummaryListItemText>{`Deleted: ${
                  report?.[item]?.filter(
                    (item) => item.status.toString() === statusDeleted
                  ).length
                }`}</S.SummaryListItemText>
                <S.SummaryListItemText>{`Total: ${report?.[item]?.length}`}</S.SummaryListItemText>
              </S.SummaryListItem>
            ))
          : undefined}
      </S.SummaryList>
      {Array.isArray(channels) && channels.length
        ? channels.map((item) => getTable(item))
        : undefined}
    </S.Container>
  )
}

export default SummaryOfTicketsByChannel
