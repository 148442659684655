import React, { useEffect, useState, useCallback } from 'react'
import { ResourceCenterUtilizationRow } from '@monorepo/interfaces'
import { http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

interface ResourceCenterUtilizationProps {
  startDate: Date
  endDate: Date
}

const ResourceCenterUtilization: React.FC<ResourceCenterUtilizationProps> = ({
  startDate,
  endDate,
}) => {
  const [report, setReport] = useState<ResourceCenterUtilizationRow[] | null>(
    null
  )

  useEffect(() => {
    http
      .get<ResourceCenterUtilizationRow[]>({
        url: `/reports/resourceCenterUtilization?dateFrom=${format(
          startDate,
          'yyyy-MM-dd'
        )}&dateTo=${format(endDate, 'yyyy-MM-dd')}`,
      })
      .then(({ data }) => {
        setReport(data)
      })
  }, [endDate, startDate])

  const exportExcel = useCallback(async () => {
    if (report !== null) {
      const reportData = report.map((item) => ({
        Resource: item.title,
        Channel: item.channel,
        Count: item.count,
      }))
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const ws = XLSX.utils.json_to_sheet(reportData)
      const wb = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `ResourceCenterUtilization${fileExtension}`)
    }
  }, [report])

  if (!report || report === null) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Resource Center Utilization
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      {report.length > 0 && (
        <>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Resource</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Count</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.map(
                  (item: ResourceCenterUtilizationRow, index: number) => (
                    <S.TableRow key={`row${index}`}>
                      <S.TableCell>{item.title}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.count}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
            </S.Table>
          </S.TableContainer>
        </>
      )}
    </S.Container>
  )
}

export default ResourceCenterUtilization
