import React, { useEffect, useState, useCallback } from 'react'
import {
  SupportTicketLevelDetailsReport,
  SupportTicketLevelDetailsRow,
  TicketStatus,
  UserProfile,
  Vendor,
} from '@monorepo/interfaces'
import {
  TicketHelper,
  UserHelper,
  VendorHelper,
  formatDateToUTC,
  http,
} from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { populateTicketReportData } from '../helpers/ticket'

interface SupportTicketLevelDetailsProps {
  startDate: Date
  endDate: Date
  channel?: string
}

const SupportTicketLevelDetails: React.FC<SupportTicketLevelDetailsProps> = ({
  startDate,
  endDate,
  channel,
}) => {
  const [report, setReport] = useState<
    SupportTicketLevelDetailsReport | null | undefined
  >(undefined)
  const [channels, setChannels] = useState<string[] | null | undefined>(
    undefined
  )

  const statusClosed = TicketHelper.getTicketStatusName(TicketStatus.CLOSED)

  useEffect(() => {
    http
      .get<SupportTicketLevelDetailsReport>({
        url: `/reports/supportticketleveldetails?dateFrom=${format(
          startDate,
          'yyyy-MM-dd'
        )}&dateTo=${format(endDate, 'yyyy-MM-dd')}${
          !!channel ? `&channel=${channel}` : ''
        }`,
      })
      .then(({ data }) => {
        const { data: report, keys: channels } = populateTicketReportData(data)
        setChannels(channels)
        setReport(report)
      })
  }, [endDate, startDate])

  const exportExcel = useCallback(async () => {
    if (report && Array.isArray(channels)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sheets: { [key: string]: any } = {}
      const sheetNames = []

      for (let i = 0, l = channels.length; i < l; ++i) {
        sheets[channels[i]] = XLSX.utils.json_to_sheet(
          getDataForExport(report[channels[i]])
        )
        sheetNames.push(channels[i])
      }

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const wb = {
        Sheets: sheets,
        SheetNames: sheetNames,
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `SupportTicketLevelDetails${fileExtension}`)
    }
  }, [report])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDataForExport = (data?: any[]) => {
    return Array.isArray(data)
      ? data.map((item) => ({
          'Dealer email': item.dealer,
          'Dealer role': getUserRole(item.dealerInfo),
          'Master dealer ID': item.masterDealerId,
          Channel: item.channel,
          Vendor: item.vendor,
          Category: item.category,
          Status: item.status,
          Priority: item.priority,
          'Date Opened': item.createdOn,
          'Date Closed':
            item.status === statusClosed ? item.updatedOn : undefined,
          'Dealer Comments': item.messages?.[0]?.message,
        }))
      : []
  }

  const getUserRole = (user?: UserProfile) => {
    return UserHelper.isAdmin(user?.groups) ? 'Admin' : 'Member'
  }

  const getTable = (dataName?: string) => {
    const data: SupportTicketLevelDetailsRow[] = (
      report as {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any
      }
    )?.[dataName as string]

    return Array.isArray(data) && data.length ? (
      <>
        <S.ReportTitle>{dataName}</S.ReportTitle>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableRow>
                <S.TableHeadCell>Dealer email</S.TableHeadCell>
                <S.TableHeadCell>Dealer role</S.TableHeadCell>
                <S.TableHeadCell>Master dealer ID</S.TableHeadCell>
                <S.TableHeadCell>Channel</S.TableHeadCell>
                <S.TableHeadCell>Vendor</S.TableHeadCell>
                <S.TableHeadCell>Category</S.TableHeadCell>
                <S.TableHeadCell>Status</S.TableHeadCell>
                <S.TableHeadCell>Priority</S.TableHeadCell>
                <S.TableHeadCell>Date Opened</S.TableHeadCell>
                <S.TableHeadCell>Date Closed</S.TableHeadCell>
                <S.TableHeadCell>Dealer Comments</S.TableHeadCell>
              </S.TableRow>
            </S.TableHead>
            <S.TableBody>
              {data.map((item: SupportTicketLevelDetailsRow, index: number) => (
                <S.TableRow key={`row${index}`}>
                  <S.TableCell>{item.dealer}</S.TableCell>
                  <S.TableCell>{getUserRole(item.dealerInfo)}</S.TableCell>
                  <S.TableCell>{item.masterDealerId}</S.TableCell>
                  <S.TableCell>{item.channel}</S.TableCell>
                  <S.TableCell>{item.vendor}</S.TableCell>
                  <S.TableCell>{item.category}</S.TableCell>
                  <S.TableCell>{item.status}</S.TableCell>
                  <S.TableCell>{item.priority}</S.TableCell>
                  <S.TableCell>
                    {formatDateToUTC(item.createdOn as string)}
                  </S.TableCell>
                  <S.TableCell>
                    {item.status.toString() === statusClosed
                      ? formatDateToUTC(item.updatedOn as string)
                      : ''}
                  </S.TableCell>
                  <S.TableCell>{item.messages?.[0]?.message}</S.TableCell>
                </S.TableRow>
              ))}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </>
    ) : undefined
  }

  const getSummaryListItemText = (
    channel?: string,
    vendor?: string,
    categoryOption?: { value: string; label: string },
    index?: number
  ) => {
    const data: SupportTicketLevelDetailsRow[] = (
      report as {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any
      }
    )?.[channel as string]

    return Array.isArray(data) && data.length ? (
      <S.SummaryListItemText key={`${channel}-${vendor}-${index}`}>{`${
        categoryOption?.label
      }: ${
        data.filter(
          (ticket) =>
            ticket.vendor === vendor &&
            ticket.category === categoryOption?.label
        ).length
      }`}</S.SummaryListItemText>
    ) : undefined
  }

  if (!report) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Support ticket level details
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {channel?.toUpperCase()}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.ReportTitle>Summary</S.ReportTitle>
      <S.SummaryList>
        {Array.isArray(channels) && channels.length
          ? channels.map((channel, index) => (
              <S.SummaryListItem key={`${channel}-${index}`}>
                <S.SummaryListItemTitle>{channel}</S.SummaryListItemTitle>
                <S.SummaryListItemDivider />
                {TicketHelper.getTicketCategoryProductOptions().map(
                  (categoryProductOption, index) =>
                    getSummaryListItemText(
                      channel,
                      VendorHelper.getVendorName(Vendor.PURE),
                      categoryProductOption,
                      index
                    )
                )}
                {TicketHelper.getTicketCategoryEventOptions().map(
                  (categoryEventOption, index) =>
                    getSummaryListItemText(
                      channel,
                      VendorHelper.getVendorName(Vendor.DSI),
                      categoryEventOption,
                      index
                    )
                )}
                <S.SummaryListItemText>{`Total: ${report?.[channel]?.length}`}</S.SummaryListItemText>
              </S.SummaryListItem>
            ))
          : undefined}
      </S.SummaryList>
      {Array.isArray(channels) && channels.length
        ? channels.map((item) => getTable(item))
        : undefined}
    </S.Container>
  )
}

export default SupportTicketLevelDetails
