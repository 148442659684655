//import { DealerChannel } from '@monorepo/interfaces'
import * as Yup from 'yup'

export const AssetSchema = Yup.object().shape({
    assetType: Yup.number().nullable().optional(),
    description: Yup.string().nullable().optional(),
    active: Yup.boolean().optional(),
})

export const initialValues = {
    assetType: undefined,
    description: undefined,
    active: undefined
}
