import React, { useCallback, useEffect, useState } from 'react'
import { ProductCatalogRow, ProductType } from '@monorepo/interfaces'
import { EventHelper, http, ProductHelper } from '@monorepo/infra'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

interface ProductCatalogProps {
  channel: string
  product: ProductType | null
}

const ProductCatalog: React.FC<ProductCatalogProps> = ({
  channel,
  product,
}) => {
  const [report, setReport] = useState<ProductCatalogRow[] | null>(null)
  useEffect(() => {
    http
      .get<ProductCatalogRow[]>({
        url: `/reports/productCatalog?channel=${channel}&productType=${product}`,
      })
      .then(({ data }) => setReport(data))
  }, [channel, product])
  const exportExcel = useCallback(async () => {
    if (report !== null) {
      let reportData
      if (product === ProductType.EVENT) {
        reportData = report.map((reportRow) => {
          return {
            Title: reportRow.title,
            Description: reportRow.description,
            SKU: reportRow.sku,
            Categories: reportRow.categories.join(';'),
            Channels: reportRow.channels.join(';'),
          }
        })
      } else {
        reportData = report.map((reportRow) => {
          return {
            Title: reportRow.title,
            Description: reportRow.description,
            SKU: reportRow.sku,
            Sizes: reportRow.sizes.join(';'),
            Categories: reportRow.categories.join(';'),
            Channels: reportRow.channels.join(';'),
            Options: reportRow.options.join(';'),
          }
        })
      }
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const ws = XLSX.utils.json_to_sheet(reportData)
      const wb = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `ProductCatalog${fileExtension}`)
    }
  }, [report, product])
  if (!report || report === null) {
    return <PageSpinner />
  }
  const getSummaryTitle = (product: ProductType | null): string => {
    if (product === null) return 'Unknown'
    switch (product) {
      case ProductType.CREATIVE:
        return 'Ad Materials'
      case ProductType.CREATIVE_CUSTOM:
        return 'Media'
      case ProductType.EVENT:
        return 'Events'
      case ProductType.MICROSITE:
        return 'Microsites'
      default:
        return 'Unknown'
    }
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Product Catalog
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>{channel.toUpperCase()}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Product</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {getSummaryTitle(product)}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      {report.length > 0 && (
        <>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Product Image</S.TableHeadCell>
                  <S.TableHeadCell>Product and Description</S.TableHeadCell>
                  <S.TableHeadCell>Sizes Available</S.TableHeadCell>
                  <S.TableHeadCell>Category</S.TableHeadCell>
                  <S.TableHeadCell>Channels</S.TableHeadCell>
                  <S.TableHeadCell>Available Options</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.map((item: ProductCatalogRow, index: number) => (
                  <S.TableRow key={`products${index}`}>
                    <S.TableCell>
                      <S.TableImageContainer>
                        <S.TableImage
                          src={
                            product === ProductType.EVENT
                              ? EventHelper.getImageFile(item.image)
                              : item.image
                          }
                        />
                      </S.TableImageContainer>
                    </S.TableCell>
                    <S.TableCell>
                      <S.ProductTitle>
                        {item.title.replace(/&nbsp;/g, '')}
                      </S.ProductTitle>
                      <S.ProductDescription>
                        {item.description.replace(/&nbsp;/g, '')}
                      </S.ProductDescription>
                      <S.ProductDescription>{`SKU: ${item.sku}`}</S.ProductDescription>
                    </S.TableCell>
                    <S.TableCell>
                      {Array.isArray(item.sizes)
                        ? item.sizes.map((size) => (
                            <p>{ProductHelper.getTitleFromSize(size)}</p>
                          ))
                        : item.sizes}
                    </S.TableCell>
                    <S.TableCell>
                      {Array.isArray(item.categories)
                        ? item.categories.map((category) => <p>{category}</p>)
                        : item.categories}
                    </S.TableCell>
                    <S.TableCell>
                      {Array.isArray(item.channels)
                        ? item.channels.map((channel) => <p>{channel}</p>)
                        : item.channels}
                    </S.TableCell>
                    <S.TableCell>
                      {Array.isArray(item.options)
                        ? item.options.map((option) => (
                            <p>{ProductHelper.getDeliveryMethod(option)}</p>
                          ))
                        : item.options}
                    </S.TableCell>
                  </S.TableRow>
                ))}
              </S.TableBody>
            </S.Table>
          </S.TableContainer>
        </>
      )}
    </S.Container>
  )
}

export default ProductCatalog
