import styled from 'styled-components'
import { InputBase as MuiInputBase } from '@material-ui/core'
import { colors } from '@monorepo/theme'

import {
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Button as MuiButton,
} from '@material-ui/core'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const InputBase = styled(MuiInputBase)`
  width: 20%;
  padding: 3px 10px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 2px;

  .MuiInputBase-input::placeholder {
    opacity: 1;
    font-size: 0.85em;
  }
`

export const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
    border-bottom: 2px solid gray;
  }
`

export const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding: 0 5px 0 5px;
    border-bottom: 2px solid gray;
  }
`

export const Button = styled(MuiButton).attrs({ disableRipple: true })`
  &.MuiButton-root {
    border: 2px solid ${colors.lightGrey};
  }

  .MuiButton-label {
    color: ${colors.lightGrey};
    font-size: 0.9em;
    font-family: 'ATTAleckSansBold';
    text-transform: none;
  }
`

export const TableRow = styled(MuiTableRow)`
  cursor: pointer;
`

export const TbodyRowLoading = styled(TableRow)`
  height: 50px;
  position: relative;
`

export const TBodyCellLoading = styled(MuiTableCell)`
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: none;

  &.MuiTableCell-root {
    padding: 0;
    display: flex;
  }
`
