import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { NavLink } from 'react-router-dom'
import { Search as BaseSearch, SearchProps } from '@monorepo/components'

export const HeaderRightSide = styled.div`
  width: 525px;
  display: flex;
  align-items: center;
`

export const Search = styled(BaseSearch).attrs({
  backgroundColor: colors.white,
  width: 250,
  height: 40,
})<SearchProps>``

export const IconLinkContainer = styled.div`
  margin: 0 0 0 auto;
`

export const IconLink = styled(NavLink)`
  margin: 0 9px;
`

export const UserContainer = styled.figure`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`

interface UserNameProps {
  color?: string
}

export const UserName = styled.figcaption<UserNameProps>`
  color: ${(props) => props.color || colors.white};
  font-family: PFDINText;
  margin: 0 12px 0 auto;
  font-size: 14px;
  max-width: 95px;
  text-align: right;
  width: 95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`

export const UserProfilePhoto = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-left: auto;
`

export const UserProfileBadge = styled.span`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-left: auto;
  font-family: Jubilat;
  font-size: 22px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
`

export const UserProfileOptions = styled.ul`
  position: absolute;
  top: 55px;
  right: 0;
  width: 100%;
  background-color: ${colors.white};
  color: ${colors.black};
  z-index: 1;
`

export const UserProfileOption = styled.li`
  height: 50px;
`

export const UserProfileLink = styled(NavLink)`
  font-size: 14px;
  display: flex;
  font-family: PFDINText;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-left: 15px;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: ${colors.lightGrey};
  }
`
