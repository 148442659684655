import { DealerChannel } from '@monorepo/interfaces'
import * as Yup from 'yup'

export const PopUpSchema = Yup.object().shape({
  headline: Yup.string().nullable().optional(),
  text: Yup.string().nullable().optional(),
  backgroundImage: Yup.string().nullable().optional(),
  ctaText: Yup.string().nullable().optional(),
  ctaResource: Yup.string().nullable().optional(),
  status: Yup.number().nullable().optional(),
  channel: Yup.string().required('Channel is required'),
  activationDate: Yup.date().nullable().optional(),
  expirationDate: Yup.date().nullable().optional(),
})

export const initialValues = {
  headline: undefined,
  text: undefined,
  backgroundImage: undefined,
  ctaText: undefined,
  ctaResource: undefined,
  status: 1,
  channel: DealerChannel.LSP,
  activationDate: undefined,
  expirationDate: undefined,
}
