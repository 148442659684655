import { useState, useEffect } from 'react'
import {
  Notification,
  Pagination as PaginationType,
} from '@monorepo/interfaces'
import {
  SectionNotifications,
  SectionNotificationsList,
  Pagination,
} from '../../../components'
import * as S from './styles'
import {
  useCrud,
  usePaginationSearchParams,
  useQueryParams,
  NotificationHelper,
  http,
} from '@monorepo/infra'
import useTextTranslation from '@monorepo/infra/build/locales/i18next/useLocation'
import { SearchTerm } from '../../molecules'
import { PaginationDetails } from '../../atoms'
import { SelectableNotification } from '../../organisms/SectionNotificationsList'

type NotificationsFetch = PaginationType & {
  data: Notification[]
}

const Notifications = (): JSX.Element => {
  const notificationBaseURL = '/notification'
  const pageURLKey = 'p'
  const searchURLKey = 's'

  const queryParams = useQueryParams()

  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(searchURLKey) ?? ''
  )
  const [selectedNotificationIds, setSelectedNotificationIds] = useState<
    string[]
  >([])
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)

  const { t } = useTextTranslation('admin')

  const { apiSearchParams, handlePageChange, handlePrevPage, handleNextPage } =
    usePaginationSearchParams({
      pageAPIKey: 'pageNum',
      pageURLKey,
      searchAPIKey: 'search',
      searchURLKey,
      searchTerm,
    })

  const apiURL = `${notificationBaseURL}?${apiSearchParams}${
    !!apiSearchParams ? '&' : ''
  }startDate=${NotificationHelper.getStartDate().toISOString()}&search=created`

  const { fetch } = useCrud<Notification>(apiURL, '_id', {
    revalidateOnFocus: false,
  })

  const { data, loading } = fetch
  const { data: notifications, ...pagination } = data as NotificationsFetch
  pagination.page = Number(pagination.page)
  const [savedNotifications, setSavedNotifications] = useState<Notification[]>(
    []
  )

  const handleClearSearchTerm = () => setSearchTerm('')

  useEffect(() => {
    if (Array.isArray(notifications)) {
      setSavedNotifications(
        getSelectedNotifications(notifications, selectedNotificationIds)
      )
    }
  }, [notifications])

  const selectAll = (selected: boolean) => {
    if (!selected) {
      return
    }

    const tempSelectedNotificaionIds = savedNotifications.reduce(
      (acc: string[], item) => {
        acc.push(item._id as string)
        return acc
      },
      []
    )
    setSelectedNotificationIds(tempSelectedNotificaionIds)

    setSavedNotifications(
      getSelectedNotifications(savedNotifications, tempSelectedNotificaionIds)
    )

    setIsSelectAll(true)
  }

  const selectOne = (id: string, selected: boolean) => {
    const tempSelectedNotificaionIds = [...selectedNotificationIds]
    const idx = tempSelectedNotificaionIds.indexOf(id)
    if (selected) {
      if (idx === -1) {
        tempSelectedNotificaionIds.push(id)
      }
    } else {
      if (idx !== -1) {
        tempSelectedNotificaionIds.splice(idx, 1)
      }
    }
    setSelectedNotificationIds(tempSelectedNotificaionIds)

    const tempSelectedNotificaions = getSelectedNotifications(
      savedNotifications,
      tempSelectedNotificaionIds
    )
    setSavedNotifications(tempSelectedNotificaions)

    setIsSelectAll(tempSelectedNotificaions.every((item) => item.selected))
  }

  const getSelectedNotifications = (
    notifications: Notification[],
    selectedNotificationIds: string[]
  ): SelectableNotification[] => {
    return notifications.map((item) => ({
      ...item,
      selected: selectedNotificationIds.indexOf(item._id as string) !== -1,
    }))
  }

  const handleArchive = () => {
    const requests = []
    for (let i = 0, l = selectedNotificationIds.length; i < l; ++i) {
      requests.push(
        http.patch<Notification>({
          url: `/notification/archive/${selectedNotificationIds[i]}`,
        })
      )
    }

    if (!requests.length) {
      return
    }

    Promise.all(requests)
      .then(() => {
        setSavedNotifications(
          getSelectedNotifications(
            savedNotifications.filter(
              (item) =>
                selectedNotificationIds.indexOf(item._id as string) === -1
            ),
            []
          )
        )

        setSelectedNotificationIds([])
        setIsSelectAll(false)
      })
      .catch((error) => console.log(error))
  }

  return (
    <S.Main>
      <SectionNotifications />
      <S.SearchFiltersContainer>
        <S.Search
          placeholder={t('section_notifications_list.input_placeholder')}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
        <SearchTerm searchTerm={searchTerm} onClear={handleClearSearchTerm} />
        <PaginationDetails
          totalItems={pagination.totalCount}
          totalPages={pagination.totalPages}
          itemsPerPage={pagination.pageSize}
          pageNumber={pagination.page}
          onClickPrev={handlePrevPage}
          onClickNext={handleNextPage}
        />
      </S.SearchFiltersContainer>
      <S.SelectAllContainer>
        <input
          type="checkbox"
          checked={isSelectAll}
          onChange={(e) => selectAll(e.target.checked)}
        />
        <S.SelectAllLabel>Select all</S.SelectAllLabel>
        <S.SelectAllButton onClick={handleArchive}>Archive</S.SelectAllButton>
      </S.SelectAllContainer>
      <SectionNotificationsList
        loading={loading}
        notifications={savedNotifications}
        selectable={true}
        selectOne={selectOne}
      />
      <S.PaginationContainer>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={pagination.totalCount}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalPages={pagination.totalPages}
        />
      </S.PaginationContainer>
    </S.Main>
  )
}

export default Notifications
