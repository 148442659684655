import styled from 'styled-components'
import { colors } from '@monorepo/theme'

export const TimelineContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  margin-top: 24px;
`

export const TimelineList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #b7bec6;
    top: 0;
    bottom: 0;
    left: 0;
  }
`

export const TimelineListItem = styled.li`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: 21px;
`

export const TimelineDescription = styled.div`
  flex: 6;
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
`

export const TimelineDescriptionText = styled.p`
  font-family: PFDINText;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  line-height: 30px;

  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
    background-color: #337ace;
    border: 4px solid ${colors.white};
    top: 15px;
    border-radius: 50%;
    margin-left: -11px;
    z-index: 1;
  }
`

export const TimelineDate = styled.span`
  font-family: PFDINText;
  font-size: 14px;
  font-weight: 400;
  color: #707d8d;
`

export const TimelineTime = styled.p`
  flex: 1;
  font-family: PFDINText;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  justify-self: flex-end;
  align-self: center;
  text-align: right;
  line-height: 30px;
  color: #707d8d;
  white-space: nowrap;
`
