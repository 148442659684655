import * as S from './styles'

import { Event, EventPropsData } from '@monorepo/interfaces'

import { EventHelper } from '@monorepo/infra'
import { Spinner } from '@monorepo/components'
import { TableBody } from '@material-ui/core'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export interface SectionEventsListProps extends EventPropsData {
  loading: boolean
}

const SectionEventsList: React.FC<SectionEventsListProps> = ({
  events,
  loading = true,
}) => {
  const { t } = useTranslation('admin')
  const history = useHistory()

  const getStatus = (status: number): string => {
    switch (status) {
      case 1:
        return 'Active'
      case 2:
        return 'Draft'
      case 3:
        return 'Archived'
      case 4:
        return 'Booked'
    }
    return 'Unknown'
  }

  const handleEditEvent = (index: number) => {
    const id = events[index]._id
    history.push(`/events/edit/${id}`)
  }

  return (
    <S.Container>
      <S.TableContainer>
        <S.Table aria-label="Events Table">
          <S.TableHead>
            <S.TableRow>
              <S.TableHeadCell>
                {t('section_events_list.table_title_1')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_2')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_3')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_4')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_5')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_6')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_7')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_events_list.table_title_8')}
              </S.TableHeadCell>
            </S.TableRow>
          </S.TableHead>
          {
            <TableBody>
              {loading ? (
                <S.TbodyRowLoading>
                  <S.TBodyCellLoading scope="row">
                    <Spinner spinnerSize={8} />
                  </S.TBodyCellLoading>
                </S.TbodyRowLoading>
              ) : (
                events?.map((event: Event, index: number) => (
                  <S.TbodyRow
                    key={index}
                    onClick={() => handleEditEvent(index)}
                  >
                    <S.TBodyCell scope="row">
                      <EventImage event={event} />
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">
                      <S.EventTitle>
                        <span className="title">{event.title}</span>
                        <span className="details">{`${event.city}, ${event.state} ${event.zipCode}`}</span>
                      </S.EventTitle>
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">{event.categories[0]}</S.TBodyCell>
                    <S.TBodyCell scope="row">{`$${event.price}`}</S.TBodyCell>
                    <S.TBodyCell scope="row">
                      {event.activationDate
                        ? format(new Date(event.activationDate), 'MM/dd/yyyy')
                        : 'N/D'}
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">
                      {event.expirationDate
                        ? format(new Date(event.expirationDate), 'MM/dd/yyyy')
                        : 'N/D'}
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">{event.tags}</S.TBodyCell>
                    <S.TBodyCell>
                      <S.TagsContainer>
                        {!event.expirationDate ||
                        new Date(event?.expirationDate) > new Date() ? (
                          <S.StatusTag data-status={event.status}>
                            {getStatus(event.status)}
                          </S.StatusTag>
                        ) : null}
                        {event.expirationDate &&
                        new Date(event?.expirationDate) < new Date() ? (
                          <S.StatusTag data-status={5}>Expired</S.StatusTag>
                        ) : null}
                      </S.TagsContainer>
                    </S.TBodyCell>
                  </S.TbodyRow>
                ))
              )}
            </TableBody>
          }
        </S.Table>
      </S.TableContainer>
    </S.Container>
  )
}

interface EventImageProps {
  event: Event
}

function EventImage({ event }: EventImageProps) {
  const image = EventHelper.getImageFile(EventHelper.getEventTypeDescription(event.type))
  return (
    <S.EventImage
      src={image}
      alt={event.title}
      style={{ maxWidth: '136px', maxHeight: '72px' }}
    />
  )
}

export default SectionEventsList
