import React, { useEffect, useState } from 'react'
import { Tab, TabList, Tabs } from './styles'

import { useHistory } from 'react-router-dom'
import { useQueryParams } from '@monorepo/infra'

interface TabsListProps {
  tabKey: string
  tabs: {
    description: string
    tabName: string
  }[]
}

const TabsList: React.FC<TabsListProps> = ({ tabKey, tabs }) => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const handleChangeTab = (tabIndex: number) => {
    const selectedTab = tabs[tabIndex]

    if (!selectedTab) return

    setCurrentTabIndex(tabIndex)

    history.push(`?${tabKey}=${selectedTab.tabName}`)
  }

  useEffect(() => {
    const searchOriginal = queryParams.toString()
    const hasSearchParam = queryParams.has(tabKey)

    if (!hasSearchParam) {
      setCurrentTabIndex(0)

      history.push({
        search: `${!!searchOriginal ? `${searchOriginal}&` : ''}${tabKey}=${
          tabs?.slice(0, 1)?.shift()?.tabName
        }`,
      })
    } else {
      const tabName = queryParams.get(tabKey)
      const tmpIndex = tabs.findIndex((item) => item.tabName === tabName)

      if (tmpIndex !== -1 && tmpIndex !== currentTabIndex) {
        setCurrentTabIndex(tmpIndex)
      }
    }
  }, [history, queryParams, tabKey, tabs])

  return (
    <Tabs onChange={(index) => handleChangeTab(index)} index={currentTabIndex}>
      <TabList>
        {tabs.map(({ description, tabName }, index) => (
          <Tab key={tabName} index={index} tabIndex={index}>
            {description}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  )
}

export default TabsList
