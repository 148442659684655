import React from 'react'
import { Text } from '@monorepo/components'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const SectionNotifications: React.FC = () => {
  const { t } = useTranslation('admin')

  return (
    <S.Container>
      <Text size="30px" family="ATTAleckSansBold">
        {t('section_notifications.title')}
      </Text>
    </S.Container>
  )
}

export default SectionNotifications
