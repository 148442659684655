import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const ManagePageContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.softGrey};
  height: calc(100vh - 88px);
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  padding-top: 60px;
`
