import React from 'react'
import { RoleData } from '@monorepo/interfaces'
import { SectionRoles, SectionRolesList } from '../../organisms'
import * as S from './styles'

const Roles: React.FC<RoleData> = ({ roles }) => {
  return (
    <S.Main>
      <SectionRoles />
      <SectionRolesList roles={roles} />
    </S.Main>
  )
}

export default Roles
