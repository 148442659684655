import { Input } from '@monorepo/components'
import { Field, Formik, setNestedObjectValues } from 'formik'

import * as Yup from 'yup'
import * as S from './styles'
import { TrackingInformation } from '@monorepo/interfaces'
import { isFunction } from '@monorepo/infra'

export interface AddTrackingProps {
  onAddTracking: (trackingInformation: TrackingInformation) => void
}

const TrackingSchema = Yup.object().shape({
  carrier: Yup.string().required('Carrier is required'),
  tracking: Yup.string().required('Tracking is required'),
})

const initialValues = {
  carrier: '',
  tracking: '',
}

const AddTracking: React.FC<AddTrackingProps> = ({ onAddTracking }) => {
  return (
    <S.Container>
      <S.TitleWrapper>
        <S.Title>Tracking information</S.Title>
      </S.TitleWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={TrackingSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values, { validateForm }) => {
          validateForm().then(() => {
            if (isFunction(onAddTracking)) {
              onAddTracking(values)
            }
          })
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
          validateForm,
          submitForm,
          setTouched,
        }) => (
          <S.DialogForm>
            <Field
              id="carrier"
              name="carrier"
              component={Input}
              value={values.carrier}
              invalid={touched.carrier && errors.carrier}
              invalidMessage={errors.carrier}
              label="Carrier"
              onBlur={() => {
                setFieldTouched('carrier')
              }}
              onChange={(option: { target: { value: string } }) => {
                setFieldValue('carrier', option.target.value)
              }}
            />
            <Field
              id="tracking"
              name="tracking"
              component={Input}
              value={values.tracking}
              invalid={touched.tracking && errors.tracking}
              invalidMessage={errors.tracking}
              label="Tracking"
              onBlur={() => {
                setFieldTouched('tracking')
              }}
              onChange={(option: { target: { value: string } }) => {
                setFieldValue('tracking', option.target.value)
              }}
            />
            <S.ButtonWrapper>
              <S.AddButton
                onClick={() => {
                  validateForm().then((errors) => {
                    setTouched(setNestedObjectValues(errors, true))
                  })
                  submitForm()
                }}
              >
                Add
              </S.AddButton>
            </S.ButtonWrapper>
          </S.DialogForm>
        )}
      </Formik>
    </S.Container>
  )
}

export default AddTracking
