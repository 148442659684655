import styled from 'styled-components'
import { Button as MuiButton } from '@material-ui/core'
import { colors } from '@monorepo/theme'
import { Form } from 'formik'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #e5e5e5;
  padding: 20px;
`

export const TitleWrapper = styled.div`
  padding: 20px;
  text-align: center;
`

export const Title = styled.h3`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 20px;
  text-transform: none;
  text-decoration: none;
  padding: 19px 30px;
  background-color: ${colors.white};
`

export const DialogForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ButtonWrapper = styled.div`
  text-align: right;
`

export const AddButton = styled(MuiButton)`
  padding: 10px;

  &.MuiButton-root {
    margin-top: 9px;
    height: 36px;
    border-radius: 0;
    border: 1px solid ${colors.blue};
    background-color: ${colors.blue};
    align-self: flex-end;
  }

  .MuiButton-label {
    color: ${colors.white};
    font-size: 14px;
    font-weight: 400;
    font-family: PFDINText;
    text-transform: none;
  }
`
