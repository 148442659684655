import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const ImportLink = styled(NavLink)`
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 66px;
`
