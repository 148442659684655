import styled from 'styled-components'
import { Button, Search as BaseSearch, SearchProps } from '@monorepo/components'
import { colors } from '@monorepo/theme'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const SearchTerm = styled.div`
  width: 100%;
  padding: 7px;
`
export const SearchFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Search = styled(BaseSearch).attrs({
  backgroundColor: colors.white,
  width: 325,
  height: 36,
})<SearchProps>``

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const PageActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 213px;
  height: 38px;
  flex: 1;
`

export const AddOrderButton = styled(Button)`
  padding: 8px 16px;
  width: 131px;
  height: 38px;
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`
