import { Formik, setNestedObjectValues } from 'formik'
import Select from 'react-select'
import React, { useState } from 'react'
import * as S from './styles'
import * as Yup from 'yup'
import { RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { DealerHelper } from '@monorepo/infra'

export interface IUserForm {
  firstName?: string
  lastName?: string
  email?: string
  userId: string
  dealerTypes?: string[]
  enabled?: boolean
}

const UserSchema = Yup.object().shape({
  dealerTypes: Yup.array().of(Yup.string()).optional(),
  enabled: Yup.boolean().optional(),
})

type UserFormProps = {
  close: () => void
  actionForm: (data: IUserForm) => void
  initialValues?: IUserForm
}

const UserForm: React.FC<UserFormProps> = ({
  initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    userId: '',
    dealerTypes: [],
    enabled: false,
  },
  actionForm,
  close,
}) => {
  const getInitialDealerTypes = (
    initialDealerTypes?: string[]
  ): { value: string; label: string }[] => {
    return DealerHelper.dealerTypes.filter(
      (item) =>
        Array.isArray(initialDealerTypes) &&
        initialDealerTypes?.findIndex((dt) => dt === item.value) !== -1
    )
  }

  const activeStr = 'ACTIVE'
  const inactiveStr = 'INACTIVE'
  const getDropdownButtonText = (enabled?: boolean) =>
    enabled ? activeStr : inactiveStr

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownButtonText, setDropdownButtonText] = useState(
    getDropdownButtonText(initialValues.enabled)
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserSchema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false)
        actionForm(values)
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        validateForm,
        submitForm,
        setTouched,
      }) => (
        <S.DialogForm>
          <S.Header>
            <S.Title>
              <S.TitleText>Edit User</S.TitleText>
            </S.Title>
            <S.Dropdown>
              <S.DropdownButton onClick={() => setDropdownOpen(!dropdownOpen)}>
                <S.DropdownButtonText data-open={dropdownOpen}>
                  {dropdownButtonText}
                </S.DropdownButtonText>
              </S.DropdownButton>
              <S.DropdownContent data-open={dropdownOpen}>
                <RadioGroup
                  name="enabled"
                  value={values.enabled}
                  onBlur={() => setFieldTouched('enabled')}
                  onChange={(e, value) => {
                    setFieldValue('enabled', value === 'true')
                    setDropdownButtonText(
                      getDropdownButtonText(value === 'true')
                    )
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio size="small" />}
                    label={activeStr}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio size="small" />}
                    label={inactiveStr}
                  />
                </RadioGroup>
              </S.DropdownContent>
            </S.Dropdown>
          </S.Header>
          <Select
            name="dealerTypes"
            placeholder="Dealer type(s)"
            isMulti
            options={DealerHelper.dealerTypes}
            value={getInitialDealerTypes(values.dealerTypes)}
            onBlur={() => setFieldTouched('dealerTypes')}
            onChange={(newValue) =>
              setFieldValue(
                'dealerTypes',
                newValue?.map((item) => item.value)
              )
            }
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={S.MultiSelectStyle}
          />

          <S.DialogActions>
            <S.ModalButton
              colorOption="stroke"
              label="CANCEL"
              onClick={() => close()}
            />
            <S.ModalButton
              colorOption="black"
              label="SAVE"
              onClick={() => {
                validateForm().then((errors) => {
                  setTouched(setNestedObjectValues(errors, true))
                })
                submitForm()
              }}
            />
          </S.DialogActions>
        </S.DialogForm>
      )}
    </Formik>
  )
}

export { UserForm }
