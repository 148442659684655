import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import '@reach/tabs/styles.css'

import {
  Tabs as ReachTabs,
  TabList as ReachTabList,
  Tab as ReachTab,
} from '@reach/tabs'

export const Tabs = styled(ReachTabs)``

export const TabList = styled(ReachTabList)`
  background-color: transparent;
`

export const Tab = styled(ReachTab)`
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  font-weight: 400;
  height: 54px;
  padding: 0;
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-reach-tab][data-selected] {
    box-shadow: inset 0px -4px 0px ${colors.blue};
  }
`
