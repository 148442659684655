import styled from 'styled-components'
import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers'
import { Button } from '@monorepo/components'
import { colors } from '@monorepo/theme'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const RowContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

export const SectionTitle = styled.h1`
  font-family: PFDINText;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`

export const OneHalf = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const StartEndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const EventDateLabel = styled.p`
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 10px;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
`

export const KeyboardDatePicker = styled(MuiKeyboardDatePicker)`
  width: 132px;

  .MuiInputBase-root {
    height: 47px;
    border: ${({ error }) =>
      error ? '2px solid #E33610' : `0.3px solid ${colors.grey}`};
    border-radius: 6px;
    padding-right: 4px;
  }

  & .MuiInputBase-input {
    height: 47px;
    padding-left: 10px;

    & ~ fieldset.MuiOutlinedInput-notchedOutline {
      display: none;
    }

    & ~ fieldset.MuiOutlinedInput-notchedOutline > legend {
      display: none;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 6px;
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    transform: none;
    top: 16px;
    left: 10px;
    font-family: PFDINText;
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: normal;
    color: ${colors.mediumGrey};

    display: ${({ value }) => (value ? 'none' : 'block')};
  }

  .MuiButtonBase-root {
    width: 34px;
    height: 34px;
    padding: 0;
  }
`

export const DateRangeDelimiter = styled.hr`
  flex: 0 0 12px;
  height: 1px;
  margin: 36px -15px 0;
  border-top-color: black;
`

export const EventDateClearContainer = styled.div``

export const ClearButton = styled(Button)`
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 25px 0 auto; */
`

export const ActionButton = styled.button<{
  disabled?: boolean
  isSecondary?: boolean
}>`
  background-color: ${(props) =>
    props.isSecondary
      ? props.disabled
        ? '#cccccc'
        : '#000000'
      : props.disabled
      ? '#65a9fc'
      : '#337ace'};
  border: 1px solid
    ${(props) =>
      props.isSecondary
        ? props.disabled
          ? '#cccccc'
          : '#000000'
        : props.disabled
        ? '#65a9fc'
        : '#337ace'};
  color: #ffffff;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 16px;
  padding: 17px 30px 17px 30px;
`
