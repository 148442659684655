import axios from 'axios'

export const exportXls = (
  url: string,
  fileName: string,
  token?: string
): void => {
  const headers = !!token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : undefined

  axios({
    url: url,
    method: 'get',
    ...(headers && { headers: headers }),
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    })
    .catch((error) => {
      console.log(`${error.config.url} ${error.message}`)
    })
}
