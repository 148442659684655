import styled from 'styled-components'
import { Button } from '@monorepo/components'
import BaseSeparator from '../../atoms/Separator'
import { Search as BaseSearch, SearchProps } from '@monorepo/components'
import { colors } from '@monorepo/theme'
import { Dialog as ReachDialog } from '@reach/dialog'
import '@reach/dialog/styles.css'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SearchFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Search = styled(BaseSearch).attrs({
  backgroundColor: colors.white,
  width: 325,
  height: 36,
})<SearchProps>``

export const PageActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 396px;
  height: 38px;
  gap: 10px;
`

export const AddEventButton = styled(Button)`
  padding: 8px 16px;
  width: 131px;
  height: 38px;
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`

export const Separator = styled(BaseSeparator)`
  && {
    width: unset;
    margin: 0 -32px;
    margin-top: -21px;
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Dialog = styled(ReachDialog)`
  &[data-reach-dialog-content] {
    background-color: ${colors.white};
    position: relative;
    padding: 58px;
  }
`

export const CloseButton = styled.button`
  width: 26px;
  height: 26px;
  border: 0;
  position: absolute;
  top: 27px;
  right: 27px;
`

export const DialogTitle = styled.h2`
  font-family: PFDINText;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.black};
  padding-bottom: 21px;
  border-bottom: 1px solid #b7bec6;
`

export const DialogText = styled.p`
  padding: 1em 0;
`

export const DialogError = styled(DialogText)`
  color: ${colors.red};
`

export const DialogLink = styled.a`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  line-height: 18px;
  align-items: center;
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
`

export const CancelButton = styled(Button)`
  padding: 8px 16px;
  height: 38px;
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UploadButton = styled(Button)`
  padding: 8px 16px;
  height: 38px;
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 14px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`
