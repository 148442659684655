const options = [
  {
    label: 'View',
    value: 'view',
    checked: true,
  },
  {
    label: 'View/Edit',
    value: 'edit',
    checked: false,
  },
]

export const features = {
  admin: [
    {
      name: 'Channels',
      value: 'channel',
      checked: false,
      options: options,
    },
    {
      name: 'Discounts',
      value: 'duscount',
      checked: false,
      options: options,
    },
    {
      name: 'Inventories',
      value: 'inventory',
      checked: false,
      options: options,
    },
    {
      name: 'Orders',
      value: 'order',
      checked: false,
      options: options,
    },
    {
      name: 'Pricing',
      value: 'price',
      checked: false,
      options: options,
    },
    {
      name: 'Products',
      value: 'product',
      checked: false,
      options: options,
    },
    {
      name: 'Users',
      value: 'user',
      checked: false,
      options: options,
    },
    {
      name: 'Roles',
      value: 'role',
      checked: false,
      options: options,
    },
  ],
  storefront: [
    {
      name: 'Products X',
      value: 'px',
      checked: false,
      options: options,
    },
    {
      name: 'Products Y',
      value: 'py',
      checked: false,
      options: options,
    },
    {
      name: 'Events X',
      value: 'ex',
      checked: false,
      options: options,
    },
    {
      name: 'Events Y',
      value: 'ey',
      checked: false,
      options: options,
    },
  ],
}
