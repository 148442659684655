import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useCrud,
  usePaginationSearchParams,
  useQueryParams,
} from '@monorepo/infra'
import { Pagination as PaginationType } from '@monorepo/interfaces'
import { PopUp } from '@monorepo/interfaces'
import { DashboardBackButton, PaginationDetails } from '../../atoms'
import { SearchTerm } from '../../molecules'
import {
  Pagination,
  SectionPopUpsList,
  TabList,
  SectionUsers,
} from '../../../components'
import * as S from './styles'

type PopUpFetch = PaginationType & {
  data: PopUp[]
}

const PopUps = (): JSX.Element => {
  const popUpBaseURL = '/popup'
  const pageURLKey = 'p'
  const searchURLKey = 's'

  const queryParams = useQueryParams()

  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(searchURLKey) ?? ''
  )
  const history = useHistory()

  const { apiSearchParams, handlePageChange, handlePrevPage, handleNextPage } =
    usePaginationSearchParams({
      pageAPIKey: 'pageNum',
      pageURLKey,
      searchAPIKey: 'search',
      searchURLKey,
      searchTerm,
    })

  const popUpSearchParams = new URLSearchParams(apiSearchParams)

  if (popUpSearchParams.get('status') === 'all') {
    popUpSearchParams.delete('status')
  }

  const apiURL = `${popUpBaseURL}?${popUpSearchParams}`

  const { fetch } = useCrud<PopUp>(apiURL, '_id', {
    revalidateOnFocus: false,
  })

  const { data, loading } = fetch
  const { data: popUps, ...pagination } = data as PopUpFetch
  pagination.page = Number(pagination.page)

  const handleCreatePopUp = () => history.push('pop-ups/create')

  const handleClearSearchTerm = () => setSearchTerm('')

  return (
    <S.Main>
      <DashboardBackButton redirectURL="/admin" text="Back to Admin" />
      <SectionUsers title="Pop-Ups" />
      <S.PageActions>
        <TabList
          tabKey="status"
          tabs={[
            { description: 'All', tabName: 'all' },
            { description: 'Active', tabName: 'active' },
            { description: 'Draft', tabName: 'draft' },
            { description: 'Archived', tabName: 'archived' },
          ]}
        />
        <S.ActionButtonsContainer>
          <S.CreatePopUpButton
            label="Create Pop-Up"
            colorOption="blue"
            onClick={handleCreatePopUp}
          />
        </S.ActionButtonsContainer>
      </S.PageActions>
      <S.Separator />
      <S.SearchFiltersContainer>
        <S.Search
          placeholder="Search Pop-Ups"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
        <SearchTerm searchTerm={searchTerm} onClear={handleClearSearchTerm} />
        <PaginationDetails
          totalItems={pagination.totalCount}
          totalPages={pagination.totalPages}
          itemsPerPage={pagination.pageSize}
          pageNumber={pagination.page}
          onClickPrev={handlePrevPage}
          onClickNext={handleNextPage}
        />
      </S.SearchFiltersContainer>
      <SectionPopUpsList loading={loading} popUps={popUps} />
      <S.PaginationContainer>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={pagination.totalCount}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalPages={pagination.totalPages}
        />
      </S.PaginationContainer>
    </S.Main>
  )
}

export default PopUps
