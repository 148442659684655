import { useHistory } from 'react-router-dom'
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import { NotificationPropsData, Notification } from '@monorepo/interfaces'
import { NotificationType, isFunction } from '@monorepo/infra'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core'
import TimeAgo from 'react-timeago'
import { Spinner } from '@monorepo/components'

export interface SelectableNotification extends Notification {
  selected?: boolean
}

export interface SelectableNotificationPropsData extends NotificationPropsData {
  notifications: Notification[] | SelectableNotification[]
}

export interface SectionNotificationsListProps
  extends SelectableNotificationPropsData {
  loading: boolean
  selectable?: boolean
  selectOne?: (notificationId: string, selected: boolean) => void
}

const SectionNotificationsList: React.FC<SectionNotificationsListProps> = ({
  notifications,
  loading = true,
  selectable,
  selectOne,
}) => {
  const { t } = useTranslation('admin')
  const history = useHistory()

  const handleRowClick = (notification: Notification, target?: string) => {
    if (selectable && target === '[object HTMLInputElement]') {
      return
    }

    if (
      (notification?.type === NotificationType.ORDER ||
        notification?.type === NotificationType.TICKET) &&
      !!notification.relation
    ) {
      history.push(
        `${
          notification?.type === NotificationType.ORDER
            ? '/orders/view/'
            : '/tickets/edit/'
        }${notification.relation}`
      )
    }
  }

  return (
    <S.Container>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {selectable ? <TableCell width={20} /> : undefined}
              <TableCell>
                {t('section_notifications_list.table_title_1')}
              </TableCell>
              <TableCell>
                {t('section_notifications_list.table_title_2')}
              </TableCell>
              <TableCell>
                {t('section_notifications_list.table_title_3')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : (
              notifications?.map(
                (notification: Notification, index: number) => (
                  <S.TableRow
                    key={index}
                    onClick={(e) =>
                      handleRowClick(notification, e.target.toString())
                    }
                    data-clickable={
                      (notification?.type === NotificationType.ORDER ||
                        notification?.type === NotificationType.TICKET) &&
                      !!notification.relation
                    }
                  >
                    {selectable ? (
                      <TableCell component="th" scope="row" width={20}>
                        <input
                          type="checkbox"
                          key={notification._id}
                          checked={
                            (notification as SelectableNotification).selected
                          }
                          onChange={(e) => {
                            e.stopPropagation()

                            if (selectOne && isFunction(selectOne)) {
                              selectOne(
                                notification._id as string,
                                e.target.checked
                              )
                            }
                          }}
                        />
                      </TableCell>
                    ) : undefined}
                    <TableCell component="th" scope="row">
                      {
                        // TODO: Add read/unread marker
                        notification.from
                      }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {notification.subject}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TimeAgo date={notification.createdOn} minPeriod={60} />
                    </TableCell>
                  </S.TableRow>
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </S.Container>
  )
}

export default SectionNotificationsList
