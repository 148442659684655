import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { ActivityByProductRow, ProductType } from '@monorepo/interfaces'
import { http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

interface ActivityByProductProps {
  channel: string
  product: ProductType | null
  startDate: Date
  endDate: Date
}

const ActivityByProduct: React.FC<ActivityByProductProps> = ({
  channel,
  product,
  startDate,
  endDate,
}) => {
  const [report, setReport] = useState<ActivityByProductRow[] | null>(null)
  const formatter = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }, [])
  useEffect(() => {
    http
      .get<ActivityByProductRow[]>({
        url: `/reports/activityByProduct?channel=${channel}&productType=${product}&dateFrom=${format(
          startDate,
          'yyyy-MM-dd'
        )}&dateTo=${format(endDate, 'yyyy-MM-dd')}`,
      })
      .then(({ data }) => setReport(data))
  }, [channel, endDate, product, startDate])
  const exportExcel = useCallback(async () => {
    if (report !== null) {
      const reportData = report.map((reportRow) => {
        return {
          Name: reportRow.name,
          Type: reportRow.type,
          'Media Id': reportRow.mediaId,
          Channel: reportRow.channel,
          'Order Number': reportRow.number,
          Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
          'Dealer Id': reportRow.dealerId,
          'Team Member': reportRow.teamMember,
          Total: reportRow.total,
        }
      })
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const ws = XLSX.utils.json_to_sheet(reportData)
      const wb = {
        Sheets: {
          Sheet1: ws,
        },
        SheetNames: ['Sheet1'],
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `ActivityByProduct${fileExtension}`)
    }
  }, [report])
  if (!report || report === null) {
    return <PageSpinner />
  }
  const getSummaryTitle = (product: ProductType | null): string => {
    if (product === null) return 'Unknown'
    switch (product) {
      case ProductType.CREATIVE:
        return 'Ad Materials'
      case ProductType.CREATIVE_CUSTOM:
        return 'Media'
      case ProductType.EVENT:
        return 'Events'
      case ProductType.MICROSITE:
        return 'Microsites'
      default:
        return 'Unknown'
    }
  }
  const total = report
    .map((reportItem) => parseFloat(reportItem.total))
    .reduce((a, b) => a + b, 0)
  console.log(JSON.stringify(report))
  return (
    <S.Container>
      <S.ReportTitle>
        Activity by Product
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>{channel.toUpperCase()}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Product</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {getSummaryTitle(product)}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.ReportTitle>Summary</S.ReportTitle>
      <S.SummaryList>
        {product !== null && (
          <S.SummaryListItem>
            <S.SummaryListItemTitle>
              {getSummaryTitle(product)}
            </S.SummaryListItemTitle>
            <S.SummaryListItemDivider />
            <S.SummaryListItemText>{`Total Sales: ${formatter.format(
              total
            )}`}</S.SummaryListItemText>
            <S.SummaryListItemText>{`Total Events: ${report.length}`}</S.SummaryListItemText>
          </S.SummaryListItem>
        )}
      </S.SummaryList>
      {report.length > 0 && product !== null && (
        <>
          <S.ReportTitle>{getSummaryTitle(product)}</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  {/* <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell> */}
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.map((item: ActivityByProductRow, index: number) => (
                  <S.TableRow key={`events${index}`}>
                    <S.TableCell>{item.name}</S.TableCell>
                    <S.TableCell>{item.type}</S.TableCell>
                    <S.TableCell>{item.mediaId}</S.TableCell>
                    <S.TableCell>{item.channel}</S.TableCell>
                    <S.TableCell>{item.number}</S.TableCell>
                    <S.TableCell>
                      {format(new Date(item.date), 'MM/dd/yyyy')}
                    </S.TableCell>
                    <S.TableCell>{item.dealerId}</S.TableCell>
                    <S.TableCell>{item.teamMember}</S.TableCell>
                    {/* <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell> */}
                    <S.TableCell>
                      {formatter.format(parseFloat(item.total))}
                    </S.TableCell>
                  </S.TableRow>
                ))}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  {/* <S.TableFootCell>
                    {formatter.format(report.events.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.events.totalSpend)}
                  </S.TableFootCell> */}
                  <S.TableFootCell>{formatter.format(total)}</S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
        </>
      )}
    </S.Container>
  )
}

export default ActivityByProduct
