import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { Icon, Button } from '@monorepo/components'
import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableContainer as MuiTableContainer,
  TableBody as MuiTableBody,
  TableFooter as MuiTableFooter,
} from '@material-ui/core'

export const ReportTitle = styled.h2`
  font-family: Jubilat;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
  color: ${colors.darkBlue};
  text-transform: uppercase;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  gap: 25px;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const FilterList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 41px 0;
`

export const FilterListItem = styled.li`
  background-color: ${colors.delicateGrey};
  padding: 10px 9px;
  width: 20%;
  min-height: 55px;
  max-height; 55px;
  position: relative;
`

export const FilterListItemTitle = styled.h4`
  font-family: PFDINText;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: ${colors.black};
  text-transform: uppercase;
`

export const FilterListItemValue = styled.p`
  font-family: PFDINText;
  font-size: 10px;
  font-weight: 500;
  color: #8793a0;
  max-width: 70%;
`

export const StyledIcon = styled(Icon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`

export const SummaryList = styled(FilterList)``

export const SummaryListItem = styled(FilterListItem)`
  min-height: 184px;
  max-height: 184px;
  width: 20%;
`

export const SummaryListItemTitle = styled(FilterListItemTitle)``

export const SummaryListItemDivider = styled.hr`
  width: 100%;
  border: 0.2px solid #9fa8b3;
  margin: 10px 0 14px 0;
`

export const SummaryListItemText = styled.p`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 10px;
  color: ${colors.black};
`

export const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    overflow-y: hidden;
  }
`

export const Table = styled(MuiTable)`
  background-color: ${colors.white};
`

export const TableHead = styled(MuiTableHead)`
  position: relative;
`

export const TableRow = styled(MuiTableRow)`
  cursor: pointer;
  padding: 5px;
`

export const TableHeadCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    color: ${colors.slateGrey};
    font-family: PFDINText;
    font-size: 8px;
    text-transform: uppercase;
    height: 48px;
    padding: 0;
    text-align: left;
  }
`

export const TableBody = styled(MuiTableBody)``

export const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
    border-bottom: 2px solid gray;
  }
`

export const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding: 0;
    font-family: PFDINText;
    border-bottom: 0;
    font-size: 8px;
  }
`

export const TableFooter = styled(MuiTableFooter)``

export const TableFootCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    color: ${colors.black};
    font-family: PFDINText;
    font-weight: 500;
    font-size: 8px;
    text-align: left;
    padding: 0;
    border-bottom: 0;
    border-top: 0.5px solid ${colors.lightGrey};
  }
`

export const TableImageContainer = styled.div`
  width: 141px;
  height: 160px;
  display: flex;
  background-color: ${colors.lighterGrey};
  justify-content: center;
  align-items: center;
`

export const TableImage = styled.img`
  max-width: 75%;
  max-height: 75%;
  border: 0;
`

export const ProductTitle = styled.p`
  font-family: PFDINText;
  font-weight: 500;
  font-size: 10px;
  max-width: 350px;
  padding: 1rem;
`

export const ProductDescription = styled.p`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 8px;
  max-width: 350px;
  padding: 0 1rem;
`

export const PieChartList = styled.ul`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 50px;
  flex-wrap: wrap;
`

export const PieChartListItem = styled.li`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`

export const PieChartTitle = styled.h1`
  font-family: PFDINText;
  font-weight: 500;
  font-size: 12px;
  color: ${colors.black};
`

export const PieChartSubtitle = styled.h2`
  font-family: PFDINText;
  font-weight: 700;
  font-size: 18px;
  color: ${colors.black};
`

export const PieChartInfo = styled.p`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 10px;
  color: ${colors.black};
  text-align: center;
`

export const BarChartList = styled.ul`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 50px;
  flex-wrap: wrap;
`

export const BarChartListItem = styled.li`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px 0;
  height: 250px;
  min-width: 350px;
`

export const BarChartListItemBig = styled(BarChartListItem)`
  flex: 2;
  min-width: 700px;
`

export const BarChartTitle = styled.h1`
  font-family: PFDINText;
  font-weight: 500;
  font-size: 12px;
  color: ${colors.black};
`

export const BarChartSubtitle = styled.h2`
  font-family: PFDINText;
  font-weight: 700;
  font-size: 18px;
  color: ${colors.black};
`

export const ExcelButton = styled(Button)`
  padding: 8px 16px;
  text-transform: uppercase;
  font-family: PFDINText;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blue};
`
