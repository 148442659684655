import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Input = styled.input`
  border: 1px solid #58677a;
  border-radius: 6px;
`

export const ChipList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
`

export const Chip = styled.li`
  background-color: ${colors.grey};
  color: ${colors.white};
  font-size: 16px;
  margin: 0;
  padding: 2px 0;
  display: inline-flex;
  align-items: center;
  margin-right: 0.25em;
`

export const DeleteChip = styled.button`
  all: revert;
  background-color: ${colors.grey};
  color: ${colors.white};
  border: 0;
  font-size: 0.5em;
  display: inline-flex;
  align-items: center;
`
