import { http, EventHelper } from '@monorepo/infra'
import { AxiosResponse } from 'axios'
import * as Yup from 'yup'

import { productStatus } from '../../../../database'

export const EventSchema = Yup.object().shape({
  status: Yup.number().required('Status is required'),
  type: Yup.number().required('Type is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  mediaIdACB: Yup.string().required('Media ID is required'),
  tags: Yup.array().of(Yup.string()).optional(),
  categories: Yup.array().of(Yup.string()).optional(),
  sku: Yup.string()
    .required('SKU is required')
    .test(
      'checkDupSKU',
      'An item with this SKU already exists',
      function (value) {
        return new Promise((resolve, reject) => {
          if (this.parent._id && this.parent._id !== null) {
            resolve(true)
          }
          console.log(value)
          http
            .get<AxiosResponse>({
              url: `/event?sku=${value}`,
            })
            .then((response) => {
              if (response.data.data.length >= 1) resolve(false)
              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        })
      }
    ),
  startDate: Yup.date().nullable().required('Start date is required'),
  endDate: Yup.date().nullable().optional(),
  city: Yup.string().required('City is required'),
  state: Yup.string()
    .transform((currentValue) =>
      typeof currentValue === 'object' ? currentValue?.label : currentValue
    )
    .required('state is required'),
  zipCode: Yup.string().required('Zip Code is required'),
  activationDate: Yup.date().nullable().optional(),
  expirationDate: Yup.date().nullable().optional(),
  searchTerms: Yup.array().of(Yup.string()).optional(),
  channels: Yup.array().of(Yup.string()).optional(),
  dealerTypes: Yup.array().of(Yup.string()).optional(),
  notes: Yup.string().optional(),
  price: Yup.string().required('Price is required'),
  media: Yup.array().of(Yup.string()),
})

export const initialValues = {
  status: parseInt(productStatus[0]?.value),
  type: parseInt(EventHelper.getEventTypeOptions()[0]?.value),
  title: '',
  description: '',
  mediaIdACB: '',
  tags: [],
  categories: [],
  sku: '',
  startDate: null,
  endDate: null,
  city: '',
  state: '',
  zipCode: '',
  activationDate: null,
  expirationDate: null,
  searchTerms: [],
  channels: [],
  dealerTypes: [],
  notes: '',
  price: '',
  vendor: 2,
  media: [],
}
