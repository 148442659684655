import React, { useEffect, useState, useCallback } from 'react'
import {
  SummaryOfOpenClosedTicketsReport,
  SummaryOfOpenClosedTicketsRow,
} from '@monorepo/interfaces'
import { formatDateToUTC, http } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from '../styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { populateTicketReportData } from '../helpers/ticket'

interface SummaryOfOpenClosedTicketsProps {
  startDate: Date
  endDate: Date
  channel?: string
}

const SummaryOfOpenClosedTickets: React.FC<SummaryOfOpenClosedTicketsProps> = ({
  startDate,
  endDate,
  channel,
}) => {
  const [report, setReport] = useState<
    SummaryOfOpenClosedTicketsReport | null | undefined
  >(undefined)
  const [statuses, setStatuses] = useState<string[] | null | undefined>(
    undefined
  )

  useEffect(() => {
    http
      .get<SummaryOfOpenClosedTicketsReport>({
        url: `/reports/summaryofopenclosedtickets?dateFrom=${format(
          startDate,
          'yyyy-MM-dd'
        )}&dateTo=${format(endDate, 'yyyy-MM-dd')}${
          !!channel ? `&channel=${channel}` : ''
        }`,
      })
      .then(({ data }) => {
        const { data: report, keys: statuses } = populateTicketReportData(data)
        setStatuses(statuses)
        setReport(report as SummaryOfOpenClosedTicketsReport)
      })
  }, [endDate, startDate])

  const exportExcel = useCallback(async () => {
    if (report) {
      const reportOpenData = getDataForExport(report?.open)
      const reportClosedData = getDataForExport(report?.closed, true)
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const openWs = XLSX.utils.json_to_sheet(reportOpenData)
      const closedWs = XLSX.utils.json_to_sheet(reportClosedData)
      const wb = {
        Sheets: { Open: openWs, Closed: closedWs },
        SheetNames: ['Open', 'Closed'],
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `SummaryOfOpenClosedTickets${fileExtension}`)
    }
  }, [report])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDataForExport = (data?: any[], isClosed?: boolean) => {
    return Array.isArray(data)
      ? data.map((item) => ({
          Vendor: item.vendor,
          Category: item.category,
          Status: item.status,
          ...(!isClosed && { 'Date Opened': item.createdOn }),
          ...(isClosed && { 'Date Closed': item.updatedOn }),
          Priority: item.priority,
          Agent: item.agent,
          Assignee: item.assignee,
        }))
      : []
  }

  const getTable = (dataName?: string) => {
    const data: SummaryOfOpenClosedTicketsRow[] = (
      report as {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any
      }
    )?.[dataName as string]

    return Array.isArray(data) && data.length ? (
      <>
        <S.ReportTitle>
          {dataName === 'open' ? 'Open' : 'Closed'}
        </S.ReportTitle>
        <S.TableContainer>
          <S.Table>
            <S.TableHead>
              <S.TableRow>
                <S.TableHeadCell>Vendor</S.TableHeadCell>
                <S.TableHeadCell>Category</S.TableHeadCell>
                <S.TableHeadCell>Status</S.TableHeadCell>
                <S.TableHeadCell>Priority</S.TableHeadCell>
                <S.TableHeadCell>{`Date ${
                  dataName === 'open' ? 'Opened' : 'Closed'
                }`}</S.TableHeadCell>
                <S.TableHeadCell>Agent</S.TableHeadCell>
                <S.TableHeadCell>Assignee</S.TableHeadCell>
              </S.TableRow>
            </S.TableHead>
            <S.TableBody>
              {data.map(
                (item: SummaryOfOpenClosedTicketsRow, index: number) => (
                  <S.TableRow key={`row${index}`}>
                    <S.TableCell>{item.vendor}</S.TableCell>
                    <S.TableCell>{item.category}</S.TableCell>
                    <S.TableCell>{item.status}</S.TableCell>
                    <S.TableCell>{item.priority}</S.TableCell>
                    <S.TableCell>
                      {formatDateToUTC(
                        (dataName === 'open'
                          ? item.createdOn
                          : item.updatedOn) as string
                      )}
                    </S.TableCell>
                    <S.TableCell>{item.agent}</S.TableCell>
                    <S.TableCell>{item.assignee}</S.TableCell>
                  </S.TableRow>
                )
              )}
            </S.TableBody>
          </S.Table>
        </S.TableContainer>
      </>
    ) : undefined
  }

  if (!report || report === null) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Summary of open/closed tickets
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {channel?.toUpperCase()}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.ReportTitle>Summary</S.ReportTitle>
      <S.SummaryList>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Open</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total: 
            ${report?.open?.length}
          `}</S.SummaryListItemText>
        </S.SummaryListItem>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Closed</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total: 
            ${report?.closed?.length}
          `}</S.SummaryListItemText>
        </S.SummaryListItem>
      </S.SummaryList>
      {getTable('open')}
      {getTable('closed')}
    </S.Container>
  )
}

export default SummaryOfOpenClosedTickets
