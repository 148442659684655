import { Vendor } from '@monorepo/interfaces'

const vendorsStatusKeyLabel = Object.entries(Vendor).filter(([key]) =>
  parseInt(key)
) as [string, string][]

export const vendors = vendorsStatusKeyLabel.map(([value, label]) => ({
  value,
  label,
}))
