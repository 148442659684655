import styled from 'styled-components'
import { IconButton as MuiIconButton } from '@material-ui/core'

export const ImportLink = styled(MuiIconButton)`
  width: 66px;
  padding: 0;
  display: flex;

  &.MuiButtonBase-root {
    padding: 0;
  }

  &.MuiIconButton-root:hover {
    background: transparent;
  }

  & .MuiIconButton-label {
    text-transform: uppercase;
    font-family: PFDINText;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .MuiIconButton-label figure {
    padding-left: 0.5em;
  }
`
