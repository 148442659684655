import { Icon } from '@monorepo/components'
import { colors } from '@monorepo/theme'
import * as S from './styles'

interface ManageHeaderContentProps {
  backText?: string
  titleText?: string
  rightSideChildren?: JSX.Element
  onGoBack?: () => void
}

const ManageHeaderContent: React.FC<ManageHeaderContentProps> = ({
  backText,
  rightSideChildren,
  titleText,
  onGoBack,
}) => (
  <S.ManageContainer>
    <S.BackContainer>
      <S.BackButton onClick={onGoBack}>
        <Icon
          icon="ChevronLeft"
          size={18}
          color={colors.white}
          hasFill={false}
        />
        {backText ?? ''}
      </S.BackButton>
    </S.BackContainer>
    <S.PageTitleContainer>
      {titleText ? <S.PageTitle>{titleText}</S.PageTitle> : null}
    </S.PageTitleContainer>
    <S.RightSideContainer>{rightSideChildren}</S.RightSideContainer>
  </S.ManageContainer>
)

export default ManageHeaderContent
