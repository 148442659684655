import * as S from './styles'

import { Ticket, TicketPropsData } from '@monorepo/interfaces'
import { TicketHelper, isFunction } from '@monorepo/infra'
import { vendors } from '../../../database'

import { Spinner } from '@monorepo/components'
import { TableBody } from '@material-ui/core'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TimeAgo from 'react-timeago'

export interface SelectableTicket extends Ticket {
  selected?: boolean
}

export interface SelectableTicketPropsData extends TicketPropsData {
  tickets: Ticket[] | SelectableTicket[]
}

export interface SectionTicketsListProps extends SelectableTicketPropsData {
  loading: boolean
  selectable?: boolean
  selectOne?: (notificationId: string, selected: boolean) => void
}

const SectionTicketsList: React.FC<SectionTicketsListProps> = ({
  tickets,
  loading = true,
  selectable,
  selectOne,
}) => {
  const statuses = TicketHelper.getTicketStatusOptions()
  const priorities = TicketHelper.getTicketPriorityOptions()

  const getStatus = (status: number, updatedOn: string) => {
    const statusName =
      statuses.find((item) => item.value === status.toString())?.label ??
      'Unknown'
    return (
      <>
        <span className="timeAgo">
          {`${statusName} - `}
          <TimeAgo date={updatedOn} minPeriod={60} />
        </span>
      </>
    )
  }

  const getPriority = (priority: number): string => {
    return (
      priorities.find((item) => item.value === priority.toString())?.label ??
      'Unknown'
    )
  }

  const getContact = (createdBy: string) => {
    return (
      <>
        <span className="email">{createdBy}</span>
      </>
    )
  }

  const getVendor = (vendor?: number) => {
    return vendor
      ? vendors.find((item) => item.value === vendor.toString())?.label ??
          'Unknown'
      : ''
  }

  const { t } = useTranslation('admin')
  const history = useHistory()

  const handleEditTicket = (id: string) => {
    history.push(`/tickets/edit/${id}`)
  }

  return (
    <S.Container>
      <S.TableContainer>
        <S.Table aria-label="Tickets Table">
          <S.TableHead>
            <S.TableRow>
              {selectable ? <S.TableHeadCheckboxCell /> : undefined}
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_1')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_2')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_3')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_4')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_5')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_6')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_7')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_8')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_tickets_list.table_title_9')}
              </S.TableHeadCell>
            </S.TableRow>
          </S.TableHead>
          <TableBody>
            {loading ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : (
              tickets?.map((ticket: Ticket, index: number) => (
                <S.TbodyRow
                  key={index}
                  onClick={(e) => {
                    if (
                      selectable &&
                      e.target.toString() === '[object HTMLInputElement]'
                    ) {
                      return
                    }
                    handleEditTicket(ticket._id ?? '')
                  }}
                >
                  {selectable ? (
                    <S.TBodyCheckboxCell scope="row">
                      <input
                        type="checkbox"
                        key={ticket._id}
                        checked={(ticket as SelectableTicket).selected}
                        onChange={(e) => {
                          e.stopPropagation()

                          if (selectOne && isFunction(selectOne)) {
                            selectOne(ticket._id as string, e.target.checked)
                          }
                        }}
                      />
                    </S.TBodyCheckboxCell>
                  ) : undefined}
                  <S.TBodyCell scope="row">
                    {getContact(ticket.createdBy)}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">{ticket.number}</S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {format(new Date(ticket.createdOn), 'MM/dd/yyyy')}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">{ticket.subject}</S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {getVendor(ticket.vendor)}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">{ticket.agent}</S.TBodyCell>
                  <S.TBodyCell scope="row">{ticket.masterDealerId}</S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {getPriority(ticket.priority)}
                  </S.TBodyCell>
                  <S.TBodyCell>
                    {getStatus(
                      ticket.status,
                      ticket.updatedOn ? ticket.updatedOn : ticket.createdOn
                    )}
                  </S.TBodyCell>
                </S.TbodyRow>
              ))
            )}
          </TableBody>
        </S.Table>
      </S.TableContainer>
    </S.Container>
  )
}

export default SectionTicketsList
