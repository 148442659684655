import React from 'react'
import * as S from './styles'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakHelper, UserHelper } from '@monorepo/infra'

export interface SideMenuProps {
  selectedLink?: string
  items: Array<{
    link: string
    text: string
    permissions?: string[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props?: any
    search?: string
    className?: string
  }>
  onChangeMenuIndex?: () => void
}

const SideMenu: React.FC<SideMenuProps> = ({ items }) => {
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)

  return (
    <S.Container>
      <S.MenuList>
        {items.map((menuItem, index) => {
          const hasMenuPermission =
            UserHelper.hasImpersonatedUserPermissions(
              keycloak,
              menuItem?.permissions
            ) ||
            UserHelper.hasPermissions(user?.permissions, menuItem?.permissions)

          return menuItem?.permissions && !hasMenuPermission ? null : (
            <S.MenuListItem key={index} className={menuItem.className}>
              <S.MenuItemLink
                key={index}
                to={{
                  pathname: menuItem.link,
                  ...(menuItem.props && { state: menuItem.props }),
                  ...(!!menuItem.search && { search: menuItem.search }),
                }}
                isActive={(match, location) => {
                  if (!match) return false

                  if (menuItem.link === '/' && location.pathname !== '/') {
                    return false
                  }

                  if (
                    !!menuItem.search &&
                    location.search.indexOf(menuItem.search) === -1
                  ) {
                    return false
                  }

                  return true
                }}
                className={menuItem.className}
              >
                {menuItem.text}
              </S.MenuItemLink>
            </S.MenuListItem>
          )
        })}
      </S.MenuList>
    </S.Container>
  )
}

export default SideMenu
