import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { DashboardTemplate, PageSpinner } from '../components'
import { AddressesProvider, PrivateRoute } from '@monorepo/infra'
import { dashboardRoutes, manageRoutes, userAddressesRoutes } from './routes'

const browserHistory = createBrowserHistory()

const RouteNotFound = () => (
  <Route path="*" render={() => <h1>Page Not Found</h1>} />
)

const Routes: React.FC = () => (
  <Suspense fallback={<PageSpinner />}>
    <Switch>
      <Route path="/viewReport">
        <Switch>
          <PrivateRoute
            key="viewReport"
            exact
            path="/viewReport"
            component={lazy(() => import('../pages/ViewReport'))}
          />
        </Switch>
      </Route>
      <Route
        path={[
          ...manageRoutes.map(({ path }) => path),
          ...userAddressesRoutes.map(({ path }) => path),
        ]}
      >
        <Switch>
          {manageRoutes.map((privateRoute, key) => (
            <PrivateRoute key={key} exact {...privateRoute} />
          ))}
          <AddressesProvider>
            {userAddressesRoutes.map((usersRoute, key) => (
              <PrivateRoute key={key} exact {...usersRoute} />
            ))}
            <RouteNotFound />
          </AddressesProvider>
        </Switch>
      </Route>

      <Route path={dashboardRoutes.map(({ path }) => path)}>
        <DashboardTemplate>
          <Switch>
            {dashboardRoutes.map((privateRoute, key) => (
              <PrivateRoute key={key} exact {...privateRoute} />
            ))}
            <RouteNotFound />
          </Switch>
        </DashboardTemplate>
      </Route>
    </Switch>
  </Suspense>
)

export { Routes, browserHistory }
