import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core'

import { TableContainer as MuiTableContainer } from '@material-ui/core'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    overflow-y: hidden;
  }
`

export const Table = styled(MuiTable)`
  background-color: ${colors.white};
`

export const TableHead = styled(MuiTableHead)`
  position: relative;

  &::after {
    line-height: 4px;
    content: '.';
    color: transparent;
    background-color: #f3f6f8;
    width: 100%;
    position: absolute;
  }
`

export const TableRow = styled(MuiTableRow)`
  cursor: pointer;
`

export const TableHeadCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    color: ${colors.slateGrey};
    font-family: PFDINText;
    font-size: 12px;
    text-transform: uppercase;
    height: 48px;
    padding: 0 16px;
  }
`

export const TbodyRowLoading = styled(TableRow)`
  height: 50px;
  position: relative;
`

export const TBodyCellLoading = styled(MuiTableCell)`
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: none;

  &.MuiTableCell-root {
    padding: 0;
    display: flex;
  }
`

export const TbodyRow = styled(TableRow)`
  border-bottom: 4px solid #f3f6f8;
  height: 139px;

  & > .MuiTableCell-root:first-child {
    width: 152px;
  }
`

export const TBodyCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-family: PFDINText;
    font-size: 14px;
    padding: 8px;
  }
`

export const TableHeadCheckboxCell = styled(TableHeadCell)`
  width: 20px !important;
`

export const TBodyCheckboxCell = styled(TBodyCell)`
  width: 20px !important;
`
