import * as S from './styles'

import { Order, Pagination as PaginationType } from '@monorepo/interfaces'
import {
  Pagination,
  SectionOrders,
  SectionOrdersList,
} from '../../../components'
import { SearchTerm, TabList } from '../../molecules'
import {
  useOrders,
  usePaginationSearchParams,
  useQueryParams,
} from '@monorepo/infra'

import { PaginationDetails } from '../../atoms'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import useTextTranslation from '@monorepo/infra/build/locales/i18next/useLocation'

type OrdersFetch = PaginationType & {
  data: Order[]
}

const Orders = (): JSX.Element => {
  const orderBaseURL = '/order'
  const pageURLKey = 'p'
  const searchURLKey = 's'

  const queryParams = useQueryParams()

  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(searchURLKey) ?? ''
  )
  const { t } = useTextTranslation('admin')
  const history = useHistory()

  const { apiSearchParams, handlePageChange, handlePrevPage, handleNextPage } =
    usePaginationSearchParams({
      pageAPIKey: 'pageNum',
      pageURLKey,
      searchAPIKey: 'search',
      searchURLKey,
      searchTerm,
    })

  const vendor = queryParams.get('vendor')
  const tabs = [
    { description: 'Active', tabName: 'active' },
    ...(!vendor
      ? [
          { description: 'Fulfilled', tabName: 'archived' },
          { description: 'Deleted', tabName: 'deleted' },
        ]
      : []),
  ]

  const apiURL = `${orderBaseURL}?${apiSearchParams}`

  const { fetch } = useOrders(apiURL, { revalidateOnFocus: false })

  const { data, loading } = fetch
  const { data: orders, ...pagination } = data as OrdersFetch
  pagination.page = Number(pagination.page)

  const handleAddOrder = () => history.push('orders/create')

  const handleClearSearchTerm = () => setSearchTerm('')

  return (
    <S.Main>
      <SectionOrders />
      <S.PageActions>
        <TabList tabKey="status" tabs={tabs} />
        <S.ActionButtonsContainer>
          {/* <ImportLink redirectURL="products/import" /> */}
          <S.AddOrderButton
            label={t('section_orders.button_text')}
            colorOption="blue"
            onClick={handleAddOrder}
          />
        </S.ActionButtonsContainer>
      </S.PageActions>
      <S.SearchFiltersContainer>
        <S.Search
          placeholder={t('section_orders_list.input_placeholder')}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
        <SearchTerm searchTerm={searchTerm} onClear={handleClearSearchTerm} />
        <PaginationDetails
          totalItems={pagination.totalCount}
          totalPages={pagination.totalPages}
          itemsPerPage={pagination.pageSize}
          pageNumber={pagination.page}
          onClickPrev={handlePrevPage}
          onClickNext={handleNextPage}
        />
      </S.SearchFiltersContainer>
      <SectionOrdersList loading={loading} orders={orders} />
      <S.PaginationContainer>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={pagination.totalCount}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalPages={pagination.totalPages}
        />
      </S.PaginationContainer>
    </S.Main>
  )
}

export default Orders
