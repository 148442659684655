import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { Button as MuiButton } from '@material-ui/core'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Button = styled(MuiButton).attrs({ disableRipple: true })`
  &.MuiButton-root {
    border: 2px solid ${colors.grey};
  }

  .MuiButton-label {
    color: ${colors.grey};
    font-size: 0.9em;
    font-family: 'ATTAleckSansBold';
    text-transform: none;
  }
`
