import React from 'react'

import * as S from './styles'

interface SectionUsersProps {
  title: string
}

const SectionUsers: React.FC<SectionUsersProps> = ({ title }) => (
  <S.Container>
    <S.AdminTitle>{title}</S.AdminTitle>
  </S.Container>
)

export default SectionUsers
