import * as S from './styles'

import {
  Pagination,
  SectionTickets,
  SectionTicketsList,
  TabList,
} from '../../../components'
import {
  useCrud,
  usePaginationSearchParams,
  useQueryParams,
  http,
  isString,
} from '@monorepo/infra'
import { SelectableTicket } from '../../organisms/SectionTicketsList'

import { Icon } from '@monorepo/components'
import { PaginationDetails } from '../../atoms'
import { Pagination as PaginationType } from '@monorepo/interfaces'
import { Ticket } from '@monorepo/interfaces'
import { SearchTerm } from '../../molecules'
import { colors } from '@monorepo/theme'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useTextTranslation from '@monorepo/infra/build/locales/i18next/useLocation'

type TicketsFetch = PaginationType & {
  data: Ticket[]
}

const Tickets = (): JSX.Element => {
  const ticketBaseURL = '/ticket'
  const pageURLKey = 'p'
  const searchURLKey = 's'

  const queryParams = useQueryParams()

  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(searchURLKey) ?? ''
  )
  const [savedTickets, setSavedTickets] = useState<Ticket[]>([])
  const [selectedTicketIds, setSelectedTicketIds] = useState<string[]>([])
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)

  const { t } = useTextTranslation('admin')
  const history = useHistory()

  const { apiSearchParams, handlePageChange, handlePrevPage, handleNextPage } =
    usePaginationSearchParams({
      pageAPIKey: 'pageNum',
      pageURLKey,
      searchAPIKey: 'search',
      searchURLKey,
      searchTerm,
    })

  const vendor = queryParams.get('vendor')
  const tabs = [
    { description: 'Active', tabName: 'active' },
    ...(!vendor
      ? [
          { description: 'Closed', tabName: 'closed' },
          { description: 'Deleted', tabName: 'deleted' },
        ]
      : []),
  ]

  const apiURL = `${ticketBaseURL}?${apiSearchParams}`

  const { fetch } = useCrud<Ticket>(apiURL, '_id', {
    revalidateOnFocus: false,
  })

  const { data, loading } = fetch
  const { data: tickets, ...pagination } = data as TicketsFetch
  pagination.page = Number(pagination.page)

  const handleAddTicket = () => history.push('tickets/create')

  const handleClearSearchTerm = () => setSearchTerm('')

  useEffect(() => {
    if (Array.isArray(tickets)) {
      setSavedTickets(
        isSelectable()
          ? getSelectedTickets(tickets, selectedTicketIds)
          : tickets
      )
    }
  }, [tickets])

  const selectAll = (selected: boolean) => {
    if (!selected) {
      return
    }

    const tempSelectedTicketIds = savedTickets.reduce((acc: string[], item) => {
      acc.push(item._id as string)
      return acc
    }, [])
    setSelectedTicketIds(tempSelectedTicketIds)

    setSavedTickets(getSelectedTickets(savedTickets, tempSelectedTicketIds))

    setIsSelectAll(true)
  }

  const selectOne = (id: string, selected: boolean) => {
    const tempSelectedTicketIds = [...selectedTicketIds]
    const idx = tempSelectedTicketIds.indexOf(id)
    if (selected) {
      if (idx === -1) {
        tempSelectedTicketIds.push(id)
      }
    } else {
      if (idx !== -1) {
        tempSelectedTicketIds.splice(idx, 1)
      }
    }
    setSelectedTicketIds(tempSelectedTicketIds)

    const tempSelectedTickets = getSelectedTickets(
      savedTickets,
      tempSelectedTicketIds
    )
    setSavedTickets(tempSelectedTickets)

    setIsSelectAll(tempSelectedTickets.every((item) => item.selected))
  }

  const getSelectedTickets = (
    tickets: Ticket[],
    selectedTicketIds: string[]
  ): SelectableTicket[] => {
    return tickets.map((item) => ({
      ...item,
      selected: selectedTicketIds.indexOf(item._id as string) !== -1,
    }))
  }

  const isSelectable = () => {
    return (
      isString(apiSearchParams) &&
      apiSearchParams.indexOf('status=active') !== -1
    )
  }

  const handleClose = () => {
    const requests = []
    for (let i = 0, l = selectedTicketIds.length; i < l; ++i) {
      requests.push(
        http.patch<Ticket>({
          url: `/ticket/${selectedTicketIds[i]}/close`,
        })
      )
    }

    if (!requests.length) {
      return
    }

    Promise.all(requests)
      .then(() => {
        setSavedTickets(
          getSelectedTickets(
            savedTickets.filter(
              (item) => selectedTicketIds.indexOf(item._id as string) === -1
            ),
            []
          )
        )

        setSelectedTicketIds([])
        setIsSelectAll(false)
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <S.Main>
        <SectionTickets />
        <S.PageActions>
          <TabList tabKey="status" tabs={tabs} />
          <S.ActionButtonsContainer>
            <S.AddTicketButton
              label={t('section_tickets.button_text')}
              colorOption="blue"
              onClick={handleAddTicket}
            />
          </S.ActionButtonsContainer>
        </S.PageActions>
        <S.Separator />
        <S.SearchFiltersContainer>
          <S.Search
            placeholder={t('section_tickets_list.input_placeholder')}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <SearchTerm searchTerm={searchTerm} onClear={handleClearSearchTerm} />
          <PaginationDetails
            totalItems={pagination.totalCount}
            totalPages={pagination.totalPages}
            itemsPerPage={pagination.pageSize}
            pageNumber={pagination.page}
            onClickPrev={handlePrevPage}
            onClickNext={handleNextPage}
          />
        </S.SearchFiltersContainer>
        <S.SelectAllContainer hidden={!isSelectable()}>
          <input
            type="checkbox"
            checked={isSelectAll}
            onChange={(e) => selectAll(e.target.checked)}
          />
          <S.SelectAllLabel>Select all</S.SelectAllLabel>
          <S.SelectAllButton onClick={handleClose}>Close</S.SelectAllButton>
        </S.SelectAllContainer>
        <SectionTicketsList
          loading={loading}
          tickets={savedTickets}
          selectable={isSelectable()}
          selectOne={selectOne}
        />
        <S.PaginationContainer>
          <Pagination
            onPageChange={handlePageChange}
            totalCount={pagination.totalCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalPages={pagination.totalPages}
          />
        </S.PaginationContainer>
      </S.Main>
    </>
  )
}

export default Tickets
